import React from 'react';

import {
    AppBar, Toolbar, Typography, IconButton, makeStyles
} from '@material-ui/core';


import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import botonLogo from '../../assets/static/botonLogo.png';

import clsx from 'clsx';

export default function AppBarDashboard({handleDrawerOpen, handleExit, open, clinic, pageTitle}) {
    const classes = useStyles();

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                    <MenuIcon />
                </IconButton>
                {!open && (
                    <img src={botonLogo} alt="Logo1" width="60px" ></img>
                )}
                <Typography component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
                    {!open ? clinic.name : pageTitle}
                </Typography>
                <IconButton color="inherit" onClick={() => handleExit()}>
                    <ExitToAppIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );

}
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
}));
