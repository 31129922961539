//Clinic Images
import estanciaPopImg from "../assets/static/clinics/estanciapopayan.jpg";
import nuestraIbagImg from "../assets/static/clinics/nuestraibague.jpg";
import nuestraCartImg from "../assets/static/clinics/nuestracartagena.jpg";
import rosalesPereImg from "../assets/static/clinics/rosalespereira.jpg";
import corazonMedImg from "../assets/static/clinics/corazonmedellin.jpg";
import antioquiaItagImg from "../assets/static/clinics/antioquiaitagui.jpg";
import antioquiaBellImg from "../assets/static/clinics/antioquiabello.jpg";
import versallesManiImg from "../assets/static/clinics/versallesmanizales.jpg";
import mercedBarrImg from "../assets/static/clinics/mercedbarranquilla.jpg";
import centralqArmeImg from "../assets/static/clinics/ccqarmenia.jpg";
import logoCuadrado from "../assets/static/logocuadrado.jpg";

import { storeService } from "./storeService";

export const clinicsM = {
  Maping,
  Clinic,
};

function Maping() {
  const clinicsMaping = {
    27: 27,
    29: 28,
    28: 29,
    14: 19,
    10: 4,
    19: 14,
    11: 6,
    9: 4,
    6: 1,
    7: 2,
    15: 10,
    2: 15,
    16: 11,
    12: 7,
    13: 8,
    3: 30,
    30: 3,
    31: 31,
    32: 32,
    4: 10,
  };
  return clinicsMaping;
}

function Clinic() {
  const clinics = [
    { name: "HOSVITAL_NSDR_OS", city: "", image: logoCuadrado, cli_id: 27 },
    {
      name: "Clinica Ospedale Manizales",
      city: "",
      image: logoCuadrado,
      cli_id: 31,
    },
    {
      name: "Los Rosales pruebas",
      city: "Pereira Risaralda",
      image: rosalesPereImg,
      cli_id: 29,
    },
    {
      name: "Clínica la Estancia",
      city: "Popayan Cauca",
      image: estanciaPopImg,
      cli_id: 4,
    },
    {
      name: "Sagrado Corazón pruebas",
      city: "",
      image: corazonMedImg,
      cli_id: 28,
    },
    {
      name: "Clínica Antioquia",
      city: "Itagui Antioquia",
      image: antioquiaItagImg,
      cli_id: 14,
    },
    {
      name: "Clínica Antioquia",
      city: "Bello Antioquia",
      image: antioquiaBellImg,
      cli_id: 14,
    },
    {
      name: "Clínica Central del Quindio",
      city: "Armenia Quindio",
      image: centralqArmeImg,
      cli_id: 10,
    },
    { name: "Sagrado Corazón", city: "", image: corazonMedImg, cli_id: 27 },
    {
      name: "Clínica Nuestra Pruebas",
      city: "Cali Valle",
      image: nuestraIbagImg,
      cli_id: 32,
    },
    //{name: "Clinica Local", city: "", image: "", cli_id: 22},
    {
      name: "Clínica Nuestra",
      city: "Cali Valle",
      image: nuestraIbagImg,
      cli_id: 11,
    },
    {
      name: "Clínica Nuestra",
      city: "Cartagena Bolivar",
      image: nuestraCartImg,
      cli_id: 9,
    },
    {
      name: "Clínica Nuestra",
      city: "Ibagué Tolima",
      image: nuestraIbagImg,
      cli_id: 6,
    },
    //{name: "Clínica Los Rosales", city: "Pereira Risaralda", image: rosalesPereImg, cli_id: 7},
    //{name: "Clínica Rosales Pruebas", city: "", image: "", cli_id: 21},
    //{name: "Clínica Corpas", city: "Bogotá D.C", image: "", cli_id: 8},
    //{name: "Desarrollo", city: "", image: "", cli_id: 17},
    //{name: "Clínica la Estancia", city: "Popayan Cauca", image: estanciaPopImg, cli_id: 15},
    {
      name: "Clínica la Estancia pruebas",
      city: "Popayan Cauca",
      image: estanciaPopImg,
      cli_id: 3,
    },
    //{name: "G-OCHO", city: "", image: "", cli_id: 18},
    { name: "G-OCHO PRUEBAS", city: "", image: logoCuadrado, cli_id: 2 },
    {
      name: "Clínica La Merced",
      city: "Barranquilla Atlantico",
      image: mercedBarrImg,
      cli_id: 16,
    },
    { name: "La Merced Pruebas", city: "", image: centralqArmeImg, cli_id: 20 },
    {
      name: "Clínica Sagrado corazon",
      city: "Medellin Antioquia",
      image: corazonMedImg,
      cli_id: 12,
    },
    {
      name: "Clínica Versalles",
      city: "Manizales Caldas",
      image: versallesManiImg,
      cli_id: 13,
    },
    { name: "CCQ Pruebas", city: "", image: logoCuadrado, cli_id: 19 },
  ];
  return clinics;
}
