import React from 'react';
// redux
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
///import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
///import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useState, useEffect } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';


// Custom Components
import Copyright from '../components/Copyright';
import FormLogin from '../components/FormLogin';
import FaceCapture from './FaceCapture';
import VoiceRecorder from './VoiceRecorder';
import FaceDetection from '../containers/FaceDetection';
//import Mp3Recorder from '../containers/Mp3Recorder';
//import AllClinics from '../containers/AllClinics';
import AutoComplete from '../components/AutoComplete';

// My icons
import FaceIcon from '@material-ui/icons/Face';
import MicIcon from '@material-ui/icons/Mic';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

// My material UI components
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// Clinics Images
import estanciaPopImg from '../assets/static/clinics/estanciapopayan.jpg';
import nuestraIbagImg from '../assets/static/clinics/nuestraibague.jpg';
import nuestraCartImg from '../assets/static/clinics/nuestracartagena.jpg';
import rosalesPereImg from '../assets/static/clinics/rosalespereira.jpg';
import corazonMedImg from '../assets/static/clinics/corazonmedellin.jpg';
import antioquiaItagImg from '../assets/static/clinics/antioquiaitagui.jpg';
import antioquiaBellImg from '../assets/static/clinics/antioquiabello.jpg';
import versallesManiImg from '../assets/static/clinics/versallesmanizales.jpg';
import mercedBarrImg from '../assets/static/clinics/mercedbarranquilla.jpg';
import centralqArmeImg from '../assets/static/clinics/ccqarmenia.jpg';

//Actions
import { setClinic, setPatient, loginRequest } from '../actions';

// services
import { authenticationService } from '../services/authenticationService';

function SignIn(props) {
  let history = useHistory();

  const [loginType, setLoginType] = useState('form');
  const [patientExist, setPatientExist] = useState(true);
  const [loading, setLoading] = useState(false);
  const [urlBackgroundImage, setUrlBackgroundImage] = useState("");
  const useStyles = makeStyles((theme) => ({
    heroContent: {
      backgroundImage: `url(${urlBackgroundImage})`,
      //backgroundImage: `url(${Background})`, 
      padding: theme.spacing(10, 0, 3),
      backgroundPosition: 'center 35%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginLeft: "90px",
      width: "45%",
      height: "10%",
      //borderRadius: '0px!important',
    },
    paper: {
      paddingTop: theme.spacing(12),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      //margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    complete: {
      background: `url(http://www.clinicantioquia.com.co/wp-content/themes/heart/images/bg_corazon.jpg)`,
      //backgroundImage: `url(${Background})`, 
      backgroundPosition: 'center 10%',
      backgroundRepeat: 'no-repeat',
      //height: '1000%',
      width: "100%",
      minWidth: "100vh",
      height: "100%",
      minHeight: "100vh",
      backgroundSize: 'inherit',
      //padding: theme.spacing(3, 9, 0),
      //borderRadius: '0px!important',
    },
  }));
  const classes = useStyles();

  function handleClick(type) {
    //setLoading(true);
    setLoginType(type);

  }

  const clinicLogo = (idClinic) => {
    console.log("Valor de id clinic ", props.clinic.cli_id);
    switch (idClinic) {
      case 6: setUrlBackgroundImage("https://clinicanuestra.com/wp-content/themes/clinicanuestra/images/clinicanuestra.png"); break
      case 7: setUrlBackgroundImage("https://www.clirosales.com/wp-content/uploads/bfi_thumb/logo_rosales-e1530296886188-3an6ahyxb40tdzet5w8em8.png"); break
      case 9: setUrlBackgroundImage("https://clinicanuestra.com/wp-content/themes/clinicanuestra/images/clinicanuestra.png"); break
      case 10: setUrlBackgroundImage("https://clinicacentral.co/wp-content/uploads/2018/12/LogoClinicaCentral2.png"); break
      case 11: setUrlBackgroundImage("https://clinicanuestra.com/wp-content/themes/clinicanuestra/images/clinicanuestra.png"); break
      case 12: setUrlBackgroundImage("https://almeras3multimedia.s3.amazonaws.com/sgicsagradocorazon/2020/12/17/empresa/065a9a1f5079e8312e12e15301efa53fbe2f0d0b.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIFSA5R2MPEUBERFQ%2F20210531%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210531T222122Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Signature=eeebf46a6a0422aef0da85563d204bc0ee341ac25169a199cbd972d652ac194a"); break
      case 14: setUrlBackgroundImage("https://almeras3multimedia.s3.amazonaws.com/sgiclinicaantioquia/2020/12/17/empresa/9ffb42aa9db616827bcf87d98c857093e1915d05.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIFSA5R2MPEUBERFQ%2F20210503%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210503T223251Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Signature=8e7cc388122a235ccc3c73596d857942bf2070c72b9e2ec507a9b213f736ecab"); break
      case 15: setUrlBackgroundImage("https://laestancia.com.co/web/wp-content/uploads/2020/07/cropped-logo-la-estancia-1-3-191x53.png"); break
      case 16: setUrlBackgroundImage("https://clinicalamerced.com/co/wp-content/uploads/2019/12/logo-merced-2.png"); break
      //case 19: setUrlBackgroundImage("https://clinicacentral.co/wp-content/uploads/2018/12/LogoClinicaCentral2.png"); break
      case 19: setUrlBackgroundImage("https://almeras3multimedia.s3.amazonaws.com/sgicsagradocorazon/2020/12/17/empresa/065a9a1f5079e8312e12e15301efa53fbe2f0d0b.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIFSA5R2MPEUBERFQ%2F20210617%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210617T235926Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Signature=b5bc244381f081a83076de74c0a897f243952e82d2822e5d776521e273ea0a78"); break
      case 27: setUrlBackgroundImage("https://almeras3multimedia.s3.amazonaws.com/sgicsagradocorazon/2020/12/17/empresa/065a9a1f5079e8312e12e15301efa53fbe2f0d0b.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIFSA5R2MPEUBERFQ%2F20210617%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210617T235926Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Signature=b5bc244381f081a83076de74c0a897f243952e82d2822e5d776521e273ea0a78"); break

    }

  }

  const handleSelectClinic = async (optionSelected) => {
    if (optionSelected) {

      props.setClinic(optionSelected);

      const resp = await authenticationService.login(props.user.email, "CC", props.user.document, optionSelected.cli_id);
      if (resp.error) {
        alert(`Ups.. No te encontramos en  ${optionSelected.name} Intenta nuevamente o registrate en esta clínica!`);
        //props.setClinic(null);
        setPatientExist(false);

      } else {
        props.setPatient(resp.patients);
        props.loginRequest(resp.ceroTramuser);
        history.push("/dashboard");
      }
    }
  }
  useEffect(async () => {
    clinicLogo(props.clinic.cli_id);
    await authenticationService.logout();
  }, [])

  if (!props.clinic) history.push("/")

  return (
    <Container component="main" maxWidth="xl" maxHeight="xl" className={classes.complete} >
      <Container component="main" maxWidth="xs">
        {
          props.faceAuth !== null && loginType === "facecapture" && (
            props.faceAuth ? (
              <Alert severity="success">
                <AlertTitle>¡Hola {props.user.name}!</AlertTitle>
              Identificación Biometrica Exitosa — <strong>Por favor selecciona una Clínica</strong>
              </Alert>
            ) :
              (
                <Alert severity="error">
                  <AlertTitle>Ups!</AlertTitle>
              No hemos podido identificarte <strong>¡Intenta nuevamente!</strong>
                </Alert>
              )
          )
        }
        <CssBaseline />

        <div className={classes.paper}>

          <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          {
            {
              'form': <FormLogin />,
              'facecapture': <FaceDetection handleSelectClinic={handleSelectClinic} />,
              'voicecapture': <VoiceRecorder />,
            }[loginType]
          }
          {
            (props.faceAuth !== null && loginType === "facecapture" && !props.clinic) && (
              props.faceAuth ? (
                <AutoComplete
                  id={"select-clinic"}
                  getOptionLabel={(clinic) => `${clinic.name} - ${clinic.city}`}
                  options={clinics}
                  handleSelection={handleSelectClinic}
                  label={"Selecciona Una Clinica"}
                />
              ) : ("")
            )
          }
          <Grid container justify="center">
            <Grid item>
              <IconButton aria-label="delete" className={classes.margin} onClick={() => handleClick("form")}>
                <PermContactCalendarIcon />
              </IconButton>
            </Grid>
            <Grid item >
              <IconButton aria-label="face" className={classes.margin} onClick={() => handleClick("facecapture")}>
                <FaceIcon />
              </IconButton>
            </Grid>
            <Grid item >
              {/* <IconButton aria-label="delete" className={classes.margin}onClick={() => handleClick("barcode")} >
              <CallToActionIcon />
            </IconButton> */}
            </Grid>
            <Grid item >
              <IconButton aria-label="delete" className={classes.margin} onClick={() => handleClick("voicecapture")} >
                <MicIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <div className={classes.heroContent}></div>
        <Box mt={1} style={{ paddingBottom: "35px" }}>
          <Copyright />
        </Box>
      </Container>
    </Container>

  );
}

const clinics = [
  { name: "Clínica la Estancia", city: "Popayan Cauca", image: estanciaPopImg, clinicId: 1 },
  { name: "Clínica Nuestra", city: "Cali Valle", image: nuestraIbagImg, clinicId: 1 },
  { name: "Clínica Nuestra", city: "Ibagué Tolima", image: nuestraIbagImg, clinicId: 1 },
  { name: "Clínica Nuestra", city: "Cartagena Bolivar", image: nuestraCartImg, clinicId: 4 },
  { name: "Clínica Los Rosales", city: "Pereira Risaralda", image: rosalesPereImg, clinicId: 1 },
  { name: "Clínica Sagrado corazon", city: "Medellin Antioquia", image: corazonMedImg, clinicId: 3 },
  { name: "Clínica Antioquia", city: "Itagui Antioquia", image: antioquiaItagImg, clinicId: 1 },
  { name: "Clínica Antioquia", city: "Bello Antioquia", image: antioquiaBellImg, clinicId: 1 },
  { name: "Clínica Versalles", city: "Manizales Caldas", image: versallesManiImg, clinicId: 1 },
  { name: "Clínica La Merced", city: "Barranquilla Atlantico", image: mercedBarrImg, clinicId: 2 },
  { name: "Clínica Central del Quindio", city: "Armenia Quindio", image: centralqArmeImg, clinicId: 1 },
];

const mapStateToProps = (state) => ({
  faceAuth: state.faceAuth,
  user: state.user,
  clinic: state.clinic
})

const mapDispatchToProps = {
  setClinic,
  setPatient,
  loginRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
//export default SignIn;