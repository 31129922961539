import React, { useState, useEffect } from "react";
// redux
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import GeneralInfoForm from "../components/GeneralInfoForm";
import ShowAlert from "../components/ShowAlert";
import VerificationCode from "../components/VerificationCode";
import ConfirmAppointmentInfo from "../components/ConfirmAppointmentInfo";
import BackdropLoading from "../components/BackdropLoading";
import FormEmailToResetPassword from "../components/FormEmailToResetPassword";
import FormDocumentToResetPassword from "../components/FormDocumentToResetPassword";
import useBrowserContextCommunication from "react-window-communication-hook";
// actions
import { setPatient, loginRequest, setClinic } from "../actions";
// Services
import { hisServices } from "../services/hisServices";
import { appointmentService } from "../services/appointmentService";
import { authenticationService } from "../services/authenticationService";
import FormResetPassword from "../components/FormResetPassword";
//Mapeo de clinicas
import { clinicsM } from "../services/clinicsM";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  grid: {
    marginTop: "20px",
  },
  buttongrid: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "140px",
  },
}));

function getSteps() {
  return ["Documento", "Confimación de identidad", "Nueva contraseña"];
}

const ResetPassword = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [alert, handleAlert] = useState({
    show: false,
    type: null,
    title: null,
    message: null,
    strong: null,
    action: null,
  });
  const [form, setFormValues] = useState({
    password: "",
    password_again: "",
  });
  const [verificationCodeInput, setVerificationCodeInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableFinishButton, setDisableFinishButton] = useState(true);
  const [documentInfo, setDocumentInfo] = useState('');
  const [documentType, setDocumentType] = useState('');
  let { dataFromCitapp } = useParams();
  const [hasToPay, setHasToPay] = useState({
    show: false,
    id: null,
    valueToPay: 0,
  });
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [user, setUser] = useState({});
  const [changeValue, setChangeValue] = useState(false);

  const [emailValidator, setEmailValidator] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordAgainValidator, setPasswordAgainValidator] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(false);

  const clinicsMapingCerotram = clinicsM.Maping();
  const clinics = clinicsM.Clinic();

  const handleVerificationCodeInput = (event) => {
    setDisableFinishButton(false);
    setVerificationCodeInput(event.target.value);
  };

  const handleInputEmail = (event) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.name == "email" && !regEmail.test(event.target.value)) {
      setEmailValidator(true);
      setDisableFinishButton(true);
    } else {
      setEmailValidator(false);
      setDisableFinishButton(false);
    }
    setEmail(event.target.value);
  };

  const handleInputPassword = (event) => {
    if (event.target.name === "password" && event.target.value.length < 4) {
      setPasswordValidator(true);
      setDisableFinishButton(true);
    } else {
      if (event.target.name === "password") {
        setPasswordValidator(false);
        setDisableFinishButton(false);
      }
    }

    if (
      event.target.name === "password_again" &&
      event.target.value !== form.password
    ) {
      setPasswordAgainValidator(true);
      setDisableFinishButton(true);
    } else {
      if (event.target.name === "password_again") {
        setPasswordAgainValidator(false);
        setDisableFinishButton(false);
      }
    }

    setFormValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitFormEmail = async () => {
    // const resp = await authenticationService.validateIfUserexists(email);
    const resp = await authenticationService.validateIfUserexists(documentInfo, documentType);
    
    if (resp.code === 404) {
      return false;
    } else {
      const respLogin = await authenticationService.loginReset(null, documentType, documentInfo);
      resp.data.ceroTramuser.email = respLogin['patients'][0]['email'];
      
      setEmail(respLogin['patients'][0]['email']);
      setUser(resp?.data?.ceroTramuser);
      return true;
    }
  };

  const handleVerificationSubmit = async () => {
    const check = await hisServices.checkVerificationCode(
      user,
      verificationCodeInput
    );

    if (check.data.result) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (form.password.length >= 4 && form.password === form.password_again) {
      try {
        const resp = await authenticationService.resetUserPassword(
          documentInfo,
          email,
          form.password
        );
        if (resp.success) {
          handleAlert({
            ...alert,
            show: true,
            type: "success",
            message: `Contraseña actualizada`,
            strong:
              "Se ha actualizado la nueva contraseña, por favor NO LA OLVIDE",
          });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          handleAlert({
            ...alert,
            show: true,
            type: "error",
            message: `Contraseña no actualizada`,
            strong:
              "No fue posible actualizar la contraseña, por favor inténtelo de nuevo",
          });
        }
      } catch (e) {
        handleAlert({
          ...alert,
          show: true,
          type: "error",
          message: `Contraseña no actualizada`,
          strong: "Ocurrió un problema en el proceso, inténtelo de nuevo",
        });
      }
    } else {
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `Campos no válidos`,
        strong: "Los campos deben cumplir los requisitos",
      });
    }
    setLoading(false);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FormDocumentToResetPassword
            setDisableFinishButton={setDisableFinishButton}
            setDocumentInfo={setDocumentInfo}
            setDocumentType={setDocumentType}
          />
        );
            // handleInputEmail={handleInputEmail}
            // emailValidator={
            //   emailValidator
            // } /* handleInput={handleInput} clinicId={clinic.cli_id} patients={patients} user={user} */
      case 1:
        return (
          <VerificationCode
            user={user}
            handleVerificationCodeInput={handleVerificationCodeInput}
            handleAlert={handleAlert}
          />
        );
      case 2:
        return (
          <FormResetPassword
            handleInputPassword={handleInputPassword}
            passwordValidator={passwordValidator}
            passwordAgainValidator={passwordAgainValidator}

            /* user={user}
        handleVerificationCodeInput={handleVerificationCodeInput}
        handleAlert={handleAlert} */
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const steps = getSteps();

  const handleNext = async () => {
    if (activeStep === 0) {
      handleAlert({ ...alert, show: false });
      setLoading(true);

      const resp = await handleSubmitFormEmail();
      console.log("datos ", resp);
      if (resp) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoading(false);
      } else {
        handleAlert({
          ...alert,
          show: true,
          type: "error",
          message: `Usuario no registrado`,
          strong:
            "Este documento no se encuentra registrado en nuestra base de datos",
        });
        setLoading(false);
      }
    } else if (activeStep === 1 && verificationCodeInput != "") {
      setLoading(true);
      const resp = await handleVerificationSubmit();
      if (resp) {
        console.log("resp ", resp);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoading(false);
      } else {
        handleAlert({
          ...alert,
          show: true,
          type: "error",
          message: `Código no válido`,
          strong:
            "El código no es correcto, por favor verifique su correo electrónico",
        });
        setLoading(false);
      }
    } else if (activeStep === 2 && form != "") {
      const resp = await handleVerificationSubmit();
    }

    //    if((activeStep === 2)){
    /* const resp = await handleVerificationSubmit();

    if(resp){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }else{ 
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `Código no válido`,
        strong: "Debe digitar el código de confirmación enviado a su correo"
      })
    } */
    //   }
  };

  const handleBack = () => {
    setDisableFinishButton(false);
    setLoading(true);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setLoading(false);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      {alert.show && <ShowAlert {...alert} />}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Grid
              container
              spacing={1}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.instructions} align="center">
                  <strong>¡Contraseña actualizada con éxito!</strong>
                  {/* Informacion actualizada correctamente le enviamos un correo de confirmacion a 
                  <strong> {props.user.user.username}</strong> */}
                </Typography>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography component="h5">
                    <strong>Usuario:</strong> {email}
                  </Typography>
                  <Typography component="h5">
                    <strong>Contraseña:</strong> {form.password}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography style={{ color: "#4D0000" }}>
                    <i>Recuerde no olvidar su contraseña</i>
                    {/* Informacion actualizada correctamente le enviamos un correo de confirmacion a 
                  <strong> {props.user.user.username}</strong> */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid className={activeStep === 1 ? classes.grid : ""}>
            {getStepContent(activeStep)}
            <Grid
              className={
                activeStep === 1 ? classes.buttongrid : classes.buttons
              }
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Atrás
              </Button>
              {loading ? (
                <Grid>
                  <CircularProgress
                    style={{ marginTop: "25px", marginLeft: "5px" }}
                    size={30}
                  />
                </Grid>
              ) : (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={disableFinishButton}
                  onClick={
                    activeStep === steps.length - 1 ? handleSubmit : handleNext
                  }
                >
                  {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <BackdropLoading open={backdropLoading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  /* clinic: state.clinic,
  user: state.user,
  patients: state.patients */
});

const mapDispatchToProps = {
  setPatient,
  setClinic,
  loginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
