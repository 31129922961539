import React from 'react';

import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const CardInfo = (props) => {
    return (
        <Paper elevation={2}>
            <Grid
                container
                direction="column"
                justify="space-between"
                style={{ marginLeft: "15px", marginRight: "15px", marginTop: "10px" }}
                alignItems="baseline">
                <Grid item alignItems="flex-start" >
                    <Typography component="h6" variant="h6">
                        {props.title}
                    </Typography>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center">
                        <Grid item>
                            {props.icon}
                        </Grid>
                        <Grid item>
                            <Typography component="h5" variant="h5">
                                {props.value}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default CardInfo;