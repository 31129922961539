import React from 'react';
import Webcam from "react-webcam";
import { useState, useEffect } from 'react';

import { loadModels, getFullFaceDescription } from '../api/face'

//my Components
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';





const FaceCapture = () => {
    const webcamRef = React.useRef();
    var interval = null;

    const [ imgSrc, setImgSrc ] = useState(null);
    const [ fullDesc, setFullDesc ] = useState(null);
    const [ detections, setDetections ] = useState(null);
    const [ facingMode, setFacingMode] = useState(null);


    useEffect(async () => {
        await loadModels();
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        
        setInputDevice();
    }, []);

    function setInputDevice() {
        navigator.mediaDevices.enumerateDevices().then(async devices => {
            let inputDevice = await devices.filter(device => device.kind === 'videoinput');
            if (inputDevice.length < 2) {
                setFacingMode('user');
            } else {
                setFacingMode('environment');
            }
            startCapture();
        });
    }

    function startCapture() {
        interval = setInterval(() => {
            capture();
        }, 1500);
    }

    async function capture() {
        if (!!webcamRef.current) {
            await getFullFaceDescription(
              webcamRef.current.getScreenshot(),
              160
            ).then(fullDesc => {
              if (!!fullDesc) {
                  setDetections(fullDesc.map(fd => fd.detection));
                  //
                  //clearInterval(interval);
              }
              
              setImgSrc(webcamRef.current.getScreenshot());
            });
        }
    }

    return(
        <Container component="main" maxWidth="xs">
            <Grid container justify="center">
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ width: { min: 400 }, height: { min: 400 } } }
                    width={400} 
                    height={400}
                />
                {/* {imgSrc && (<img src={imgSrc}/>)} */}
            </Grid>
        </Container>
    );
}

export default FaceCapture;