import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import ShowAlert from '../../components/ShowAlert';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// redux
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
import { SendIncapacityToEmployerModal } from './SendIncapacityToEmployerModal';
// Services
import { appointmentService } from '../../services/appointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';
//Icons
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import useBrowserContextCommunication from 'react-window-communication-hook';

const clinicsMaping = clinicsM.Maping();

const MyIncapacitiesTable = (props) => {

  const [title, setTitle] = useState('Mis Incapacidades');
  const [incapacitiesLoading, setIncapacitiesLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [communicationState, postMessage] = useBrowserContextCommunication("CitappModalIframe");

  const columnsIncapacities = [
    { field: 'Fch_Inicia_Incap', headerName: 'Inicia', width: 110, renderCell: renderCellExpand },
    { field: 'Fch_Fin_Incap', headerName: 'Finaliza', width: 110, renderCell: renderCellExpand },
    { field: 'Dias_Incap', headerName: 'Días', width: 81, renderCell: renderCellExpand },
    { field: 'Nombre_Medico', headerName: 'Médico', width: 110, renderCell: renderCellExpand },
    { field: 'Tipo_de_Incapacidad', headerName: 'Tipo', width: 90, renderCell: renderCellExpand },
    { field: 'Causa_Externa', headerName: 'Causa Externa', width: 200, renderCell: renderCellExpand },
    { field: 'Nombre_Diag', headerName: 'Diagnóstico', width: 200, renderCell: renderCellExpand },
    { field: 'estado', headerName: 'Estado', width: 130, renderCell: renderCellExpand },
    {
      field: 'OrdenMed',
      renderHeader: () => (
        loadingDocument ? <CircularProgress size={28} /> : "Ver"
      ),

      width: 80,
      renderCell: (params) => (
        <IconButton
          onClick={() => viewDocumentIncapacity(params)}
          disabled={loadingDocument}
        >
          <DescriptionIcon color={loadingDocument ? "inherit" : "primary"} />

        </IconButton>
      ),
    },
    /* {
      field: 'Enviar a Empleador',
      headerName: 'Radicar',
      width: 110,
      renderCell: (params) => (
        <IconButton
          onClick={() => params.row.estado != 'RADICADO POR LA EPS' && handleApprovalIncapacity(params)}
        >
          {params.row.estado === 'RADICADO POR LA EPS'? "" : <CheckCircleIcon color="primary" />}
        </IconButton>
      ),
    }, */
  ]

  const getAllIncapacities = async (user, clinic) => {
    //Get data
    console.log("User send ", user);
    console.log("Clinic send ", clinic)
    setIncapacitiesLoading(true);
    const resp = await appointmentService.getAllIncapacitiesCeroTramites(user, clinic);
    // parse data
    console.log("PRINT DATA INCAPACITIES FROM PYTHON", resp)
    //console.log("valor de resp ",resp.data[0].incapacity)
    const rowsToRender = resp.data.map((row, index) => {
      const appointmentInfo = row.incapacity;
      return {
        ...row,
        id: index,
      }
    })
    console.log("valor incapacidades", rowsToRender)
    setRows(rowsToRender);
    setIncapacitiesLoading(false);
  }

  const viewDocumentIncapacity = async (params) => {
    //Get data
    setLoadingDocument(true);

    console.log(params);
    const clinicId = clinicsMaping[props.clinic.cli_id]
    try {
      const resp = await appointmentService.viewDocumentIncapacity(clinicId,
        params.row.nro_Docum,
        params.row.Folio,
        null);
      var file = new Blob([resp.data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

    } catch {
      alert("Error al crear archivo")
    }
    setLoadingDocument(false);
  }

  const handleApprovalIncapacity = (params) => {
    props.handleOpenCloseSendIncapacityToEmployerModal(params.row);
    //console.log("params row ",params.row)
    //changeIncapacityStatus(params.row, "Radicado por EPS");
  }

  useEffect(async () => {
    //effect to hear communication and update appointments
    if (communicationState.lastMessage) {
      const { incapacitiesReload } = communicationState.lastMessage
      if (incapacitiesReload) {
        const clinicId = clinicsMaping[props.clinic.cli_id]
        await getAllIncapacities(props.user, props.clinic.cli_id);
      }
    }
  }, [communicationState])


  useEffect(async () => {
    //await getAllIncapacities(props.user, props.clinic);
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        rows={props.rows}
        loading={props.incapacitiesLoading}
        columns={columnsIncapacities}
        pageSize={4}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(MyIncapacitiesTable);