import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutoComplete = ({id, getOptionLabel, options, handleSelection, label}) =>{

    const [value, setValue] = useState("Selecciona una Clinica");
    const [inputValue, setInputValue] = useState('');

    return (
        <Autocomplete
            onChange={(event, newValue) => {
                setValue(newValue);
                handleSelection(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            id={`autocomplete-${id}`}
            options={options}
            getOptionLabel={getOptionLabel}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
    );
}

export default AutoComplete;