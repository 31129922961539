export const loginRequest = payload => ({
    type: 'LOGIN_REQUEST',
    payload,
  });
  
export const logoutRequest = payload => ({
  type: 'LOGOUT_REQUEST',
  payload,
});

export const registerRequest = payload => ({
  type: 'REGISTER_REQUEST',
  payload,
});

export const faceLoginRequest = payload => ({
  type: 'FACE_LOGIN_REQUEST',
  payload,
});

export const setClinic = payload => ({
  type: 'SET_CLINIC',
  payload,
});

export const setPatient = payload => ({
  type: 'SET_PATIENT',
  payload,
});

export const setLastContrat = payload => ({
  type: 'SET_LASTCONTRAT',
  payload,
});