import React, {useEffect}from 'react';
import Typography from '@material-ui/core/Typography';


const ConfirmAppointmentInfo = (props) => {
    return (
        <>
            <Typography component="h5">
                <strong>Nombre del paciente:</strong> {props.infoAppointment.data.MPNOMC}
            </Typography>
            <Typography component="h5">
                <strong>Identificación:</strong> {props.infoAppointment.data.MPCedu}
            </Typography>
            <Typography component="h5">
                <strong>Nombre de medico:</strong> {props.infoAppointment.data.nommed}
            </Typography>
            <Typography component="h5">
                <strong>Especialidad:</strong> {props.infoAppointment.data.menome}
            </Typography>
            <Typography component="h5">
                <strong>Numbre del Consultorio:</strong> {props.infoAppointment.data.nom_consultorio}
            </Typography>
            <Typography component="h5">
                <strong>Fecha:</strong> {props.infoAppointment.data.nombre_dia} {props.infoAppointment.data.fecha_cita}
            </Typography>
            <Typography component="h5">
                <strong>Hora:</strong> {props.infoAppointment.data.hora_cita}
            </Typography>
            <Typography component="h5">
                <strong>Tiempo estimado de la cita:</strong> {props.infoAppointment.data.tiempo_cita} min
            </Typography>
            {/* <Typography component="h5">
                <strong>Departamento:</strong> {props.infoAppointment.data.MDNomD}
            </Typography>
            <Typography component="h5">
                <strong>Municipio:</strong> {props.infoAppointment.data.MDNomM}
            </Typography> */}
            {props.hasToPay.show && (
                <Typography component="h5">
                    <strong>Valor cuota moderadora:</strong> {props.hasToPay.valueToPay}
                </Typography>
            )}
        </>
    )
}

export default ConfirmAppointmentInfo;