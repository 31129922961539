import axios from 'axios';


const registerPatient = async (form, cli_id) => {
    // Parse data
    const data = JSON.stringify(form)
    const dataToSend = {
      json: data,
      id_clinica: cli_id
    }
    let params = new URLSearchParams()
    Object.entries(dataToSend).forEach(([k, v]) => params.append(k, v))

    // andpoint url
    const url= process.env.REACT_APP_URL_HOST_AMS + "/api/storeUserHIS";

    // axios config
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const resultData = await axios.post(url, params, config).then(result => result)
    return resultData
    /* .then((result) => {
      // Do somthing
      console.log("succes", result);
      props.setPatient(result.data.data)
      handleAlert({
        ...alert,
        show: true,
        type: "success",
        message: `Informacion almacenada correctamente`,
        strong: "Por favor completa la siguiente informaciión"
      })
    })
    .catch((err) => {
      // Do somthing
      console.log("Error", err);
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `No podemos completar esta acción`,
        strong: "Intenta nuevamente"
      })
    }) */
}

const updatePatient = async (form, cli_id) => {
  // Parse data
  const data = JSON.stringify(form)
  const dataToSend = {
    json: data,
    id_clinica: cli_id
  }
  let params = new URLSearchParams()
  Object.entries(dataToSend).forEach(([k, v]) => params.append(k, v))

  // andpoint url
  const url= process.env.REACT_APP_URL_HOST_AMS + "/api/updateUserHIS";

  // axios config
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  const resultData = await axios.put(url, params, config).then(result => result)
  return resultData
}

const registerContratPatient = async (form, cli_id) => {
  // Parse data
  const data = JSON.stringify(form)
  const dataToSend = {
    json: data,
    id_clinica: cli_id
  }
  let params = new URLSearchParams()
  Object.entries(dataToSend).forEach(([k, v]) => params.append(k, v))

  // andpoint url
  const url= process.env.REACT_APP_URL_HOST_AMS +  "/api/storePacienteContrato";

  // axios config
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  const resultData = await axios.post(url, params, config).then(result => result)
  return resultData
}

const checkVerificationCode = async (user, verificationCodeInput) => {
  // Parse data
  const data = JSON.stringify({
    user,
    verificationCodeInput
  });

  // andpoint url
  const url= process.env.REACT_APP_URL_HOST + "/cerotram/checkverificationcode/";

  const resultData = await axios({ method:'post', url:url, data:data }).then(result => result)
  return resultData
}

export const hisServices = {
  registerPatient,
  updatePatient,
  registerContratPatient,
  checkVerificationCode
}