import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import red from "@material-ui/core/colors/red";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import ShowAlert from "../../components/ShowAlert";
import Divider from "@material-ui/core/Divider";
import useBrowserContextCommunication from "react-window-communication-hook";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ContractInfoForm from "../../components/staff/ContractInfoForm";
import UploadDocument from "../../components/UploadDocument";
// Services
import { hisServices } from "../../services/hisServices";
import { staffAppointmentService } from "../../services/staff/staffAppointmentService";
//Mapeo de clinicas
import { clinicsM } from "../../services/clinicsM";
import DashboardStaff from "./DashboardStaff";
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    paddingLeft: "50px",
    paddingTop: "0px",
    paddingRight: "50px",
    paddingBottom: "20px",
    //backgroundColor: theme.palette.background.paper,
    //padding: theme.spacing(5, 5, 5),
  },
  grid: {
    marginLeft: "101%",
    //marginTop: "-10px",
  },
}));

export default function UpdateContractPriorityInfoModal(props) {
  const classes = useStyles();
  const [editContract, setEditContract] = useState(false);
  const [haveChangesToSave, setHaveChangesToSave] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  //const [formPriorityAppointment, setFormPriorityAppointment] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [estado, setEstado] = useState("");
  const [alert, handleAlert] = useState({
    show: false,
    type: null,
    title: null,
    message: null,
    strong: null,
    action: null,
  });
  const [communicationState, postMessage] = useBrowserContextCommunication(
    "DashboardStaffChanel"
  );
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: "false",
  };
  const [mostarEstado, setMostarEstado] = useState("");
  const [formContratos, setFormContratosValues] = useState({
    documento: "",
    tipo_documento: "",
    contrato: "",
    regimen: "",
    nivel_usuario: "",
    fecha_vigencia: "",
    prioridad: "",
    description: "",
    status_changed_to: "",
    motivo_to: "",
  });  
  const clinicsMaping = clinicsM.Maping();

  const [infoSanitas, setInfoSanitas] = useState();
  const [isSanitas, setIsSanitas] = useState(false);
  const [infoSaludTotal, setInfoSaludTotal] = useState();
  const [isSaludTotal, setIsSaludTotal] = useState(false);
  const [infoCompensar, setInfoCompensar] = useState();
  const [isCompensar, setIsCompensar] = useState(false);
  const [regimenPatient, setRegimenPatient] = useState("");
  const [levelPatient, setLevelPatient] = useState("");

  useEffect(() => {
    setMostarEstado();
    const priority = props.allLastContractInfo?.MPOrd
      ? parseInt(props.allLastContractInfo.MPOrd) + 1
      : 1;
    setFormContratosValues({
      ...formContratos,
      documento: props.allLastContractInfo.documento,
      tipo_documento: props.allLastContractInfo.tipo_documento,
      fecha_vigencia: new Date().toISOString().split("T")[0],
      prioridad: priority,
    });
  }, []);

  const handleCancelAppoinments = (params) => {
    const clinicIdCitapp = clinicsMaping[props.clinic.cli_id];
    const valueToEncrypt =
      "" +
      clinicIdCitapp +
      "|" +
      params.row.cerotram_user.card_id +
      `|${params.row.cerotram_user.card_id_type}|` +
      params.row.cita_num +
      "|Cancelacion por Citapp desde el correo|" +
      params.row.cerotram_user.user.username +
      "|" +
      params.row.appointment_date +
      "|" +
      params.row.appointment_time +
      "|" +
      params.row.paciente +
      "|" +
      params.row.medico;

    const urlCancelAppoinmentsModal =
      `https://portubien.com.co/citapp_user_desa_0//citas/cancelarCitaCorreo/` +
      encrypt(valueToEncrypt) +
      "/0_tram/0_staff/" +
      process.env.REACT_APP_FLAG_PRODUCTION_DEVELOP +
      "/" +
      params.row.cita_num;
    console.log("Url citapp ", urlCancelAppoinmentsModal);
    props.handleOpenCloseCancelAppoinmentsModal(urlCancelAppoinmentsModal);
  };

  function encrypt(valueToEncrypt) {
    const value = btoa(valueToEncrypt);
    //console.log("Valor encriptado =", value);
    return value;
  }

  const handleInputFormContratos = (event) => {
    setHaveChangesToSave(true);
    setFormContratosValues({
      ...formContratos,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    //if(formContratos.regimen && formContratos.nivel_usuario && formContratos.contrato && formContratos.status_changed_to && formPriorityAppointment){
    /* let data = await hisServices.registerContratPatient(formContratos, props.clinic.cli_id);
    console.log("response HIS:  ", data); */
    setSubmitLoading(true);
    if (props.citaNumSelected) {
      console.log("-----.....----- formContratos: ",formContratos)      
      console.log("-----.....----- props: ", props)
      if (formContratos.status_changed_to === "AUTORIZADO" && formContratos.contrato === "COMPENSARCEXT")  {
        const authCompensar = await staffAppointmentService.authCompensarEndpoint(props.selectedValueRowTable.row.cerotram_user.card_id_type, props.selectedValueRowTable.row.cerotram_user.card_id, props.selectedValueRowTable.row.appointment_date, props.selectedValueRowTable.row.appointment_time);
        console.log(authCompensar)
        handleAlert({
          ...alert,
          show: true,
          type: "success",
          message: `Autorizacion consultada exitosamente: `,
          strong: authCompensar,
        });
        setTimeout(() => handleAlert({ ...alert, show: false }), 8000);
        // props.handleOpenCloseUpdateInfoModal(ceroTramUser);
        //handleCancelAppoinments(props.selectedValueRowTable);
      // } else if(formContratos.status_changed_to === "AUTORIZADO"){        
      //     console.log("AUTORIZADO PERO NO CON COMPENSAR")
      } else {
        if (formContratos.status_changed_to === "NO AUTORIZADO") {
          var raw = JSON.stringify({
            clinica_id: props.selectedValueRowTable.row.info_dataRic.clinica_id,
            cita_num: props.selectedValueRowTable.row.cita_num,
            identificacion:
              props.selectedValueRowTable.row.info_dataRic.identificacion,
            nombre_paciente:
              props.selectedValueRowTable.row.info_dataRic.nombre_paciente,
            nombre_medico:
              props.selectedValueRowTable.row.info_dataRic.nombre_medico,
            fecha: props.selectedValueRowTable.row.info_dataRic.fecha,
            hora: props.selectedValueRowTable.row.info_dataRic.hora,
            mail: props.selectedValueRowTable.row.info_dataRic.mail,
            observacion: formContratos.description,
          });
          console.log("datos para cancelar cita", raw);
          const fileTransfer = await staffAppointmentService.cancelarcita(raw);
          console.log("cancelar cita", fileTransfer);
        }

        if (props.isSanitas) {
          //negate condition for pruebas
          //if patient is Sanitas
          const formPriorityAppointment = new FormData();
          console.log("selectedValueRowTable ", props.selectedValueRowTable);
          formPriorityAppointment.append(
            "user",
            JSON.stringify(props.selectedValueRowTable?.row?.cerotram_user)
          );
          formPriorityAppointment.append("file", fileToUpload);
          formPriorityAppointment.append("is_sanitas", props.isSanitas);
          formPriorityAppointment.append(
            "sanitas_info",
            JSON.stringify(props.infoSanitas)
          );
          formPriorityAppointment.append("cita_num", props.citaNumSelected);
          formPriorityAppointment.append(
            "status_changed_to",
            formContratos.status_changed_to
          );
          formPriorityAppointment.append(
            "description",
            formContratos.description
          );
          try {
            await staffAppointmentService.recordAppointmentStatusChangedSanitas(
              formPriorityAppointment
            );
          } catch {
            handleAlert({
              ...alert,
              show: true,
              type: "error",
              message: `Error de conexión`,
              strong: "por favor inténtelo nuevamente",
            });
            setTimeout(() => handleAlert({ ...alert, show: false }), 8000);
          }
        } else {
          //if patient is not Sanitas
          const formPriorityAppointment = new FormData();
          formPriorityAppointment.append("file", fileToUpload);
          formPriorityAppointment.append("cita_num", props.citaNumSelected);
          formPriorityAppointment.append(
            "status_changed_to",
            formContratos.status_changed_to
          );
          formPriorityAppointment.append(
            "description",
            formContratos.description
          );
          /*const formPriorityAppointment = JSON.stringify({
            "file": fileToUpload,
            "cita_num": props.citaNumSelected,
            "status_changed_to": formContratos.status_changed_to,
            "description": formContratos.description
          });*/
          formPriorityAppointment.append(
            "cerotram_user_id",
            props.user.id
          );

          if (formContratos.contrato === '') {
            formContratos.contrato = props.eps
          } 
          if (formContratos.regimen === '') {
            formContratos.regimen = props.regimen
          } 
          if (formContratos.nivel_usuario === '') {
            formContratos.nivel_usuario = props.level
          } 
          if (formContratos.status_changed_to === '') {
            formContratos.status_changed_to = 'PENDIENTE'
          } 
          
          const updateAppointmentData = {
            clinic_id: props.selectedValueRowTable.row.cerotram_user.clinic_id,
            contrato: formContratos.contrato,
            regimen: formContratos.regimen,
            nivel: formContratos.nivel_usuario,
            status: formContratos.status_changed_to,
            cerotram_user_id: props.selectedValueRowTable.row.cerotram_user.id,
            cita_num: props.selectedValueRowTable.row.cita_num,
            documento: props.selectedValueRowTable.row.cerotram_user.card_id,
            tipo_documento: props.selectedValueRowTable.row.cerotram_user.card_id_type,
          };

          try {
            await staffAppointmentService.updateAppointment(updateAppointmentData);
          } catch {
            handleAlert({
              ...alert,
              show: true,
              type: "error",
              message: `Error de conexión`,
              strong: "por favor inténtelo nuevamente",
            });
            setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
          }

          console.log(
            "MOTIVO ******************************* ",
            formContratos.motivo_to
          );
          try {
            await staffAppointmentService.recordAppointmentStatusChanged(
              formPriorityAppointment
            );
          } catch {
            handleAlert({
              ...alert,
              show: true,
              type: "error",
              message: `Error de conexión`,
              strong: "por favor inténtelo nuevamente",
            });
            setTimeout(() => handleAlert({ ...alert, show: false }), 8000);
          }
        }
      }
    } else {
      console.log("Prioritaria", formContratos);
      try {
        await staffAppointmentService.changePriorityPetitionAppointmentStatus(
          props.priorityPetitionApointmentId,
          formContratos.status_changed_to,
          formContratos.description
        );
      } catch {
        handleAlert({
          ...alert,
          show: true,
          type: "error",
          message: `Error de conexión`,
          strong: "por favor inténtelo nuevamente",
        });
        setTimeout(() => handleAlert({ ...alert, show: false }), 8000);
      }
    }

    /* await setTimeout(() =>{setSubmitLoading(false);}, 1000) */
    postMessage({
      PriorityStatusChanged: true,
    });
    // handleAlert({
    //   ...alert,
    //   show: true,
    //   type: "success",
    //   message: `Datos actualizados`,
    //   strong: "exitosamente",
    // });
    // setTimeout(() => handleAlert({ ...alert, show: false }), 8000);

    setEditContract(false);
    setHaveChangesToSave(false);
    postMessage({
      AppointmentStatusChanged: true,
    });
    /* }else{
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `Todos los campos`,
        strong: "son obligatorios"
      })
      setTimeout(() => handleAlert({...alert, show: false}), 8000);
    } */
    setSubmitLoading(false);
    handleOpenCloseUpdateInfoModal()
  };

  const handleDocumentToUpload = (fileToUpload) => {
    setFileToUpload(fileToUpload);
  };

  const handleOpenCloseUpdateInfoModal = async () => {
    setEditContract(false);
    setHaveChangesToSave(false);
    setSubmitLoading(false);
    await props.handleOpenCloseUpdateInfoModal();
    setIsSanitas(false);
    setIsSaludTotal(false);
    setIsCompensar(false);
    setInfoSanitas();
    setInfoSaludTotal();
    setInfoCompensar();
  };

  const handleEditContract = () => {
    //console.log("valor de info sanitas ", JSON.stringify(props.infoSanitas));
    //console.log("valor de info salud total ", JSON.stringify(props.infoSaludTotal));
    //setHaveChangesToSave(!haveChangesToSave);
    setEditContract(true);
  };
  
  const handleOpenDocument = () => {
    window.open(
      process.env.REACT_APP_URL_HOST +
        props.datesAndSpecialistInformation?.url_to_view_doc,
      "_blank"
    );
  };

  const handleCloseButton = () => {
    postMessage({
      PriorityStatusChanged: true,
    });
    postMessage({
      AppointmentStatusChanged: true,
    });
    setEditContract(false);
    //setCloseModal(true);
    props.handleOpenCloseUpdateInfoModal();
  };

  const handleDataFromChild = (data, eps, regimen, level) => {
    // Actualiza el estado con los datos del componente hijo
    console.log("Llega data del hijo:", data)
    setRegimenPatient(regimen);
    setLevelPatient(level);
    if (eps === "ENTIDAD PROMOTORA DE SALUD SANITAS S.A.S"){
      setIsSanitas(true);
      setIsSaludTotal(false);
      setIsCompensar(false);
      setInfoSanitas(data);
      setInfoSaludTotal();
      setInfoCompensar();
    } else if (eps === "SALUD TOTAL  S.A." && data !== "El paciente no existe."){
      setIsSanitas(false);
      setIsSaludTotal(true);
      setIsCompensar(false);   
      setInfoSaludTotal(data);
      setInfoSanitas();
      setInfoCompensar();
    }else if (eps === "COMPENSAR" && data !== "El paciente no existe."){
      setIsSanitas(false);
      setIsSaludTotal(false);
      setIsCompensar(true);
      setInfoCompensar(data);
      setInfoSanitas();
      setInfoSaludTotal();
    }else{
      setIsSanitas(false);
      setIsSaludTotal(false);
      setIsCompensar(false);
      setInfoSanitas();
      setInfoSaludTotal();
      setInfoCompensar();
    }
  };

  const handleExcel = () => {
    if (infoSanitas || infoSaludTotal || infoCompensar){
      getReporteExcelValidador();
    } else {
      getReporteExcel();
    }
  }  

  const getReporteExcelValidador = async () => {
    if (isCompensar){
      const datosExcel = [{
        Nombre_Paciente: props.selectedValueRowTable.row.info_dataRic.nombre_paciente,
        Identificacion: props.selectedValueRowTable.row.info_dataRic.identificacion,
        Tipo_Identificacion: props.selectedValueRowTable.row.info_dataRic.tipo_identificacion,
        Correo: props.selectedValueRowTable.row.info_dataRic.mail,
        Telefono: props.selectedValueRowTable.row.info_dataRic.telefono_ws,
        Numero_Cita: props.selectedValueRowTable.row.cita_num,
        Lugar_Cita: props.selectedValueRowTable.row.info_dataRic.cit_lugar,
        Nombre_Medico: props.selectedValueRowTable.row.info_dataRic.nombre_medico,
        Especialidad: props.selectedValueRowTable.row.info_dataRic.especialidad,
        Fecha_Programacion: props.selectedValueRowTable.row.info_dataRic.fecha,
        Hora_Programacion: props.selectedValueRowTable.row.info_dataRic.hora,
        Clinica_Id: props.selectedValueRowTable.row.info_dataRic.clinica_id,
        Cuota_Moderadora: props.selectedValueRowTable.row.info_dataRic.cuota_moderadora,
        Departamento: props.selectedValueRowTable.row.info_dataRic.departamento,
        Direccion: props.selectedValueRowTable.row.info_dataRic.direccion,
        Estado: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.STATUS,
        Regimen: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.TIPO_USUARIO,
        Tipo_Afiliado: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.TIPO_AFILIADO,
        Parentezco: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.PARENTEZCO,
        Codigo_Ciudad: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.CODIGO_CIUDAD,
        Codigo_Departamento: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.CODIGO_DEPARTAMENTO,
        Nautcli: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.NAUTCLI,
        Convenio: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.CONVENIO,
        Alerta: infoCompensar.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.ALERTA
      }];
      exportToExcel(datosExcel);
      console.log("getReporteExcelValidador comp");
    } else if(isSaludTotal){
      const datosExcel = [{
        Nombre_Paciente: props.selectedValueRowTable.row.info_dataRic.nombre_paciente,
        Identificacion: props.selectedValueRowTable.row.info_dataRic.identificacion,
        Tipo_Identificacion: props.selectedValueRowTable.row.info_dataRic.tipo_identificacion,
        Correo: props.selectedValueRowTable.row.info_dataRic.mail,
        Telefono: props.selectedValueRowTable.row.info_dataRic.telefono_ws,
        Numero_Cita: props.selectedValueRowTable.row.cita_num,
        Lugar_Cita: props.selectedValueRowTable.row.info_dataRic.cit_lugar,
        Nombre_Medico: props.selectedValueRowTable.row.info_dataRic.nombre_medico,
        Especialidad: props.selectedValueRowTable.row.info_dataRic.especialidad,
        Fecha_Programacion: props.selectedValueRowTable.row.info_dataRic.fecha,
        Hora_Programacion: props.selectedValueRowTable.row.info_dataRic.hora,
        Clinica_Id: props.selectedValueRowTable.row.info_dataRic.clinica_id,
        Cuota_Moderadora: props.selectedValueRowTable.row.info_dataRic.cuota_moderadora,
        Departamento: props.selectedValueRowTable.row.info_dataRic.departamento,
        Direccion: props.selectedValueRowTable.row.info_dataRic.direccion,
        Estado: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.STATUS,
        Estado_Detallado: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.ESTADO_DETALLADO,
        Regimen: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.REGIMEN,
        Tipo_Usuario: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.TIPO_USUARIO,
        Nivel: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.RANGO_SALARIAL,
        Contrato: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.CONTRATO,
        Contrato_Inicio: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.CONTRATO_INICIO,
        Municipio: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.MUNICIPIO,
        Departamento: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.DEPARTAMENTO,
        IPS_Medica: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.IPS_MEDICA_NOMBRE,
        IPS_Odontologica: infoSaludTotal.CONTRATOS[0].REGIMEN_CONTRIBUTIVO.IPS_ODONTOLOGICA_NOMBRE 
      }];
      exportToExcel(datosExcel);
      console.log("getReporteExcelValidador st");
    } else {
      console.log("no se tiene info")
    }
  }

  const getReporteExcel = async () => {
    const datosExcel = [{
      Nombre_Paciente: props.selectedValueRowTable.row.info_dataRic.nombre_paciente,
      Identificacion: props.selectedValueRowTable.row.info_dataRic.identificacion,
      Tipo_Identificacion: props.selectedValueRowTable.row.info_dataRic.tipo_identificacion,
      Regimen: regimenPatient,
      Nivel: levelPatient,
      Correo: props.selectedValueRowTable.row.info_dataRic.mail,
      Telefono: props.selectedValueRowTable.row.info_dataRic.telefono_ws,
      Numero_Cita: props.selectedValueRowTable.row.cita_num,
      Lugar_Cita: props.selectedValueRowTable.row.info_dataRic.cit_lugar,
      Nombre_Medico: props.selectedValueRowTable.row.info_dataRic.nombre_medico,
      Especialidad: props.selectedValueRowTable.row.info_dataRic.especialidad,
      Fecha_Programacion: props.selectedValueRowTable.row.info_dataRic.fecha,
      Hora_Programacion: props.selectedValueRowTable.row.info_dataRic.hora,
      Clinica_Id: props.selectedValueRowTable.row.info_dataRic.clinica_id,
      Cuota_Moderadora: props.selectedValueRowTable.row.info_dataRic.cuota_moderadora,
      Departamento: props.selectedValueRowTable.row.info_dataRic.departamento,
      Direccion: props.selectedValueRowTable.row.info_dataRic.direccion,
    }];
    exportToExcel(datosExcel);
  }  

  const exportToExcel = (data) => {
    // Crea una hoja de trabajo de Excel vacía
    const wb = XLSX.utils.book_new();
    // Crea una hoja de Excel a partir de los datos
    const ws = XLSX.utils.json_to_sheet(data);
    // Agrega la hoja de Excel a la hoja de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'Datos');
    // Convierte la hoja de trabajo en un archivo de Excel binario
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    // Crea un objeto blob a partir del archivo binario
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    // Descarga el archivo
    saveAs(blob, 'paciente_lista_autorizacion.xlsx');
  };
  
  // Convierte una cadena en un array de bytes
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  
  // Descarga el archivo con el nombre especificado
  const saveAs = (blob, filename) => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // URL.createObjectURL() funciona en Chrome y Firefox
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // URL.revokeObjectURL() funciona en Chrome y Firefox
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={handleOpenCloseUpdateInfoModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Fade in={props.open}>
        <Paper className={classes.paper}>
          <Grid className={classes.grid}>
            <IconButton
              edge="start"
              size="medium"
              onClick={() => handleCloseButton()}
            >
              <CloseIcon style={{ color: red[400] }} />
            </IconButton>
          </Grid>

          <div style={{ width: "100%" }}>
            {" "}
            {alert.show && <ShowAlert {...alert} />}
          </div>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            spacing={1}
          >
            {!props.citaNumSelected && (
              <Paper variant="outlined" style={{ paddingBottom: "20px" }}>
                <Grid item>
                  <Container component="main" maxWidth="sm" spacing={3}>
                    <Typography variant="h6" gutterBottom>
                      INFORMACIÓN DEL CONTRATO:
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography component="h5">
                          <strong>Nombre:</strong>{" "}
                          {props.user?.user?.first_name}{" "}
                          {props.user?.user?.last_name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Identificación:</strong> {props.user?.card_id}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Correo electrónico:</strong>{" "}
                          {props.user?.user?.username}
                        </Typography>
                      </Grid>
                      {props.user?.phone && (
                        <Grid item>
                          <Typography component="h5">
                            <strong>Teléfono:</strong> {props.user?.phone}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: "10px" }}
                    >
                      DISPONIBILIDAD DEL PACIENTE
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography component="h5">
                          {props.datesAndSpecialistInformation?.date1}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          {props.datesAndSpecialistInformation?.date2}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          {
                            /* new Date(props.datesAndSpecialistInformation?.date3).toLocaleString('es-ES', options) */
                            props.datesAndSpecialistInformation?.date3
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ marginTop: "10px" }}
                    >
                      ¿SOLICITA A EL MISMO ESPECIALISTA?
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography component="h5">
                          <strong>
                            {props.datesAndSpecialistInformation
                              ?.need_the_same_specialist
                              ? "SI"
                              : "NO"}
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => handleOpenDocument()}
                          >
                            Ver documento
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              </Paper>
            )}

            {isSanitas && (
              //Sanitas contract information
              <Paper variant="outlined" style={{ paddingBottom: "20px" }}>
                <Grid item>
                  <Container component="main" maxWidth="sm" spacing={3}>
                  <Typography variant="h6" gutterBottom color="primary" style={{ marginTop: "15px" }}>
                    VALIDADOR DE DERECHOS
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography component="h5">
                          <strong>Código producto :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CODIGO_PRODUCTO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Plan :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.PLAN}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Familia :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.FAMILIA}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Régimen :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.REGIMEN}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Tipo de usuario :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.TIPO_USUARIO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Nivel :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.RANGO_SALARIAL}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Contrato :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CONTRATO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Contrato Inicio :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CONTRATO_INICIO}
                        </Typography>
                      </Grid>                      
                      <Grid item>
                        <Typography component="h5">
                          <strong>Estado :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.STATUS}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Estado afiliación observación :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.ESTADO_AFILIACION_OBSERVACION}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>IPS médica :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.IPS_MEDICA_NOMBRE}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>IPS odontológica :</strong> {infoSanitas?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.IPS_ODONTOLOGICA_NOMBRE}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                    </Grid>
                  </Container>
                </Grid>
              </Paper>
            )}

            {isSaludTotal && (
              //SaludTotal contract information
              <Paper variant="outlined" style={{ paddingBottom: "20px" }}>
                <Grid item>
                  <Container component="main" maxWidth="sm" spacing={3}>
                  <Typography variant="h6" gutterBottom color="primary" style={{ marginTop: "15px" }}>
                      VALIDADOR DE DERECHOS
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography component="h5">
                          <strong>Estado :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.STATUS}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Estado detallado :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.ESTADO_DETALLADO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Régimen :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.REGIMEN}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Tipo de usuario :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.TIPO_USUARIO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Nivel :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.RANGO_SALARIAL}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Contrato :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CONTRATO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Contrato Inicio :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CONTRATO_INICIO}
                        </Typography>
                      </Grid>                      
                      <Grid item>
                        <Typography component="h5">
                          <strong>Municipio :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.MUNICIPIO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Departamento :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.DEPARTAMENTO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>IPS médica :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.IPS_MEDICA_NOMBRE}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>IPS odontológica :</strong> {infoSaludTotal?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.IPS_ODONTOLOGICA_NOMBRE}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                    </Grid>
                  </Container>
                </Grid>
              </Paper>
            )}

            {isCompensar && (
              //Compensar contract information
              <Paper variant="outlined" style={{ paddingBottom: "20px" }}>
                <Grid item>
                  <Container component="main" maxWidth="sm" spacing={3}>
                    <Typography variant="h6" gutterBottom color="primary" style={{ marginTop: "15px" }}>
                      VALIDADOR DE DERECHOS
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography component="h5">
                          <strong>Estado :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.STATUS}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Régimen :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.TIPO_USUARIO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Tipo afiliado :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.TIPO_AFILIADO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Parentezco :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.PARENTEZCO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Código Ciudad :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CODIGO_CIUDAD}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Código Departamento :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CODIGO_DEPARTAMENTO}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Nautcli :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.NAUTCLI}
                        </Typography>
                      </Grid>         
                      <Grid item>
                        <Typography component="h5">
                          <strong>Convenio :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.CONVENIO}
                        </Typography>
                      </Grid>       
                      <Grid item>
                        <Typography component="h5">
                          <strong>Alerta :</strong> {infoCompensar?.CONTRATOS[0]?.REGIMEN_CONTRIBUTIVO?.ALERTA}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                    </Grid>
                  </Container>
                </Grid>
              </Paper>
            )}

            <Paper variant="outlined" style={{ paddingBottom: "20px" }}>
              <Grid item>
                <ContractInfoForm
                  isAuthorized={props.citaNumSelected ? true : false}
                  clinic={props.clinic}
                  user={props.user}
                  handleInput={handleInputFormContratos}
                  //handleInput={props.handleInput}
                  editContract={editContract}
                  allEps={props.allEps}
                  mostarEstado={props.mostarEstado}
                  allRegimen={props.allRegimen}
                  allLevels={props.allLevels}
                  eps={props.eps}
                  regimen={props.regimen}
                  level={props.level}
                  selectedValueRowTable={props.selectedValueRowTable}
                  estado={estado}
                  submitLoading={submitLoading}
                  handleDataFromChild={handleDataFromChild}
                />
              </Grid>
              <Grid item style={{ marginTop: "5px" }}>
                {editContract && !props.isSanitas && (
                  <UploadDocument
                    handleDocumentToUpload={handleDocumentToUpload}
                  />
                )}
              </Grid>
            </Paper>
          </Grid>

          {submitLoading ? (
            <LinearProgress style={{ marginTop: "5%" }} />
          ) : (
            <Grid item style={{ marginTop: "2%" }}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                spacing={2}
              >
                {props.mostarEstado == "Reservada" ? (
                  <Grid item style={{ paddingRight: "8px" }}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleExcel()}
                  >
                    Descarga Excel
                  </Button>
                </Grid>
                ) : (
                  <Grid item></Grid>
                )}                
                <Grid item>
                  <Button
                    size="small"
                    onClick={() => handleOpenCloseUpdateInfoModal()}
                  >
                    Cancelar
                  </Button>
                </Grid>
                {props.mostarEstado == "Reservada" ? (
                  <Grid item>
                    {!editContract && (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEditContract()}
                      >
                        Editar
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <Grid item></Grid>
                )}

                {props.mostarEstado == "Reservada" ? (
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      disabled={!haveChangesToSave}
                      onClick={() => handleSubmit()}
                    >
                      Guardar
                    </Button>
                  </Grid>
                ) : (
                  <Grid item></Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
}
