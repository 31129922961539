import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';


import { makeStyles } from '@material-ui/core/styles';

import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import DoneIcon from '@material-ui/icons/Done';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(14, 0, 0.6),
    paddingBottom: "20px",
    //borderRadius: '0px!important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    borderColor: '#e2316f',
    textTransform: 'none',
    fontSize: 13,
    color:"#e2316f",
  },
  dialogTitle: {
    borderColor: '#097F72',
    fontSize: 13,
    
  },
  field: {
    width: "100%",
    marginTop: "15px",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop: "10px",
    marginBottom: "17%",
    paddingTop: "20px",
    paddingBottom: "10px",
    paddingRight: "20px",
  },

}));


export default function AlertDialog(props) {

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props.open}
        maxWidth='xs'
        onClose={props.handleAlertDialogSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"
        className={classes.dialogTitle}
        >{props.title}</DialogTitle>
        <Divider light />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={props.handleClose} color="primary">
            Cancelar
          </Button> */}
          <Button 
          className={classes.button}
          variant="outlined"
          disableRipple
          size="small"
          disableElevation
          endIcon={<NotificationsOffIcon />}
          onClick={props.handleDisableNotifyIncapacities}  >
            No volver a recordarme
          </Button>
          <Button 
          variant="contained"
          size="small"          
          endIcon={<DoneIcon />}               
          style={{textTransform: 'none'}}
          onClick={props.handleAlertDialogSuccess} color="primary" >
            Radicar
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}