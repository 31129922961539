import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridFilterToolbarButton } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ShowAlert from '../components/ShowAlert';
import AppointmentsBottomNavigation from './AppointmentsBottomNavigation';
import renderCellExpand from './renderCellExpand';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
// Services
import { appointmentService } from '../services/appointmentService';
//Mapeo de clinicas
import { clinicsM } from '../services/clinicsM';
//icons
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const AppointmentsTable = (props) => {
  const [hasToPay, setHasToPay] = useState({ show: false, id: null, valueToPay: 0 });
  const [linkToTransferFiles, setLinkToTransferFiles] = useState("#")
  const [appointmentSelected, setAppointmentSelected] = useState(0)
  const [selectionModel, setSelectionModel] = React.useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
        <Tooltip title="Pagar Cuota Moderadota">
          <IconButton
            disabled={!hasToPay.show}
            onClick={() => handlePay()}
          >
            <MonetizationOnRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Adjuntar documentos">
          <IconButton
            disabled={!(linkToTransferFiles !== "#")}
            onClick={() => openTransferFileModal()}
          >
            <PublishRoundedIcon />
          </IconButton>
        </Tooltip>
      </GridToolbarContainer>
    );
  }
  const clinicsMaping = clinicsM.Maping();

  const columns = [
    { field: 'fecha', headerName: 'Fecha', width: 105, renderCell: renderCellExpand },
    { field: 'CitHorI', headerName: 'Hora', width: 80, filterable: false, renderCell: renderCellExpand },
    { field: 'medico', headerName: 'Medico', width: 190, filterable: false, renderCell: renderCellExpand },
    { field: 'MENomE', headerName: 'Especialidad', width: 150, renderCell: renderCellExpand },
    { field: 'CitNomPr', headerName: 'Cups', width: 150, filterable: false, renderCell: renderCellExpand },
    { field: 'estado', headerName: 'Estado', width: 100, renderCell: renderCellExpand },
    {
      field: 'Cancelar',
      headerName: 'Cancelar',
      width: 90,
      renderCell: (params) => (
        <Tooltip title="Cancelar cita">
          <IconButton
            disabled={params.row.estado == "Cancelada" ? true : false}
            onClick={() => handleCancelAppoinments(params)}
          >
            {params.row.estado !== "Cancelada" && <CancelIcon style={{ color: red[700] }} />}

          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'Reprogramar',
      headerName: 'Reprogramar',
      width: 120,
      renderCell: (params) => (
        <Tooltip title="Reprogramar">
          <IconButton
            disabled={params.row.estado == "Cancelada" ? true : false}
            onClick={() => handleRescheduleAppoinments(params)}
          >
            {params.row.estado !== "Cancelada" && <CheckCircleIcon style={{ color: green[700] }} />}

          </IconButton>
        </Tooltip>
      ),
    },
  ]
  const handleCancelAppoinments = (params) => {
    const clinicIdCitapp = clinicsMaping[props.clinic.cli_id];
    const valueToEncrypt = "" + clinicIdCitapp + "|" + params.row.CitCed.trim() + `|${props.user.card_id_type}|` + params.row.CitNum +
      "|Cancelacion por Citapp desde el correo|" + params.row.MpMail.trim() + "|" + params.row.fecha + "|"
      + params.row.CitHorI + "|" + params.row.CITPAC.trim() + "|" + params.row.medico.trim();
    console.log('valueToEncrypt', valueToEncrypt);
    const urlCancelAppoinmentsModal = `https://portubien.com.co/citapp_user_desa_0//citas/cancelarCitaCorreo/` + encrypt(valueToEncrypt) + '/0_tram/';
    props.handleOpenCloseCancelAppoinmentsModal(urlCancelAppoinmentsModal);
  }


  const handleRescheduleAppoinments = (params) => {
    //Method Reprogramar cita    
    const clinicIdCitapp = clinicsMaping[props.clinic.cli_id];

    const MECodE = params.row.CitEspMed // codigo especialidad
    const EspCodPIn = params.row.CitPro.replace(/\s/g, '') // codigo tipo de cita sin espacios
    const CitNroCto = params.row.CitNroCto.replace(/\s/g, '') //Contrato de cita
    const urlCitapp = `https://portubien.com.co/citapp_user_desa_0/login/view_index2/${clinicIdCitapp}/${props.user.card_id_type}/${props.user.card_id}/${CitNroCto}/${MECodE}/${EspCodPIn}/0_tram/` + process.env.REACT_APP_FLAG_PRODUCTION_DEVELOP;


    console.log("params ", urlCitapp)
    const valueToEncrypt = "" + clinicIdCitapp + "|" + params.row.CitCed.trim() + `|${props.user.card_id_type}|` + params.row.CitNum +
      "|Cancelacion por Citapp desde el correo|" + params.row.MpMail.trim() + "|" + params.row.fecha + "|"
      + params.row.CitHorI + "|" + params.row.CITPAC.trim() + "|" + params.row.medico.trim();
    const urlCancelAppoinmentsModal = `https://portubien.com.co/citapp_user_desa_0//citas/cancelarCitaCorreo/` + encrypt(valueToEncrypt);
    props.handleOpenCloseRescheduleCancelAppointmentModal(urlCancelAppoinmentsModal, urlCitapp);
  }


  const handleTransferFileAppoinments = (params) => {
    const clinicIdCitapp = props.clinicsMaping[props.clinic.cli_id];
    const valueToEncrypt = params.row.CitCed + "-" + params.row.CitNum + "-" + clinicIdCitapp;
    const urlTransferFileAppointmentsModal = `https://portubien.com.co/appRic_desarrollo/transfer-files/` + encrypt(valueToEncrypt);
    //const urlTransferFileAppointmentsModal = `https://portubien.com.co/appRic_desarrollo/transfer-files/MTAzMTE2MjQ1My0xMDYxMjgtNQ==`;
    setLinkToTransferFiles(urlTransferFileAppointmentsModal);
    console.log(urlTransferFileAppointmentsModal);
  }

  const openTransferFileModal = () => {
    props.handleOpenCloseTransferFileAppointmentsModal(linkToTransferFiles);
  }


  function encrypt(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
  }

  const handleRowSelected = async (params) => {
    if ((params.row.CitNum !== appointmentSelected) && props.title != 'Histórico de citas registradas') {
      setLinkToTransferFiles("#")
      setHasToPay({ show: false })
      const cita_num = params.row.CitNum;
      // const cerotram_user_id = params.row.CitNum;
      console.log("aquiiiiii", params.row)
      const info = await appointmentService.getAppointmentInfo(parseInt(cita_num));
      console.log("info value ", info)
      const { valueToPay, teleConsultaID } = info.data;
      if (parseInt(valueToPay) > 0) {
        setHasToPay({
          show: true,
          id: teleConsultaID,
          valueToPay: valueToPay
        })
      }
      if (teleConsultaID) handleTransferFileAppoinments(params);
    }
    setAppointmentSelected(params.row.CitNum);
  }

  const handlePay = () => {
    const baseUrl = "https://portubien.com.co/appRic_desarrollo/"
    const url = baseUrl + "ApiTeleconsulta/PasarelaPagos/" + btoa(`${hasToPay.id}|${hasToPay.valueToPay}`)
    const win = window.open(url, '_blank');
    win.focus();
  }

  const handleSwitchChange = (selected) => {
    if (selected === 'history') {
      setLinkToTransferFiles("#");
      setHasToPay({ show: false });
    }
    props.handleSwitchChange(selected);
  }

  const handleCheckBox = (newSelection) => {
    const newSet = new Set(newSelection.selectionModel);
    const lastSet = new Set(selectionModel);
    let newRowselected = [...newSet].filter(x => !lastSet.has(x));
    if (newRowselected.length === 0) newRowselected = newSelection.selectionModel;
    if (newRowselected.length > 1) newRowselected = []

    const params = { row: props.rows[parseInt(newRowselected[0])] }
    if (newRowselected.length === 1) {
      handleRowSelected(params)
    } else {
      setLinkToTransferFiles("#")
      setHasToPay({ show: false })
      setAppointmentSelected(0);
    }
    setSelectionModel(newRowselected);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        rows={props.rows}
        columns={columns}
        pageSize={4}
        components={{
          Toolbar: CustomToolbar
        }}
        disableColumnMenu={true}
        loading={props.appointmentLoading}
        onRowClick={(params) => {
          handleRowSelected(params);
        }}
        checkboxSelection={
          props.title === 'Histórico de citas registradas' ? false : true
        }
        hideFooterSelectedRowCount
        onSelectionModelChange={(newSelection) => {
          handleCheckBox(newSelection);
        }}
        selectionModel={selectionModel}
      />
      <AppointmentsBottomNavigation
        handleSwitchChange={handleSwitchChange}
      />
    </React.Fragment>
  );
}
export default AppointmentsTable;