import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';

import UpdateInfoSteper from '../UpdateInfoSteper';
import RegisterInfoSteper from '../RegisterInfoSteper';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width:900,
    padding: theme.spacing(2, 4, 3),
  },
  grid:{
    height:"0px", 
    marginLeft:"98%",
    marginTop:"-10px",
  },
}));

export default function UpdateInfoModal(props) {
  const classes = useStyles();

  const handleCloseButton=()=>{
    //setCloseModal(true);
    props.handleOpenCloseUpdateInfoModal()
  }


  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={props.open}
    onClose={props.handleOpenCloseUpdateInfoModal}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
        timeout: 500,
    }}
    disableBackdropClick={props.disableBackdropClick}
    >
    <Fade in={props.open}>
        <Grid className={classes.paper}>
        <Grid className={classes.grid} >
              <IconButton edge="start" size="medium" onClick={()=>handleCloseButton()}>
                  <CloseIcon  style={{ color: red[400] }} />
              </IconButton>
          </Grid>
            {props.havePatient ?
              <UpdateInfoSteper
                handleOpenCloseUpdateInfoModal={props.handleOpenCloseUpdateInfoModal}
                disableCancelButton={props.disableBackdropClick}
              />:
              <RegisterInfoSteper
                handleOpenCloseUpdateInfoModal={props.handleOpenCloseUpdateInfoModal}
                disableCancelButton={props.disableBackdropClick}
              />
            }
        </Grid>
    </Fade>
    </Modal>
  );
}