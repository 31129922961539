import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import {
  Typography,
  makeStyles,
} from '@material-ui/core';
import ShowAlert from '../../components/ShowAlert';
// redux
import { connect } from 'react-redux';
// Services
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';
import { format, parseISO } from 'date-fns';
import renderCellExpand from '../../components/renderCellExpand';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: "20px",
    //borderRadius: '0px!important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  field: {
    width: "100%",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 160,
  },

}));

const clinicsMaping =  clinicsM.Maping();

function MyExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const StaffAuthorizations = (props) => {

  const [title, setTitle] = useState('Autorizaciones');
  const [rowsLoading, setRowsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const columnsIncapacities = [
    { field: 'TIPO_ID', headerName: 'Tipo Documento', width: 140},
    { field: 'ID_PACIENTE', headerName: 'Documento', width: 140},   
    { field: 'nombreCompleto', headerName: 'Paciente', width: 200, renderCell: renderCellExpand},
    { field: 'Edad', headerName: 'Edad', width: 90},
    { field: 'CITA_MEDICA', headerName: 'Cita Médica', width: 140},    
    { field: 'ESTADO', headerName: 'Estado', width: 140},
    { field: 'FECHA_CITA', headerName: 'Fecha Cita', width: 170},
    { field: 'CUPS', headerName: 'Cups', width: 150},
    { field: 'CELULAR', headerName: 'Celular', width: 170},
  ]

  useEffect(async () => {
    await getAllAuthorizations();
  }, [])
  
  //Consulta si la cita se encuentra en estado atendida
  const handleRowSelected = async (params) => {
    if (params.row.ESTADO === "ATENDIDA"){
      //Se consulta primero el afiliado para extraer el plan de autorización
      const documento = params.row.ID_PACIENTE.replace(/\s+/g, '')
      const tipoDocumento = params.row.TIPO_ID.replace(/\s+/g, '')
      const parseToDocumentType = ["","CC", "NI", "TI", "CE", "PA", "CD", "RC", "UN", "CN", "SC", "PE", "AS", "MS"]
      const searchIndex = (element) => element == tipoDocumento;
      const idxTipoDocumento = parseToDocumentType.findIndex(searchIndex);

      const authProgram = await staffAppointmentService.authProgramCompensar(documento, idxTipoDocumento);

      //Se consume el servicio para generar las autorizaciones
      const parseToDob = params.row.FECHA_NCTO.substring(0, 10);
      const dob = parseToDob.replaceAll('-', '')
      const cups = "890301"
      const phone = params.row.CELULAR.replace(/\s+/g, '')
      const idMedico = params.row.IDENTIF_MEDICO.replace(/\s+/g, '')
      const fechaCita = format(parseISO(params.row.FECHA_CITA),'yyyy-MM-dd').replaceAll('-', '')
      const horaCita = params.row.HORA_CITA.substring(0, 5).replaceAll(':', '');
      const appointment = fechaCita + horaCita

      const authorization = await staffAppointmentService.numberAuthorizationCompensar(dob, idxTipoDocumento, documento, cups, phone, idMedico, authProgram, appointment);
      console.log("Número de autorización generado:", authorization)

      //Se consume el servicio para cambiar el estado de la cita
      if (authorization !== false){
        const appointment_message = await staffAppointmentService.AppointmentMessageCompensar(documento, idxTipoDocumento, authorization, authProgram);
        console.log("Mensaje estado de cita:", appointment_message)
        alert("Número de autorización generado: " + authorization + ", Estado de la cita: " + appointment_message)  
      }            
    }
  }  

  const getAllAuthorizations = async () => {
    //Get data
    setRowsLoading(true);

    var list = [];
    var count = 0;

    const resp = await staffAppointmentService.authCompensarList();
    count =  Object.keys(resp).length;
    if (count > 0) {    
      for (let j = 0; j < count; j++) {     
        list.push(resp[j]);
      }     
    }

    const rowsToRender = list.map((row, index) => {
        return {
            ...row,
            id: index,
            nombreCompleto: row.NOMBRESUSUARIO + row.APELLIDOSUSUARIO
        }
    })

    setRows(rowsToRender);
    setRowsLoading(false);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        rows={rows}
        loading={rowsLoading}
        columns={columnsIncapacities}
        pageSize={5}
        onRowClick={(params) => {
          handleRowSelected(params);//if (params.row.Estado === 'ATENDIDA')
        }}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(StaffAuthorizations);