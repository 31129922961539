import React from 'react';

// Material UI Components
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Carousel from 'react-material-ui-carousel'
//Custom components
import ClinicCard from '../components/ClinicCard'
// Clinics Images
import estanciaPopImg from '../assets/static/clinics/estanciapopayan.jpg';
import nuestraIbagImg from '../assets/static/clinics/nuestraibague.jpg';
import nuestraCartImg from '../assets/static/clinics/nuestracartagena.jpg';
import rosalesPereImg from '../assets/static/clinics/rosalespereira.jpg';
import corazonMedImg from '../assets/static/clinics/corazonmedellin.jpg';
import antioquiaItagImg from '../assets/static/clinics/antioquiaitagui.jpg';
import antioquiaBellImg from '../assets/static/clinics/antioquiabello.jpg';
import versallesManiImg from '../assets/static/clinics/versallesmanizales.jpg';
import mercedBarrImg from '../assets/static/clinics/mercedbarranquilla.jpg';
import centralqArmeImg from '../assets/static/clinics/ccqarmenia.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    control: {
      padding: theme.spacing(2),
    },
}));


const AllClinics = () => {
    const [spacing, setSpacing] = React.useState(2);
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item sm={12}>
                <Grid container justify="center" spacing={spacing}>
                    <Carousel animation="slide">
                        {clinics.map((clinic, index) => (
                            <ClinicCard key={index} {...clinic} />
                        ))}
                    </Carousel>
                </Grid>
            </Grid>
        </Grid>
    );
}

const clinics = [
    {name: "Clínica la Estancia", city: "Popayan Cauca", image: estanciaPopImg},
    {name: "Clínica Nuestra", city: "Cali Valle", image: nuestraIbagImg},
    {name: "Clínica Nuestra", city: "Ibagué Tolima", image: nuestraIbagImg},
    {name: "Clínica Nuestra", city: "Cartagena Bolivar", image: nuestraCartImg},
    {name: "Clínica Los Rosales", city: "Pereira Risaralda", image: rosalesPereImg},
    {name: "Clínica Sagrado corazon", city: "Medellin Antioquia", image: corazonMedImg},
    {name: "Clínica Antioquia", city: "Itagui Antioquia", image: antioquiaItagImg},
    {name: "Clínica Antioquia", city: "Bello Antioquia", image: antioquiaBellImg},
    {name: "Clínica Versalles", city: "Manizales Caldas", image: versallesManiImg},
    {name: "Clínica La Merced", city: "Barranquilla Atlantico", image: mercedBarrImg},
    {name: "Clínica Central del Quindio", city: "Armenia Quindio", image: centralqArmeImg},
];

export default AllClinics;