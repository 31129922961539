import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
// redux
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';


// Actions
import { loginRequest, setPatient } from '../actions';

// services
import { authenticationService } from '../services/authenticationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  circle: {
    size: 60,
  },
}));

const FormEmailToResetPassword = (props) => {

  const classes = useStyles();
  const [form, setValues] = useState(props.form);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordAgainValidator, setPasswordAgainValidator] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(false);
  let { usernameToReset } = useParams();

 
  useEffect(async () => {
  }, [])


  const handleInput = event => {
    if (event.target.name === 'password' && event.target.value.length < 4) {
      setPasswordValidator(true);
    } else {
      if(event.target.name === 'password') {        
      setPasswordValidator(false);
      }
    }

    if (event.target.name === 'password_again' && event.target.value !== form.password) {
      setPasswordAgainValidator(true);
    } else {
      if(event.target.name === 'password_again' ){
        setPasswordAgainValidator(false);
      }
      
    }

    setValues({
      ...form,
      [event.target.name]: event.target.value
    })
  }


  const handleSubmit = async () => {
    setLoading(true);
    if(form.password.length>=4 && form.password === form.password_again){
      try{
        const resp = await authenticationService.resetUserPassword(usernameToReset, form.password);
        if(resp.success){
          alert('Contraseña actualizada correctamente');
  
        }else{
          alert('El usuario no existe');
        }
        console.log('Valor de resp ', resp);
  
       }catch(e){
         console.log('excepción de tipo ', e)
  
       }
    }else{
      alert('Los campos deben cumplir los requisitos')
    }

     
      

    setLoading(false);


  }

  return (
    <form className={classes.form} noValidate onSubmit={props.handleEmail}>

      <TextField
        error={props.emailValidator}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="text"
        id="email"
        label="Escriba su correo electrónico"
        name="email"
        helperText={props.emailValidator && "Debe ingresar un correo válido*"}
        autoFocus
        onChange={props.handleInputEmail}
      />

    </form>
  );
}
const mapStateToProps = (state) => ({
  clinic: state.clinic
})

const mapDispatchToProps = {
  loginRequest,
  setPatient
};

export default connect(mapStateToProps, mapDispatchToProps)(FormEmailToResetPassword);