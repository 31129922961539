import React, { useState, useEffect } from "react";
// redux
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Image from "material-ui-image";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { secondaryListItems } from "./listItems";
import Copyright from "../../components/Copyright";
import CitappModal from "./CitappModal";
//import UpdateContractAppointmentsToBeAuthorizedModal from './UpdateContractAppointmentsToBeAuthorizedModal';
import UpdateContractPriorityInfoModal from "./UpdateContractPriorityInfoModal";
import IncapacitiesAcceptModal from "./IncapacitiesAcceptModal";
import MedicalOrdersAttendModal from "./MedicalOrdersAttendModal";
import PresurgeryAcceptModal from "./PresurgeryAcceptModal";
import AppointmentsModal from "./AppointmentsModal";
import CancelAppoinmentsModal from "./CancelAppoinmentsModal";
import TransferFileAppointmentsModal from "./TransferFileAppointmentsModal";
import StaffAdminIncapacitiesTable from "./StaffAdminIncapacitiesTable";
import StaffMedicalOrders from "./StaffMedicalOrders";
import StaffWaitingList from "./StaffWaitingList";
import StaffAuthorizations from "./StaffAuthorizations";
import StaffExceptions from "./StaffExceptions";
import StaffReminders from "./StaffReminders";
import StaffCrons from "./StaffCrons";
import PresurgeryProcedureTable from "./PresurgeryProcedureTable";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
// My icons
import ScheduleIcon from "@material-ui/icons/Schedule";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CreateIcon from "@material-ui/icons/Create";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ComputerIcon from "@material-ui/icons/Computer";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccessAlarmOutlinedIcon from "@material-ui/icons/AccessAlarmOutlined";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import DescriptionIcon from "@material-ui/icons/Description";
import CardInfo from "../../components/CardInfo";
import AppointmentsTobeAuthorizedTable from "./AppointmentsTobeAuthorizedTable";
import PriorityAppointmentTable from "./PriorityAppointmentTable";
import BackdropLoading from "../../components/BackdropLoading";
import SendIncapacityToEmployerModal from "../dashboard/SendIncapacityToEmployerModal";
import useBrowserContextCommunication from "react-window-communication-hook";
import { BsWhatsapp } from "react-icons/bs";
// services
import { authenticationService } from "../../services/authenticationService";
import { staffAppointmentService } from "../../services/staff/staffAppointmentService";
import { appointmentService } from "../../services/appointmentService";
//
import headerLogo from "../../assets/static/headerLogo.png";
import botonLogo from "../../assets/static/botonLogo.png";
import mainStaffDashboard from "../../assets/static/mainStaffDashboard.svg";
//actions
import { setLastContrat } from "../../actions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function DashboardStaff(props) {
  const incapacitiesRows = [
    {
      id: 0,
      inicio: "13/05/2021",
      final: "13/06/2021",
      Dias: "31",
      MedNom: "JOSE RAUL RODRIGUEZ REYES",
      TipoInc: "Laboral",
      CausExt: "Ortopedia",
      Diagn: "Fractura completa del hueso peroné",
      status: "Tramite Interno",
    },
    {
      id: 2,
      inicio: "09/02/2020",
      final: "08/03/2020",
      Dias: "30",
      MedNom: "LUIS ALBERTO SPINETTA",
      TipoInc: "Laboral",
      CausExt: "Ortopedia",
      Diagn: "Fisura sección del hueso peroné",
      status: "Tramite Interno",
    },
    {
      id: 3,
      inicio: "13/05/2021",
      final: "10/06/2021",
      Dias: "28",
      MedNom: "MARÍA CRISTINA ENCISO",
      TipoInc: "Laboral",
      CausExt: "Ortopedia",
      Diagn: "Inflamación al nivel del hueso peroné",
      status: "Tramite Interno",
    },
    {
      id: 4,
      inicio: "01/01/2019",
      final: "10/01/2019",
      Dias: "10",
      MedNom: "CARLOS CORONEL PUMAREJO",
      TipoInc: "Laboral",
      CausExt: "Ortopedia",
      Diagn: "Sintomas ligeros del hueso peroné",
      status: "Tramite Interno",
    },
  ];
  const presurgeryRows = [
    {
      id: 0,
      Fch_Inicia_Incap: "13/05/2021",
      Fch_Fin_Incap: "13/06/2021",
      Dias_Incap: "31",
      Nombre_Medico: "JOSE RAUL RODRIGUEZ REYES",
      Tipo_de_Incapacidad: "Laboral",
      Causa_Externa: "Ortopedia",
      Nombre_Diag: "Fractura completa del hueso peroné",
      estado: "Tramite Interno",
    },
    {
      id: 2,
      Fch_Inicia_Incap: "09/02/2020",
      Fch_Fin_Incap: "08/03/2020",
      Dias_Incap: "30",
      Nombre_Medico: "LUIS ALBERTO SPINETTA",
      Tipo_de_Incapacidad: "Laboral",
      Causa_Externa: "Ortopedia",
      Nombre_Diag: "Fisura sección del hueso peroné",
      estado: "Tramite Interno",
    },
    {
      id: 3,
      Fch_Inicia_Incap: "13/05/2021",
      Fch_Fin_Incap: "10/06/2021",
      Dias_Incap: "28",
      Nombre_Medico: "MARÍA CRISTINA ENCISO",
      Tipo_de_Incapacidad: "Laboral",
      Causa_Externa: "Ortopedia",
      Nombre_Diag: "Inflamación al nivel del hueso peroné",
      estado: "Tramite Interno",
    },
    {
      id: 4,
      Fch_Inicia_Incap: "01/01/2019",
      Fch_Fin_Incap: "10/01/2019",
      Dias_Incap: "10",
      Nombre_Medico: "CARLOS CORONEL PUMAREJO",
      Tipo_de_Incapacidad: "Laboral",
      Causa_Externa: "Ortopedia",
      Nombre_Diag: "Sintomas ligeros del hueso peroné",
      estado: "Tramite Interno",
    },
  ];

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [openCitappModal, setOpenCitappModal] = useState(false);
  const [
    openUpdateInfoPriorityAppointmentsModal,
    setOpenUpdateInfoPriorityAppointmetsModal,
  ] = useState(false);
  const [
    openUpdateInfoAppointmentsToBeAuthorizedModal,
    setOpenUpdateInfoAppointmentsToBeAuthorizedModal,
  ] = useState(false);
  const [
    openSendIncapacityToEmployerModal,
    setOpenSendIncapacityToEmployerModal,
  ] = useState(false);
  const [openIncapacitiesAcceptModal, setOpenIncapacitiesAcceptModal] =
    useState(false);
  const [openMedicalOrdersAttendModal, setOpenMedicalOrdersAttendModal] =
    useState(false);
  const [openPresurgeryAcceptModal, setOpenPresurgeryAcceptModal] =
    useState(false);
  const [paramsRowIncapacity, setParamsRowIncapacity] = useState(false);
  const [paramsRowMedicalOrders, setParamsRowMedicalOrders] = useState(false);
  const [paramsRowPresurgery, setParamsRowPresurgery] = useState(false);
  const [openAppointmentsModal, setOpenAppointmentsModal] = useState(false);
  const [appointmentsToShow, setAppointmentsToShow] = useState("authorized");
  const [pageTitle, setPageTitle] = useState("Grupo Ospedale");
  const [totalLastAppoint, setTotalLastAppoint] = useState(0);
  const [totalNextAppoint, setTotalNextAppoint] = useState(0);
  const [totalControlAppoint, setTotalControlAppoint] = useState(0);
  const [urlCitapp, setUrlCitapp] = useState(
    "https://portubien.com.co/citapp_user_desa_0/"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastContrat, setLastContrat] = useState("");
  const [urlCancelAppoinmentsModal, setUrlCancelAppoinmentsModal] =
    useState("");
  const [openCancelAppoinmentsModal, setOpenCancelAppoinmentsModal] =
    useState(false);
  const [
    urlTransferFileAppointmentsModal,
    setUrlTransferFileAppointmentsModal,
  ] = useState("");
  const [
    openTransferFileAppointmentsModal,
    setOpenTransferFileAppointmentsModal,
  ] = useState(false);
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState("Pendientes de autorización");
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [priorityLoading, setPriorityLoading] = useState(false);
  const [communicationState, postMessage] = useBrowserContextCommunication(
    "DashboardStaffChanel"
  );
  const [userSelected, setUserSelected] = useState({});
  const [allEps, setAllEps] = useState([]);
  const [allRegimen, setAllRegimen] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [eps, setEps] = useState("");
  const [regimen, setRegimen] = useState("");
  const [level, setLevel] = useState("");
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [allLastContractInfo, setAllLastContractInfo] = useState({});
  const [citaNumSelected, setCitaNumSelected] = useState("");
  const [priorityPetitionApointmentId, setPriorityPetitionApointmentId] =
    useState(null);
  const [datesAndSpecialistInformation, setDatesAndSpecialistInformation] =
    useState(null);
  const [selectedValueRowTable, setSelectedValueRowTable] = useState(null);
  const [infoSanitas, setInfoSanitas] = useState();
  const [isSanitas, setIsSanitas] = useState(false);
  const [infoSaludTotal, setInfoSaludTotal] = useState();
  const [isSaludTotal, setIsSaludTotal] = useState(false);
  const [infoCompensar, setInfoCompensar] = useState();
  const [isCompensar, setIsCompensar] = useState(false);
  const [mostarEstado, setMostarEstado] = useState("");
  const [closeImageWaitingList, setCloseImageWaitingList] = useState(false);
  const [closeImageExceptions, setCloseImageExceptions] = useState(false);
  const [closeImageAuth, setCloseImageAuth] = useState(false);

  let history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleIncapacityClick = () => {
    setPageTitle("Radicar Incapacidades");
    setAppointmentsToShow("adminIncapacities");
  };

  const handleGestionarOrdenesClick = () => {
    setPageTitle("Gestionar Órdenes");
    setAppointmentsToShow("adminMedicalOrders");
  };

  const handleWaitingListClick = () => {
    setPageTitle("Lista de Espera");
    setAppointmentsToShow("adminWaitingList");
    setCloseImageWaitingList(true);
  };

  const handleAuthorizationsClick = () => {
    setPageTitle("Autorizaciones");
    setAppointmentsToShow("adminAuthorizations");
  }

  const handleExceptionsClick = () => {
    setPageTitle("Excepciones");
    setAppointmentsToShow("adminExceptions");
    setCloseImageExceptions(true);
  }

  const handleRemindersClick = () => {
    setPageTitle("Reminders");
    setAppointmentsToShow("adminReminders");
  }

  const handleCronsClick = () => {
    setPageTitle("Crons");
    setAppointmentsToShow("adminCrons");
    setCloseImageExceptions(true);
  }

  const handlePresurgeryClick = () => {
    setPageTitle("Radicar procedimientos prequirurgicos");
    setAppointmentsToShow("PresurgeryProcedure");
  };

  const handleOpenCloseCitappModal = (url) => {
    setUrlCitapp(url);
    setOpenCitappModal(!openCitappModal);
    //history.go(0);
  };

  const handleOpenCloseCancelAppoinmentsModal = (url) => {
    setUrlCancelAppoinmentsModal(url);
    setOpenCancelAppoinmentsModal(!openCancelAppoinmentsModal);
  };
  const handleOpenCloseTransferFileAppointmentsModal = (url) => {
    setUrlTransferFileAppointmentsModal(url);
    setOpenTransferFileAppointmentsModal(!openTransferFileAppointmentsModal);
  };

  const handleEPSChange = async (contract) => {
    /* Get All regiments for clinic id and number contract */
    const urlRegimens =
      process.env.REACT_APP_URL_HOST_AMS +
      `/api/getListRegimenes/${props.clinic.cli_id}/${contract}`;
    const respAllRegimens = await getData(urlRegimens);
    setAllRegimen(respAllRegimens.data);
  };

  const getLastContractInfo = async (ceroTramUser, row) => {
    /* Get All apss for clinic id */
    const urlContracts =
      process.env.REACT_APP_URL_HOST_AMS +
      `/api/getListContratos/${props.clinic.cli_id}`;
    const respAllEps = await getData(urlContracts);

    setAllEps(respAllEps.data);

    //const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getUltimoContratoPaciente/${props.clinic.cli_id}/${ceroTramUser.card_id}/${ceroTramUser.card_id_type}`;
    const urlLastContrat =
      process.env.REACT_APP_URL_HOST_AMS +
      `/api/getContratoPacienteSelect/${props.clinic.cli_id}/${ceroTramUser.card_id}/${ceroTramUser.card_id_type}/${row.cita_num}`;
    console.log("api de contrato ", urlLastContrat);
    console.log("datos guillermo ", rows); //rows

    const numero_cita = row.cita_num;
    const tipo_documento = row.cerotram_user.card_id_type;
    const documento = row.cerotram_user.card_id;
    const clinica_id = row.info_dataRic.clinica_id;
    const cerotram_user_id = row.cerotram_user.id;
    //Validar si la cita ya fue cancelada
    const Estado = await appointmentService.EstadoCerotram(
      numero_cita,
      tipo_documento,
      documento,
      clinica_id,
      cerotram_user_id
    );
    setMostarEstado(Estado?.estado);
    console.log(" ESTADO CERO TRAM", Estado?.estado);

    //actualiza la lista de Pendientes de autorización
    getAllAppointments();

    const lastContratData = await getData(urlLastContrat);
    if (lastContratData.data) {
      setEps(lastContratData.data[0].contrato);
      await handleEPSChange(lastContratData.data[0].contrato);
      setRegimen(lastContratData.data[0].regimen);
      const urlLevels =
        process.env.REACT_APP_URL_HOST_AMS +
        `/api/getListNivelesUsuario/${props.clinic.cli_id}/${lastContratData.data[0].contrato}/${lastContratData.data[0].regimen}`;
      const respAllLevels = await getData(urlLevels);
      setAllLevels(respAllLevels.data);
      setLevel(lastContratData.data[0].nivel_usuario);
    }
  };

  const handleOpenCloseSendIncapacityToEmployerModal = async (paramsRow) => {
    if (!openSendIncapacityToEmployerModal && paramsRow) {
      setParamsRowIncapacity(paramsRow);
      //setBackdropLoading(true);
      //await getLastContractInfo(ceroTramUser);
      setOpenSendIncapacityToEmployerModal(!openSendIncapacityToEmployerModal);
      //setBackdropLoading(false);
    } else {
      setOpenSendIncapacityToEmployerModal(!openSendIncapacityToEmployerModal);
    }
  };

  const handleOpenCloseIncapacityAcceptModal = async (paramsRow) => {
    if (!openIncapacitiesAcceptModal && paramsRow) {
      setParamsRowIncapacity(paramsRow);
      setBackdropLoading(true);
      //await getLastContractInfo(ceroTramUser);
      setOpenIncapacitiesAcceptModal(!openIncapacitiesAcceptModal);
      setBackdropLoading(false);
    } else {
      setOpenIncapacitiesAcceptModal(!openIncapacitiesAcceptModal);
    }
  };

  const handleOpenCloseMedicalOrdersModal = async (paramsRow) => {
    if (!openMedicalOrdersAttendModal && paramsRow) {
      setParamsRowMedicalOrders(paramsRow);
      //setBackdropLoading(true);
      //await getLastContractInfo(ceroTramUser);
      setOpenMedicalOrdersAttendModal(!openMedicalOrdersAttendModal);
      //setBackdropLoading(false);
    } else {
      setOpenMedicalOrdersAttendModal(!openMedicalOrdersAttendModal);
    }
  };

  const handleOpenClosePresurgeryAcceptModal = async (paramsRow) => {
    if (!openPresurgeryAcceptModal && paramsRow) {
      setParamsRowPresurgery(paramsRow);
      setBackdropLoading(true);
      //await getLastContractInfo(ceroTramUser);
      setOpenPresurgeryAcceptModal(!openPresurgeryAcceptModal);
      setBackdropLoading(false);
    } else {
      setOpenPresurgeryAcceptModal(!openPresurgeryAcceptModal);
    }
  };

  const handleOpenCloseUpdateInfoModal = async (
    ceroTramUser,
    valueRowTable
  ) => {
    if (!openUpdateInfoPriorityAppointmentsModal && ceroTramUser) {
      setBackdropLoading(true);
       
      await getLastContractInfo(ceroTramUser, valueRowTable.row);

      setBackdropLoading(false);
      setSelectedValueRowTable(valueRowTable);
      setOpenUpdateInfoPriorityAppointmetsModal(
        !openUpdateInfoPriorityAppointmentsModal
      );
    } else {
      setOpenUpdateInfoPriorityAppointmetsModal(
        !openUpdateInfoPriorityAppointmentsModal
      );
    }
  };

  const handleOpenCloseAppointmentsModal = () => {
    setOpenAppointmentsModal(!openAppointmentsModal);
  };

  const handleAppointmentsPriorityClick = () => {
    setPageTitle("Solicitud de citas");
    setAppointmentsToShow("priority");
  };

  const handleAppointmentsAuthorizedClick = () => {
    setPageTitle("Autorización de citas");
    setAppointmentsToShow("authorized");
  };

  const handleHomeClick = () => {
    setPageTitle("Página Principal");
    setAppointmentsToShow("authorized");
  };

  const getInfoSanitas = async (user) => {
    //setAppointmentLoading(true);

    try {
      const respSanitas = await staffAppointmentService.sanitasEndpoint(
        user.card_id_type,
        user.card_id
      );
      setInfoSanitas(respSanitas);
      if (respSanitas?.status_user) {
        // setIsSanitas(true)
        /* if (infoSanitas?.status_user === "VIGENTE") {
          console.log("Vigecia de sanitas ", infoSanitas?.status_user)
          setEstado('AUTORIZADO')
        } else {
          console.log("Vigecia de sanitas ", infoSanitas?.status_user)
        } */
      }
    } catch {
      setIsSanitas(false);
    }
  };

  const getAuthCompensar = async (user) => {
    setAppointmentLoading(true);
    try{
      const respGetAuthCompensar = await staffAppointmentService.authCompensarEndpoint(        
      );      
    } catch {
      console.log("error getAuthCompensar")
    }
  }
  
  const getAppointmentTable = (appointmentsToShow) => {
    switch (appointmentsToShow) {
      case "authorized":
        return (
          <AppointmentsTobeAuthorizedTable
            handleOpenCloseCancelAppoinmentsModal={
              handleOpenCloseCancelAppoinmentsModal
            }
            handleOpenCloseUpdateInfoModal={handleOpenCloseUpdateInfoModal}
            handleOpenCloseTransferFileAppointmentsModal={
              handleOpenCloseTransferFileAppointmentsModal
            }
            clinic={props.clinic}
            clinicsMaping={props.clinicsMaping}
            user={props.user}
            rows={rows}
            title={title}
            appointmentLoading={appointmentLoading}
            setUserSelected={setUserSelected}
            setBackdropLoading={setBackdropLoading}
            handleSwitchChange={handleSwitchChange}
            setCitaNumSelected={setCitaNumSelected}
          />
        );
      case "priority":
        return (
          <PriorityAppointmentTable
            handleOpenCloseCitappModal={handleOpenCloseCitappModal}
            priorityLoading={priorityLoading}
            setPriorityLoading={setPriorityLoading}
            user={props.user}
            clinic={props.clinic}
            handleOpenCloseUpdateInfoModal={handleOpenCloseUpdateInfoModal}
            setUserSelected={setUserSelected}
            setBackdropLoading={setBackdropLoading}
            setCitaNumSelected={setCitaNumSelected}
            setPriorityPetitionApointmentId={setPriorityPetitionApointmentId}
            setDatesAndSpecialistInformation={setDatesAndSpecialistInformation}
          />
        );
      case "adminIncapacities":
        return (
          <StaffAdminIncapacitiesTable
            rows={incapacitiesRows}
            //handleOpenCloseIncapacityAcceptModal={handleOpenCloseIncapacityAcceptModal}
            handleOpenCloseIncapacityAcceptModal={
              handleOpenCloseSendIncapacityToEmployerModal
            }
          />
        );
      case "adminMedicalOrders":
        return (
          <StaffMedicalOrders
            handleOpenCloseMedicalOrdersModal={
              handleOpenCloseMedicalOrdersModal
            }
          />
        );
      case "adminWaitingList":
        return (
          <StaffWaitingList
          />
        );
      case "adminAuthorizations":
        return (
          <StaffAuthorizations
          />
        );
      case "adminExceptions":
        return (
          <StaffExceptions
          />
        );
      case "adminReminders":
        return (
          <StaffReminders
          />
        );
      case "adminCrons":
        return (
          <StaffCrons
          />
        );
      case "PresurgeryProcedure":
        return (
          <PresurgeryProcedureTable
            rows={presurgeryRows}
            handleOpenClosePresurgeryAcceptModal={
              handleOpenClosePresurgeryAcceptModal
            }
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const handleExit = () => {
    authenticationService.logout();
    history.push("/");
  };

  const handleOpenAppointMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAppointMenu = () => {
    setAnchorEl(null);
  };

  const getData = async (url) => {
    const requestOptions = {
      method: "GET",
    };
    const resp = await fetch(url, requestOptions).then((resp) => resp.json());
    return resp;
  };

  const handleSelectAppointMenu = async (AppointTypeSelected) => {
    // Parse url
    const baseUrl = "https://portubien.com.co/citapp_user_desa_0";
    const url =
      baseUrl +
      (AppointTypeSelected === "teleorientacion"
        ? "/login/view_index1"
        : "/login/view_index2");
    //maping clinic id
    const clinicId = props.clinicsMaping[props.clinic.cli_id];
    // Build urlCitapp
    const urlCitapp =
      url +
      `/${clinicId}/${props.user.card_id_type}/${props.user.card_id}/${lastContrat}/0_tram/desarrollo` +
      process.env.REACT_APP_FLAG_PRODUCTION_DEVELOP;
    // Open Modal
    handleOpenCloseCitappModal(urlCitapp);
    //Close menu
    handleCloseAppointMenu();
  };

  const handleSwitchChange = (selected) => {
    if (selected === "authorized") {
      setTitle("Pendientes de autorización");
      // set new rows data
      //setRows(rowser);
      getAllAppointments();
    } else {
      setTitle("Histórico de citas autorizadas");
      // set new rows data
      getAllHistorycAppointments();
      //setRows(priorityAppoint);
    }
  };

  const getAllAppointments = async () => {
    setAppointmentLoading(true);
    var list = [];
    var count = 0;
    
    const allAppointments = await staffAppointmentService.getAllAppointments(props.clinic.cli_id);
    count =  Object.keys(allAppointments.data).length;
    if (count > 0) {    
        for (let j = 0; j < count; j++) {     
            list.push(allAppointments.data[j]);
        }     
    }

    const rowsToRender = list.map((row, index) => {
      return {
          ...row,
          especialidad: row.info_dataRic.especialidad,
      }
    })
    
    console.log("all appointments", allAppointments.data);
    setRows(rowsToRender);
    setAppointmentLoading(false);
  };

  const getAllHistorycAppointments = async () => {
    setAppointmentLoading(true);
    const allHistorycAppointments =
      await staffAppointmentService.getAllHistorycAppointments(
        props.clinic.cli_id
      );
    setRows(allHistorycAppointments.data);
    setAppointmentLoading(false);
  };

  useEffect(async () => {
    getAllAppointments();
    // const pat = props.patients ? props.patients[0] : null;
    // if (!pat) {
    //   //setOpenUpdateInfoPriorityAppointmetsModal(true);
    // } else {
    //   // get last contrat
    //   const urlLastContrat =
    //     process.env.REACT_APP_URL_HOST_AMS +
    //     `/api/getUltimoContratoPaciente/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`;
    //   //const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getContratoPacienteSelect/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`;
    //   const lastContratData = await getData(urlLastContrat);
    //   const contrat = lastContratData.data
    //     ? lastContratData.data[0].contrato
    //     : "";
    //   setLastContrat(contrat);
    //   props.setLastContrat(contrat);
    //   setAllLastContractInfo(
    //     lastContratData.data[0] ? lastContratData.data[0] : null
    //   );
    //   getAllAppointments();
    // }
  }, [eps]);

  useEffect(() => {
    if (communicationState.lastMessage) {
      const { createNewAppointment, AppointmentStatusChanged } =
        communicationState.lastMessage;
      if (createNewAppointment || AppointmentStatusChanged) {
        getAllAppointments();
      }
    }
  }, [communicationState]);

  // useEffect(() => {
  //   setInterval(() => {
  //     getAllAppointments();
  //   }, 60000);
  // }, []);

  const currentUser = authenticationService.currentUserValue;
  if (!currentUser) {
    // not logged in so redirect to login page with the return url
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          {!open && <img src={botonLogo} alt="Logo1" width="60px"></img>}
          <Typography
            component="h1"
            variant="h5"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {!open ? "Grupo Ospedale" : "Staff dashboard"}
          </Typography>
          <IconButton color="inherit" onClick={() => handleExit()}>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={headerLogo} alt="Logo2" width="75%"></img>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            selected={
              pageTitle === "Grupo Ospedale" || pageTitle === "Página Principal"
                ? true
                : false
            }
            button
            onClick={handleHomeClick}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Página Principal" />
          </ListItem>
          <ListItem
            button
            onClick={handleAppointmentsPriorityClick}
            selected={pageTitle === "Solicitud de citas" ? true : false}
          >
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary="Solicitud de Citas" />
          </ListItem>
          <ListItem
            button
            onClick={handleAppointmentsAuthorizedClick}
            selected={pageTitle === "Citas por Autorizar" ? true : false}
          >
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Citas por Autorizar" />
          </ListItem>

          <ListItem
            button
            onClick={handleIncapacityClick}
            selected={pageTitle === "Radicar Incapacidades" ? true : false}
          >
            <ListItemIcon>
              <LocalHospitalIcon />
            </ListItemIcon>
            <ListItemText primary="Radicar Incapacidad" />
          </ListItem>

          <ListItem
            button
            onClick={handleGestionarOrdenesClick}
            selected={pageTitle === "Gestionar Órdenes" ? true : false}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Gestionar Órdenes" />
          </ListItem>

          <ListItem
            button
            onClick={handleWaitingListClick}
            selected={pageTitle === "Lista de Espera" ? true : false}
          >
            <ListItemIcon>
              <ComputerIcon />
            </ListItemIcon>
            <ListItemText primary="Lista de Espera" />
          </ListItem>

          <ListItem
            button
            onClick={handleAuthorizationsClick}
            selected={pageTitle === "Autorizaciones" ? true : false}
          >
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary="Autorizaciones" />
          </ListItem>

          <ListItem
            button
            onClick={handleExceptionsClick}
            selected={pageTitle === "Excepciones" ? true : false}
          >
            <ListItemIcon>
              <EventNoteIcon />
            </ListItemIcon>
            <ListItemText primary="Excepciones" />
          </ListItem> 

          <ListItem
            button
            onClick={handleRemindersClick}
            selected={pageTitle === "Recordatorios" ? true : false}
          >
            <ListItemIcon>
              <AccessAlarmOutlinedIcon/>
            </ListItemIcon>
            <ListItemText primary="Recordatorios" />
          </ListItem> 

          <ListItem
            button
            onClick={handleCronsClick}
            selected={pageTitle === "Crons" ? true : false}
          >
            <ListItemIcon>
              <EventNoteIcon />
            </ListItemIcon>
            <ListItemText primary="Crons" />
          </ListItem>

        </List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {getAppointmentTable(appointmentsToShow)}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
          <Copyright />
          </Box>
        </Container>
      </main>
      <CitappModal
        open={openCitappModal}
        handleOpenCloseCitappModal={handleOpenCloseCitappModal}
        urlCitapp={urlCitapp}
      />
      <UpdateContractPriorityInfoModal
        open={openUpdateInfoPriorityAppointmentsModal}
        selectedValueRowTable={selectedValueRowTable}
        handleOpenCloseUpdateInfoModal={handleOpenCloseUpdateInfoModal}
        handleOpenCloseCancelAppoinmentsModal={
          handleOpenCloseCancelAppoinmentsModal
        }
        disableBackdropClick={props.patients ? false : true}
        havePatient={props.patients ? true : false}
        clinic={props.clinic}
        user={userSelected}
        allEps={allEps}
        allRegimen={allRegimen}
        allLevels={allLevels}
        eps={eps}
        mostarEstado={mostarEstado}
        regimen={regimen}
        level={level}
        allLastContractInfo={allLastContractInfo}
        citaNumSelected={citaNumSelected}
        priorityPetitionApointmentId={priorityPetitionApointmentId}
        datesAndSpecialistInformation={datesAndSpecialistInformation}
      />
      {/* <UpdateContractAppointmentsToBeAuthorizedModal
        //open={openUpdateInfoPriorityAppointmentsModal}
        disableBackdropClick={props.patients ? false : true}
        havePatient={props.patients ? true : false}
        clinic={props.clinic}
        user={userSelected}
        allEps={allEps}
        allRegimen={allRegimen}
        allLevels={allLevels}
        eps={eps}
        regimen={regimen}
        level={level}
        allLastContractInfo={allLastContractInfo}
        citaNumSelected = {citaNumSelected}
        priorityPetitionApointmentId = {priorityPetitionApointmentId}
        datesAndSpecialistInformation = {datesAndSpecialistInformation}
      /> */}
      <SendIncapacityToEmployerModal
        open={openSendIncapacityToEmployerModal}
        handleOpenCloseSendIncapacityToEmployerModal={
          handleOpenCloseSendIncapacityToEmployerModal
        }
        paramsRowIncapacity={paramsRowIncapacity}
      />

      {/* <IncapacitiesAcceptModal
        open={openIncapacitiesAcceptModal}
        handleOpenCloseIncapacityAcceptModal={handleOpenCloseIncapacityAcceptModal}
        paramsRowIncapacity={paramsRowIncapacity}
      /> */}

      <MedicalOrdersAttendModal
        open={openMedicalOrdersAttendModal}
        handleOpenCloseMedicalOrdersModal={handleOpenCloseMedicalOrdersModal}
        paramsRowMedicalOrders={paramsRowMedicalOrders}
        handleOpenCloseCitappModal={handleOpenCloseCitappModal}
      />
      <PresurgeryAcceptModal
        open={openPresurgeryAcceptModal}
        handleOpenClosePresurgeryAcceptModal={
          handleOpenClosePresurgeryAcceptModal
        }
        paramsRowPresurgery={paramsRowPresurgery}
      />
      <AppointmentsModal
        open={openAppointmentsModal}
        handleOpenCloseAppointmentsModal={handleOpenCloseAppointmentsModal}
      />
      <CancelAppoinmentsModal
        open={openCancelAppoinmentsModal}
        handleOpenCloseCancelAppoinmentsModal={
          handleOpenCloseCancelAppoinmentsModal
        }
        urlCancelAppoinmentsModal={urlCancelAppoinmentsModal}
      />
      <TransferFileAppointmentsModal
        open={openTransferFileAppointmentsModal}
        handleOpenCloseTransferFileAppointmentsModal={
          handleOpenCloseTransferFileAppointmentsModal
        }
        urlTransferFileAppointmentsModal={urlTransferFileAppointmentsModal}
      />
      <BackdropLoading open={backdropLoading} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  patients: state.patients,
  clinic: state.clinic,
  clinicsMaping: state.clinicsMaping,
  testCreateAppointment: state.testCreateAppointment,
});

const mapDispatchToProps = {
  setLastContrat,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStaff);
