import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Typography,
} from '@material-ui/core';
import ShowAlert from '../../components/ShowAlert';
import { connect } from 'react-redux';
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
import renderCellExpand from '../../components/renderCellExpand';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/CloudUpload';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { MenuItem, FormHelperText, FormControl, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, InputLabel } from '@material-ui/core';
import { clinicsM } from '../../services/clinicsM';

const StaffExceptions = (props) => {
    const [rowsLoading, setRowsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowsEspecialidades, setRowsEspecialidades] = useState([]);
    const [rowsEspecialistas, setRowsEspecialistas] = useState([]);
    const [condicional, setCondicional] = useState('condicionales');
    const [isCondicional, setIsCondicional] = useState(true);
    const [isCondicionalEspecialidades, setIsCondicionalEspecialidades] = useState(false);
    const [isCondicionalEspecialistas, setIsCondicionalEspecialistas] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [epsHomologadas, setEpsHomologadas] = useState([]);
    const [open, setOpen] = React.useState(false);    
    const valueRefStepCondicional = useRef('');
    const valueRefCampoVerificar = useRef('');
    const valueRefEspecialidadValidar = useRef('');
    const valueRefMensajeCondicion = useRef('');
    const valueRefEspecialistaValidar = useRef('');
    const valueRefEspecialidadValidarEspecialistas = useRef('');
    const valueRefMensajeCondicionEspecialistas = useRef('');
    const [selectedValue, setSelectedValue] = React.useState('');
    const [valueRefVisualizarBot, setValueRefVisualizarBot] = React.useState(false);
    const [valueRefVisualizarBotEspecialistas, setValueRefVisualizarBotEspecialistas] = React.useState(false);    
    const [valueRefInformativoEspecialidades, setValueRefInformativoEspecialidades] = React.useState(false);
    const [valueRefInformativoEspecialistas, setValueRefInformativoEspecialistas] = React.useState(false);
    const [especialidadesHomologadas, setEspecialidadesHomologadas] = useState([]);
    const [especialistasHomologadas, setEspecialistasHomologadas] = useState([]);
    const [filtro, setFiltro] = useState('');
    const [selectedEspecialidad, setSelectedEspecialidad] = useState('');
    const [selectedEspecialista, setSelectedEspecialista] = useState('');
    const [isSecondSelectDisabled, setIsSecondSelectDisabled] = useState(true);
    const [isSecondSelectEspecialistaDisabled, setIsSecondSelectEspecialistaDisabled] = useState(true);
    const [isThirdSelectEspecialistaDisabled, setIsThirdSelectEspecialistaDisabled] = useState(true);
    
    const clinicsMaping = clinicsM.Maping();

    const columnsCondicionales = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'campo_verificar', headerName: 'Campo Verificar', editable: true, width: 190},   
        { field: 'descripcion_eps', headerName: 'Descripcion EPS', renderCell: renderCellExpand, editable: true, width: 200},        
        { field: 'step_condicional', headerName: 'Step Condicional', renderCell: renderCellExpand, editable: true, width: 200},
        { field: 'activo', headerName: 'Activo', width: 140, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.activo ? (
              <CheckIcon style={{ color: 'green'}} onClick={toggleActivo(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleActivo(params.row)}
              />
            );
        },},
        { field: 'guardar', headerName: 'Guardar', width: 140, renderCell: renderCellExpand, renderCell: (params) => {
            return  (
                <DoneIcon style={{ color: 'green' }} onClick={() => editStep(params.row)}/>                      
            )
        }, },
    ]

    const columnsCondicionalesEspecialidades = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'especialidad_validar', headerName: 'Especialidad Validar', editable: true, width: 200},   
        { field: 'descripcion_especialidad', headerName: 'Descripcion Especialidad', renderCell: renderCellExpand, editable: true, width: 220}, 
        { field: 'eps_condicional', headerName: 'Eps Condicional', editable: true, width: 190},
        { field: 'descripcion_eps', headerName: 'Descripcion EPS', renderCell: renderCellExpand, editable: true, width: 200}, 
        { field: 'mensaje_condicion', headerName: 'Mensaje Condicion', editable: true, renderCell: renderCellExpand, width: 200},        
        { field: 'mensaje_informativo', headerName: 'Mensaje Informativo', width: 160, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.mensaje_informativo ? (
              <CheckIcon style={{ color: 'green'}} onClick={toggleInformativoEspecialidades(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleInformativoEspecialidades(params.row)}
              />
            );
        },},
        { field: 'visualizar_bot', headerName: 'Visualizar Bot', width: 160, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.visualizar_bot ? (
              <CheckIcon style={{ color: 'green'}} onClick={toggleBotEspecialidades(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleBotEspecialidades(params.row)}
              />
            );
        },},        
        { field: 'activo', headerName: 'Activo', width: 130, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.activo ? (
              <CheckIcon style={{ color: 'green'}} onClick={toggleActivoEspecialidades(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleActivoEspecialidades(params.row)}
              />
            );
        },},
        { field: 'guardar', headerName: 'Guardar', width: 130, renderCell: renderCellExpand, renderCell: (params) => {
            return  (
                <DoneIcon style={{ color: 'green' }} onClick={() => editStepEspecialidades(params.row)}/>  
            )
        }, },
    ]

    const columnsCondicionalesEspecialistas = [
        { field: 'id', headerName: 'ID', width: 70},
        { field: 'especialista_validar', headerName: 'Especialista Validar', editable: true, width: 200},
        { field: 'descripcion_especialista', headerName: 'Descripcion Especialista', renderCell: renderCellExpand, editable: true, width: 220},
        { field: 'especialidad_validar', headerName: 'Especialidad Validar', editable: true, width: 200},   
        { field: 'descripcion_especialidad', headerName: 'Descripcion Especialidad', renderCell: renderCellExpand, editable: true, width: 220},
        { field: 'eps_condicional', headerName: 'Eps Condicional', editable: true, width: 190},
        { field: 'descripcion_eps', headerName: 'Descripcion EPS', renderCell: renderCellExpand, editable: true, width: 200}, 
        { field: 'mensaje_condicion', headerName: 'Mensaje Condicion', editable: true, renderCell: renderCellExpand, width: 200},
        { field: 'mensaje_informativo', headerName: 'Mensaje Informativo', width: 160, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.mensaje_informativo ? (
              <CheckIcon style={{ color: 'green'}} onClick={toggleInformativoEspecialistas(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleInformativoEspecialistas(params.row)}
              />
            );
        },},
        { field: 'visualizar_bot', headerName: 'Visualizar Bot', width: 160, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.visualizar_bot ? (
              <CheckIcon style={{ color: 'green' }} onClick={toggleBotEspecialistas(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleBotEspecialistas(params.row)}
              />
            );
        },},
        { field: 'activo', headerName: 'Activo', width: 130, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.activo ? (
              <CheckIcon style={{ color: 'green'}} onClick={toggleActivoEspecialistas(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleActivoEspecialistas(params.row)}
              />
            );
        },},
        { field: 'guardar', headerName: 'Guardar', width: 130, renderCell: renderCellExpand, renderCell: (params) => {
            return  (
                <DoneIcon style={{ color: 'green' }} onClick = {() => editStepEspecialistas(params.row)} alt = "Editar"/>                
            )
        }, },
    ]

    useEffect(async () => {
        await getAllStepsEspecialidades();
        await getAllStepsEspecialistas();
        await getAllSteps();
    }, [isUpdate])

    
    const handleChange = (event) => {
        setCondicional(event.target.value);
        setIsUpdate(!isUpdate);
        //llamar api eps homologadas
        getEpsHomologada(props.clinic.cli_id);
        getEspecialidadesHomologada(props.clinic.cli_id);
        setSelectedValue('');
        setSelectedEspecialidad('');
        setSelectedEspecialista('');
        setIsThirdSelectEspecialistaDisabled(true);
        setIsSecondSelectEspecialistaDisabled(true);

        if (event.target.value === 'condicionales_especialistas'){
            setIsCondicionalEspecialistas(true)
            setIsCondicionalEspecialidades(false)
            setIsCondicional(false)
        } else if (event.target.value === 'condicionales_especialidades'){
            setIsCondicionalEspecialistas(false)
            setIsCondicionalEspecialidades(true)
            setIsCondicional(false)
        } else {
            setIsCondicionalEspecialistas(false)
            setIsCondicionalEspecialidades(false)
            setIsCondicional(true)
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const createStep = () => {
        const StepCondicional = valueRefStepCondicional.current.value;
        const CampoVerificar = valueRefCampoVerificar.current.value;
        if (StepCondicional.length > 2 && CampoVerificar.length > 2) {
            const resp = staffAppointmentService.createStep(props.clinic.cli_id, CampoVerificar, StepCondicional);
            setIsUpdate(!isUpdate);
            handleClose();
        } else {
            alert("Ingrese 3 o más caracteres en los campos");
        }        
    };    

    const createStepEspecialidad = () => {
        const EspecialidadValidar = valueRefEspecialidadValidar.current.value;
        const MensajeCondicion = valueRefMensajeCondicion.current.value;
        if (EspecialidadValidar.length && MensajeCondicion.length) {            
            const resp = staffAppointmentService.createStepEspecialidad(props.clinic.cli_id, EspecialidadValidar, selectedValue, MensajeCondicion, valueRefVisualizarBot ? 1 : 0, valueRefInformativoEspecialidades ? 1 : 0);
            setIsUpdate(!isUpdate);
            handleClose();            
        } else {
            alert("Ingrese 1 o más caracteres en los campos");
        }  
    };

    const createStepEspecialista = () => {
        const EspecialidadValidar = valueRefEspecialidadValidar.current.value;
        const EspecialistaValidar = valueRefEspecialidadValidarEspecialistas.current.value;
        const MensajeCondicion = valueRefMensajeCondicionEspecialistas.current.value;
        if (EspecialidadValidar.length && MensajeCondicion.length && EspecialistaValidar.length) {
            const resp = staffAppointmentService.createStepEspecialista(props.clinic.cli_id, EspecialidadValidar, selectedValue, EspecialistaValidar, MensajeCondicion, valueRefVisualizarBotEspecialistas ? 1 : 0, valueRefInformativoEspecialistas ? 1 : 0);
            handleClose();
            setIsUpdate(!isUpdate);
        }else {
            alert("Ingrese 1 o más caracteres en los campos");
        }  
    };

    const toggleBotEspecialidades = React.useCallback(
        (clickedRow) => () => {
            setRowsEspecialidades((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, visualizar_bot: !clickedRow.visualizar_bot } : row,
                ),
            );
        },
        [],
    );

    const toggleBotEspecialistas = React.useCallback(
        (clickedRow) => () => {
            setRowsEspecialistas((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, visualizar_bot: !clickedRow.visualizar_bot } : row,
                ),
            );
        },
        [],
    );

    const toggleActivoEspecialidades = React.useCallback(
        (clickedRow) => () => {
            setRowsEspecialidades((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, activo: !clickedRow.activo } : row,
                ),
            );
        },
        [],
    );

    const toggleActivoEspecialistas= React.useCallback(
        (clickedRow) => () => {
            setRowsEspecialistas((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, activo: !clickedRow.activo } : row,
                ),
            );
        },
        [],
    );

    const toggleActivo = React.useCallback(
        (clickedRow) => () => {
            setRows((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, activo: !clickedRow.activo } : row,
                ),
            );
        },
        [],
    );

    const toggleInformativoEspecialistas = React.useCallback(
        (clickedRow) => () => {
            setRowsEspecialistas((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, mensaje_informativo: !clickedRow.mensaje_informativo } : row,
                ),
            );
        },
        [],
    );

    const toggleInformativoEspecialidades = React.useCallback(
        (clickedRow) => () => {
            setRowsEspecialidades((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, mensaje_informativo: !clickedRow.mensaje_informativo } : row,
                ),
            );
        },
        [],
    );

    const editStep = (row) => {
        const resp = staffAppointmentService.updateStep(row.id, row.campo_verificar, row.step_condicional, row.activo ? 1 : 0);
        setIsUpdate(!isUpdate);
    }

    const editStepEspecialidades = (row) => {
        const resp = staffAppointmentService.updateStepEspecialidad(row.id, row.especialidad_validar, row.eps_condicional, row.mensaje_condicion, row.activo ? 1 : 0, row.visualizar_bot ? 1 : 0, row.mensaje_informativo ? 1 : 0);
        setIsUpdate(!isUpdate);
    }

    const editStepEspecialistas = (row) => {
        const resp = staffAppointmentService.updateStepEspecialistas(row.id, row.especialista_validar, row.eps_condicional, row.especialidad_validar, row.mensaje_condicion, row.activo ? 1 : 0, row.visualizar_bot ? 1 : 0, row.mensaje_informativo ? 1 : 0);
        setIsUpdate(!isUpdate);
    }; 

    const getAllSteps = async () => {
        //Get data
        setRowsLoading(true);

        var list = [];
        var count = 0;

        const resp = await staffAppointmentService.getAllSteps(props.clinic.cli_id);
        count =  Object.keys(resp).length;
        if (count > 0) {    
            for (let j = 0; j < count; j++) {     
                list.push(resp[j]);
            }     
        }

        const rowsToRender = list.map((row, index) => {
            return {
                ...row,
                index: index,
            }
        })

        setRows(rowsToRender);
        setRowsLoading(false);
    }

    const getAllStepsEspecialidades = async () => {
        setRowsLoading(true);

        var list = [];
        var count = 0;

        const resp = await staffAppointmentService.getAllStepsEspecialidades(props.clinic.cli_id);
        count =  Object.keys(resp).length;
        if (count > 0) {    
            for (let j = 0; j < count; j++) {     
                list.push(resp[j]);
            }     
        }

        const rowsToRender = list.map((row, index) => {
            return {
                ...row,
                index: index,
            }
        })

        setRowsEspecialidades(rowsToRender);
        setRowsLoading(false);
    }

    const getAllStepsEspecialistas = async () => {
        setRowsLoading(true);

        var list = [];
        var count = 0;

        const resp = await staffAppointmentService.getAllStepsEspecialistas(props.clinic.cli_id);
        count =  Object.keys(resp).length;
        if (count > 0) {    
            for (let j = 0; j < count; j++) {   
                list.push(resp[j]);
            }     
        }

        const rowsToRender = list.map((row, index) => {
            return {
                ...row,
                index: index,
            }
        })

        setRowsEspecialistas(rowsToRender);
        setRowsLoading(false);
    }

    const handleStepChange = (event) => {
        setValueRefVisualizarBot(event.target.checked);
    };

    const handleStepChangeEspecialistas = (event) => {
        setValueRefVisualizarBotEspecialistas(event.target.checked);
    };

    const handleInformativoEspecialidadesChange = (event) => {
        setValueRefInformativoEspecialidades(event.target.checked);
    };

    const handleInformativoEspecialistasChange = (event) => {
        setValueRefInformativoEspecialistas(event.target.checked);
    };

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
        setIsSecondSelectDisabled(!event.target.value);
    };

    const handleSelectEspecialistaChange = (event) => {
        setSelectedValue(event.target.value);
        setIsSecondSelectEspecialistaDisabled(!event.target.value);
    };

    const handleEspecialidadChange = (event) => {
        setSelectedEspecialidad(event.target.value);
        getEspecialistasHomologada(props.clinic.cli_id, selectedEspecialidad);
        setIsThirdSelectEspecialistaDisabled(!event.target.value);
    };

    const handleFilterChange = (event) => {
        setFiltro(event.target.value.toLowerCase());
    };

    const handleEspecialistaChange = (event) => {
        setSelectedEspecialista(event.target.value);
    };

    const getEpsHomologada = async () => {
        var list = [];
        var count = 0;

        const clinicIdCitapp = clinicsMaping[props.clinic.cli_id];

        const resp = await staffAppointmentService.getEpsHomologada(clinicIdCitapp);
        count =  Object.keys(resp).length;
        if (count > 0) {    
            for (let j = 0; j < count; j++) {   
                const obj = {
                    "Nombre": resp[j]['EmpDsc'],
                    "Codigo": resp[j]['contrato']
                };
                list.push(obj);
            }     
        }

        setEpsHomologadas(list);
    }

    const getEspecialidadesHomologada = async () => {
        var list = [];
        var count = 0;

        const clinicIdCitapp = clinicsMaping[props.clinic.cli_id];

        const resp = await staffAppointmentService.getEspecialidadesHomologada(clinicIdCitapp);
        count =  Object.keys(resp).length;
        if (count > 0) {    
            for (let j = 0; j < count; j++) {   
                const obj = {
                    "Nombre": resp[j]['MENomE'].trim(),
                    "Codigo": resp[j]['MECodE']
                };
                list.push(obj);
            }     
        }

        setEspecialidadesHomologadas(list);
    }

    const getEspecialistasHomologada = async () => {
        var list = [];
        var count = 0;

        const clinicIdCitapp = clinicsMaping[props.clinic.cli_id];

        const resp = await staffAppointmentService.getEspecialistasHomologada(clinicIdCitapp, selectedEspecialidad);
        count =  Object.keys(resp).length;
        if (count > 0) {    
            for (let j = 0; j < count; j++) {   
                const obj = {
                    "Nombre": resp[j]['MMNomM'].trim(),
                    "Codigo": resp[j]['MMCODM']
                };
                list.push(obj);
            }     
        }

        setEspecialistasHomologadas(list);
    }

    return (
        <div>
            <div style={{display: "flex",  alignItems: "center", justifyContent: "center", padding: '20px'}}>
            <FormControl sx={{ m: 1, minWidth: 180}}>
                <Select
                value={condicional}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Without label' }}
                >
                <MenuItem value={'condicionales'}>Eps</MenuItem>
                <MenuItem value={'condicionales_especialidades'}>Especialidades</MenuItem>
                <MenuItem value={'condicionales_especialistas'}>Especialistas</MenuItem>
                </Select>
            <FormHelperText>Aquí puede seleccionar el tipo de condicional.</FormHelperText>
            </FormControl>
            </div>

            {isCondicional && <div style={{display: "flex",  alignItems: "center", justifyContent: "center", paddingBottom: "35px"}}>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Crear Condicional Eps
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Step Condicional</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Debe completar los campos a continuación para crear un nuevo step
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="campo_verificar"
                        label="Campo Verificar"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputRef={valueRefCampoVerificar}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="step_condicional"
                        label="Step Condicional"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputRef={valueRefStepCondicional}
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={createStep}>Crear</Button>
                    </DialogActions>
                </Dialog>
            </div>}
        
            {isCondicional && <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Condicionales
            </Typography>
            {alert.show && (
                <ShowAlert
                {...alert}
                />
            )}
            <DataGrid
                autoHeight
                rows={rows}
                loading={rowsLoading}
                columns={columnsCondicionales}
                pageSize={4}
                // onRowClick={(params) => {
                // handleRowSelected(params);//if (params.row.Estado === 'ATENDIDA')
                // }}
            />
            </React.Fragment>}

            {isCondicionalEspecialidades && <div style={{display: "flex",  alignItems: "center", justifyContent: "center", paddingBottom: "35px"}}>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Crear Condicional Especialidades
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Step Condicional Especialidades</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Debe completar los campos a continuación para crear un nuevo step especialidades
                    </DialogContentText>
                    <InputLabel id="select">Eps</InputLabel>
                    <Select
                        value={selectedValue}
                        onChange={handleSelectChange}
                        fullWidth
                        margin="dense"
                        id="select"
                        label="Eps"
                    >
                        {epsHomologadas.map((eps) => (
                            <MenuItem value={eps.Codigo}>{eps.Nombre}</MenuItem>
                        ))}
                    </Select>
                    <div style={{ textAlign: 'center' }}>
                        <TextField
                            label="Filtrar especialidad por nombre"
                            value={filtro}
                            onChange={handleFilterChange}
                            fullWidth
                            margin="dense"
                            style={{ width: '50%', fontSize: '8px', margin: 'auto', display: 'flex', marginTop: '40px' }}
                        />                        
                    </div>
                    <InputLabel id="select" style={{ marginTop: '20px' }}>Especialidad</InputLabel>
                    <Select
                        value={selectedEspecialidad}
                        onChange={handleEspecialidadChange}
                        fullWidth
                        margin="dense"
                        id="especialidad_validar"
                        label="Especialidad Validar"
                        inputRef={valueRefEspecialidadValidar}
                        disabled={isSecondSelectDisabled}
                    >
                        {especialidadesHomologadas
                            .filter(especialidad => especialidad.Nombre.toLowerCase().includes(filtro))
                            .map((especialidad) => (
                                <MenuItem key={especialidad.Codigo} value={especialidad.Codigo}>
                                {especialidad.Nombre}
                                </MenuItem>
                            ))}
                    </Select>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="mensaje_condicion"
                        label="Mensaje Condicion"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputRef={valueRefMensajeCondicion}
                        style={{ marginTop: '50px' }}
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={valueRefVisualizarBot}
                            onChange={handleStepChange}
                            name="valueRefVisualizarBot"
                            color="primary"
                        />
                        }
                        label="Visualizar Bot"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={valueRefInformativoEspecialidades}
                            onChange={handleInformativoEspecialidadesChange}
                            name="valueRefInformativoEspecialidades"
                            color="primary"
                        />
                        }
                        label="Mensaje Informativo"
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={createStepEspecialidad}>Crear</Button>
                    </DialogActions>
                </Dialog>
            </div>}

            {isCondicionalEspecialidades && <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Condicionales Especialidades
            </Typography>
            {alert.show && (
                <ShowAlert
                {...alert}
                />
            )}
            <DataGrid
                autoHeight
                rows={rowsEspecialidades}
                loading={rowsLoading}
                columns={columnsCondicionalesEspecialidades}
                pageSize={7}
            />
            </React.Fragment>}

            {isCondicionalEspecialistas && <div style={{display: "flex",  alignItems: "center", justifyContent: "center", paddingBottom: "35px"}}>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Crear Condicional Especialistas
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Step Condicional Especialistas</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Debe completar los campos a continuación para crear un nuevo step especialistas
                    </DialogContentText>
                    <InputLabel id="select">Eps</InputLabel>
                    <Select
                        value={selectedValue}
                        onChange={handleSelectEspecialistaChange}
                        fullWidth
                        margin="dense"
                        id="select"
                        label="Eps"
                    >
                        {epsHomologadas.map((eps) => (
                            <MenuItem value={eps.Codigo}>{eps.Nombre}</MenuItem>
                        ))}
                    </Select>
                    <div style={{ textAlign: 'center' }}>
                        <TextField
                            label="Filtrar especialidad por nombre"
                            value={filtro}
                            onChange={handleFilterChange}
                            fullWidth
                            margin="dense"
                            style={{ width: '50%', fontSize: '8px', margin: 'auto', display: 'flex', marginTop: '40px' }}
                        />
                    </div>
                    <InputLabel id="select" style={{ marginTop: '20px' }}>Especialidad</InputLabel>
                    <Select
                        value={selectedEspecialidad}
                        onChange={handleEspecialidadChange}
                        fullWidth
                        margin="dense"
                        id="especialidad_validar"
                        label="Especialidad Validar"
                        inputRef={valueRefEspecialidadValidar}
                        disabled={isSecondSelectEspecialistaDisabled}
                    >
                        {especialidadesHomologadas
                            .filter(especialidad => especialidad.Nombre.toLowerCase().includes(filtro))
                            .map((especialidad) => (
                                <MenuItem key={especialidad.Codigo} value={especialidad.Codigo}>
                                {especialidad.Nombre}
                                </MenuItem>
                            ))}
                    </Select> 
                    <InputLabel id="select" style={{ marginTop: '50px' }}>Especialista</InputLabel>
                    <Select
                        value={selectedEspecialista}
                        onChange={handleEspecialistaChange}
                        fullWidth
                        margin="dense"
                        id="especialista_validar"
                        label="Especialista Validar"
                        inputRef={valueRefEspecialidadValidarEspecialistas}
                        disabled={isThirdSelectEspecialistaDisabled}
                    >
                        {especialistasHomologadas.map((eps) => (
                            <MenuItem value={eps.Codigo}>{eps.Nombre}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="mensaje_condicion_especialistas"
                        label="Mensaje Condicion Especialistas"
                        type="text"
                        fullWidth
                        variant="standard"
                        inputRef={valueRefMensajeCondicionEspecialistas}
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={valueRefVisualizarBotEspecialistas}
                            onChange={handleStepChangeEspecialistas}
                            name="valueRefVisualizarBotEspecialistas"
                            color="primary"
                        />
                        }
                        label="Visualizar Bot"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={valueRefInformativoEspecialistas}
                            onChange={handleInformativoEspecialistasChange}
                            name="valueRefInformativoEspecialistas"
                            color="primary"
                        />
                        }
                        label="Mensaje Informativo"
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={createStepEspecialista}>Crear</Button>
                    </DialogActions>
                </Dialog>
            </div>}

            {isCondicionalEspecialistas && <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Condicionales Especialistas
            </Typography>
            {alert.show && (
                <ShowAlert
                {...alert}
                />
            )}
            <DataGrid
                autoHeight
                rows={rowsEspecialistas}
                loading={rowsLoading}
                columns={columnsCondicionalesEspecialistas}
                pageSize={7}
            />
            </React.Fragment>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    lastContrat: state.lastContrat,
    clinic: state.clinic
})

export default connect(mapStateToProps, null)(StaffExceptions);