import { BehaviorSubject } from "rxjs";
import { TokenFlags } from "typescript";

import { handleResponse } from "../helpers/handle-response";
// Services
import { storeService } from "./storeService";
//7
import axios from "axios";

const urlBase = process.env.REACT_APP_URL_HOST;

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  manualLogin,
  signUp,
  resetUserPassword,
  validateIfUserexists,
  validationUser, //validacion usuario por cedula
  loginReset,
};

function login(username, cardIdType, cardId, clinicId) {
  const body = JSON.stringify({
    username,
    cardIdType,
    cardId,
    clinicId,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };

  return fetch(
    process.env.REACT_APP_URL_HOST + "/cerotram/checkonhis",
    requestOptions
  )
    .then(handleResponse)
    .then((resp) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (!resp.error) {
        localStorage.setItem("currentUser", JSON.stringify(resp));
        currentUserSubject.next(resp);
      }
      return resp;
    });
}

function manualLogin(username, password) {
  const body = JSON.stringify({
    username,
    password,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };

  return fetch(
    process.env.REACT_APP_URL_HOST + "/cerotram/api/token/",
    requestOptions
  )
    .then(handleResponse)
    .then((tokens) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      /* localStorage.setItem('currentUser', JSON.stringify(resp));
      currentUserSubject.next(resp); */
      return tokens;
    });
}

function resetUserPassword(document, email, password) {
  const body = JSON.stringify({
    document: document,
    email: email,
    new_password: password,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };
  return fetch(
    process.env.REACT_APP_URL_HOST + "/cerotram/change/password/",
    requestOptions
  )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    });
}

function validateIfUserexists(card_id, card_id_type) {
  const body = JSON.stringify({
    card_id: card_id,
    card_id_type: card_id_type,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };
  return fetch(
    process.env.REACT_APP_URL_HOST + "/cerotram/validate/username/",
    requestOptions
  )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    });
}

function signUp({ firstName, lastName, dob, cardId, email, clinic, password }) {
  const dateOfBirth = dob.toDateString();
  const body = JSON.stringify({
    firstName,
    lastName,
    dateOfBirth,
    cardId,
    email,
    clinic,
    password,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };

  return fetch(
    process.env.REACT_APP_URL_HOST + "/cerotram/register/",
    requestOptions
  )
    .then((resp) => resp.json())
    .then((resp) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      /* localStorage.setItem('currentUser', JSON.stringify(resp));
      currentUserSubject.next(resp); */
      //console.log(resp);
      return resp;
    });
}
//// validacion si existe usuario segun numero de cedula
function validationUser(cardId, clinic){
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };
  return fetch(
    process.env.REACT_APP_URL_HOST + `/cerotram/cedula_clinica/paciente/?Cedula=${cardId}&CodClinica=${clinic}`,
    requestOptions
  )
  .then((resp) => resp.json())
  .then((resp) => {
    return resp;
  });
}
///



function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  const currentStore = storeService.loadFromLocalStorage();
  localStorage.setItem(
    "state",
    JSON.stringify({
      ...currentStore,
      lastContrat: "",
      patients: [],
      user: {},
      faceAuth: null,
      clinicsMaping: [],
    })
  );
  currentUserSubject.next(null);
}


function loginReset(username, cardIdType, cardId) {
  const body = JSON.stringify({
    username,
    cardIdType,
    cardId,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };

  return fetch(
    process.env.REACT_APP_URL_HOST + "/cerotram/checkonhis",
    requestOptions
  )
    .then(handleResponse)
    .then((resp) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if (!resp.error) {
        localStorage.setItem("currentUser", JSON.stringify(resp));
        currentUserSubject.next(resp);
      }
      return resp;
    });
}