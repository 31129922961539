import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import red from '@material-ui/core/colors/red';

import useBrowserContextCommunication from 'react-window-communication-hook';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  grid: {
    height: "0px",
    marginLeft: "94%",
  },
}));



export default function CancelAppoinmentsModal(props) {
  const classes = useStyles();
  const [closeModal, setCloseModal] = useState(true);
  const [communicationState, postMessage] = useBrowserContextCommunication("CitappModalIframe");


  if (props.urlRescheduleCancelAppointmentModal) {
    console.log('props.urlCancelAppoinmentsModal ', props.urlCancelAppoinmentsModal)

  }


  const handleCloseButton = () => {
    setCloseModal(true);
    props.handleOpenCloseRescheduleCancelAppointmentModal();
    props.handleOpenCloseCitappModal(props.urlRescheduleAppointmentModal);
    postMessage({
      createNewAppointment: true
    });
  }

  const clickbutton = () => {
    var iframe = document.getElementById("iframe_id");
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var currentFrame = innerDoc.location.href;
    console.log('currentFrame ',currentFrame)
  }

  useEffect(() => {
    console.log('valor props. ', props.urlRescheduleCancelAppointmentModal)
    /*     if (props.urlCancelAppoinmentsModal) {
          console.log('url iframe', document.getElementById("iframe_id").contentWindow.location.href)
        } */
    return () => {
      postMessage({
        createNewAppointment: true
      });
    }
  }, []);


  /* let lastUrl = window.location.href; 
  new MutationObserver (() => {
    console.log('leer cambio url')
    const url = window.location.href;
    if (url !== lastUrl) {
      lastUrl = url;
      onUrlChange();
    }
  }).observe(document, {subtree: true, childList: true});
   
   
  
  function onUrlChange() {
    alert('URL changed!', window.location.href);
    console.log('URL changed!');
  } */

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleOpenCloseRescheduleCancelAppointmentModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
      disableEscapeKeyDown
      disableScrollLock={false}
    >
      <Fade in={props.open}>
        <Grid className={classes.paper} >
          <Grid className={classes.grid} >
            <IconButton edge="start" size="medium" onClick={() => handleCloseButton()} color="primary">
              <CloseIcon style={{ color: red[400] }} />
            </IconButton>
          </Grid>
          <iframe id="iframe_id"
            title="Inline Frame Example"
            //onChange={props.urlCancelAppoinmentsModal && console.log('url iframe', document.getElementById("iframe_id").contentWindow.location.href)}
            width="610"
            height="610"
            src={props.urlRescheduleCancelAppointmentModal}>
          </iframe>
        </Grid>
      </Fade>
    </Modal>
  );
}