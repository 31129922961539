import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  grid:{
    height:"0px", 
    width:"1px",
    marginLeft:"92%",
  },
}));

export default function TransferFileAppointmentsModal(props) {
  const classes = useStyles();
  const [closeModal, setCloseModal] = useState(true);

  const handleCloseButton=()=>{
    setCloseModal(true);
    props.handleOpenCloseTransferFileAppointmentsModal("");
    //window.location.reload();
    }

  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={props.open}
    onClose={props.handleOpenCloseTransferFileAppointmentsModal}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
        timeout: 500,
    }}
    >
    <Fade in={props.open}>
    <Grid className={classes.paper} >
          <Grid className={classes.grid}>
              <IconButton edge="start" size="medium" onClick={()=>handleCloseButton()}>
                  <CloseIcon/>
              </IconButton>
          </Grid>
            <iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="450"
                height="520"
                src={props.urlTransferFileAppointmentsModal}>
            </iframe>
        </Grid>
    </Fade>
    </Modal>
  );
}