import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';

import useBrowserContextCommunication from 'react-window-communication-hook';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  grid:{
    height:"0px", 
    marginLeft:"94%",
  },
}));

export default function CancelAppoinmentsModal(props) {
  const classes = useStyles();
  const [closeModal, setCloseModal] = useState(true);
  const [communicationState, postMessage] = useBrowserContextCommunication("DashboardStaffChanel");

  const handleCloseButton=()=>{
    setCloseModal(true);
    props.handleOpenCloseCancelAppoinmentsModal("");
    postMessage({
      createNewAppointment: true
    });
    }

    useEffect(() => {
      return ()=>{
        postMessage({
          createNewAppointment: true
        });
      }
    },[]);


  return (
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={props.open}
    onClose={props.handleOpenCloseCancelAppoinmentsModal}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
        timeout: 500,
    }}
    disableBackdropClick
    disableEscapeKeyDown
    disableScrollLock={false}
    >
    <Fade in={props.open}>
    <Grid className={classes.paper} >
      <Grid className={classes.grid} >
              <IconButton edge="start" size="medium" onClick={()=>handleCloseButton()} color="primary">
                  <CloseIcon  style={{ color: red[400] }} />
              </IconButton>
          </Grid>
            <iframe id="inlineFrameExample"
                title="Inline Frame Example"
                width="610"
                height="610"
                src={props.urlCancelAppoinmentsModal}>
            </iframe>
        </Grid>
    </Fade>
    </Modal>
  );
}