import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ShowAlert from '../../components/ShowAlert';
import useBrowserContextCommunication from 'react-window-communication-hook';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DescriptionIcon from '@material-ui/icons/Description';
import ContractInfoForm from '../../components/staff/ContractInfoForm'
import UploadDocument from '../../components/UploadDocument'
// Services
import { hisServices } from '../../services/hisServices';
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
import { appointmentService } from '../../services/appointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  field: {
    width: "100%",
    marginTop: "2px",
  },
  grid: {
    height: "0px",
    marginLeft: "92%",
  },
  principalCont: {
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingRight: "20px",
  },
  principalCont1: {
    paddingLeft: "10px",
    paddingTop: "1px",
    paddingBottom: "10px",
    paddingRight: "10px",
  },
}));

const SendIncapacityToEmployerModal = (props) => {
  const classes = useStyles();
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [formDataDocument, setFormDataDocument] = useState(false);
  const [status, setStatus] = useState(props.paramsRowIncapacity?.estado);
  const [allStatus, setAllStatus] = useState(['TRAMITE INTERNO', 'RADICADO POR LA EPS']);
  const [communicationState, postMessage] = useBrowserContextCommunication("CitappModalIframe");
  const [disabledModal, setDisabledModal] = useState(false);
  const [description, setDescription] = useState("");
  const [alert, handleAlert] = useState({ show: false, type: null, title: null, message: null, strong: null, action: null });

  const clinicsMaping = clinicsM.Maping();

  const handleCloseButton = () => {
    setCloseModal(true);
    setDisabledModal(false);
    //setStatus('TRAMITE INTERNO');
    setDescription("");
    props.handleOpenCloseSendIncapacityToEmployerModal();
    postMessage({
      incapacitiesReload: true
    });
  }

  const handleDocumentToUpload = (document) => {
    setFormDataDocument(document);

  }

  const viewDocumentIncapacity = async () => {
    //Get data
    setLoadingDocument(true);
    console.log("Props Value", props.paramsRowIncapacity);
    const clinicId = clinicsMaping[props.clinic.cli_id]
    try {
      const resp = await staffAppointmentService.viewDocumentIncapacity(clinicId,
        props.paramsRowIncapacity.nro_Docum,
        props.paramsRowIncapacity.Folio,
        null);
      var file = new Blob([resp.data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      //console.log("valor de URL ", fileURL)
      window.open(fileURL);


    } catch {
      alert("Error al crear archivo");
    }
    setLoadingDocument(false);
  }

  const changeIncapacityStatus = async () => {
    //Send data
    setDisabledModal(true);
    setLoadingSubmit(true);
    //console.log("valor de archivo ",formDataDocument)
    const clinicId = clinicsMaping[props.clinic.cli_id]
    const urlIncapacity = "https://portubien.com.co/appRic_desarrollo/OMedicas/view_incapacidad/" + clinicId + "/" + props.paramsRowIncapacity.nro_Docum + "/" + props.paramsRowIncapacity.Folio;
    console.log("valor de url ", urlIncapacity);

    try {
      //const formData = new FormData();
      //formData.append("file", props.clinic);

      const resp = await staffAppointmentService.changeIncapacityStatus(props.clinic, props.paramsRowIncapacity, "RADICADO POR LA EPS", description, formDataDocument);
      //const resp = await staffAppointmentService.changeIncapacityStatus(props.clinic, props.paramsRowIncapacity, status, description);
      handleAlert({
        ...alert,
        show: true,
        type: "success",
        message: ``,
        strong: "INCAPACIDAD RADICADA SATISFACTORIAMENTE"
      })
      setDisabledModal(true);
      setLoadingSubmit(false);

    } catch {
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: ``,
        strong: "ERROR AL RADICAR, INTENTE NUEVAMENTE"
      })
    }

    setLoadingSubmit(false);
    setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
    //await getAllIncapacitiesByDate(selectedDate);
  }


  const sendIncapacityToEmployer = async () => {
    //Send data
    setDisabledModal(true);
    setLoadingSubmit(true);
    const clinicId = clinicsMaping[props.clinic.cli_id]
    const urlIncapacity = "https://portubien.com.co/appRic_desarrollo/OMedicas/view_incapacidad/" + clinicId + "/" + props.paramsRowIncapacity.nro_Docum.trim() + "/" + props.paramsRowIncapacity.Folio;
    console.log("valor de url ", urlIncapacity);

    await changeIncapacityStatus();
    //console.log("valor de archivo ",formDataDocument)

    try {

      //const formData = new FormData();
      //formData.append("file", props.clinic);
      const resp = await appointmentService.sendIncapacityToEmployer(props.user, props.clinic, description, urlIncapacity);
      //const resp = await staffAppointmentService.changeIncapacityStatus(props.clinic, props.paramsRowIncapacity, status, description);
      handleAlert({
        ...alert,
        show: true,
        type: "success",
        message: ``,
        strong: "INCAPACIDAD RADICADA SATISFACTORIAMENTE"
      })
      setDisabledModal(true);
      setLoadingSubmit(false);

    } catch {
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: ``,
        strong: "ERROR AL RADICAR, INTENTE NUEVAMENTE"
      })
    }

    setLoadingSubmit(false);
    setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
    //await getAllIncapacitiesByDate(selectedDate);
  }

  useEffect(() => {
    setStatus(props.paramsRowIncapacity?.estado)
    setDisabledModal(false);
    setDescription("");
  }, [])




  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleOpenCloseIncapacityAcceptModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Fade in={props.open}>
        <Grid
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={1}
        >
          <Grid className={classes.grid} >
            <IconButton edge="start" size="medium" onClick={() => handleCloseButton()} color="primary">
              <CloseIcon style={{ color: red[400] }} />
            </IconButton>
          </Grid>
          <Paper variant="outlined" className={classes.principalCont}>
            <Container component="main" maxWidth="sm" spacing={3}>
              <div style={{ width: "100%", marginBottom: "5px" }}> {alert.show && (
                <ShowAlert
                  {...alert}
                />
              )}</div>
              <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <Paper variant="outlined" className={classes.principalCont1}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography variant="h6" gutterBottom>
                          INFORMACIÓN DE INCAPACIDAD:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Total días de incapacidad:</strong> {props.paramsRowIncapacity?.Dias_Incap}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Tipo de Incapacidad:</strong> {props.paramsRowIncapacity?.Tipo_de_Incapacidad}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Diagnóstico:</strong> {props.paramsRowIncapacity?.Nombre_Diag}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Tratamiento:</strong> {props.paramsRowIncapacity?.Tipo_Tratamiento}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h5">
                          <strong>Estado de la incapacidad:</strong> {props.paramsRowIncapacity?.estado}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper variant="outlined" className={classes.principalCont1}>
                    <Grid item>
                      <Typography variant="h6" gutterBottom style={{ marginTop: "10px" }}>
                        Información Adicional
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="stretch"
                      spacing={1}
                    >

                      {/* <Grid item>
                        <InputLabel id="StatusLabel">Estado de la Incapacidad</InputLabel>
                        <Select
                          required
                          labelId="StatusLabel"
                          id="status"
                          name="status_changed_to"
                          value={status}
                          onChange={(event) => { setStatus(event.target.value) }}
                          fullWidth

                        >
                          {allStatus && (allStatus.map((status, index) => (
                            <MenuItem key={index} value={status}>{status}</MenuItem>
                          )))}
                        </Select>
                      </Grid> */}

                      <Grid item>
                        <TextField
                          className={classes.field}
                          id="outlined-multiline-static"
                          label="Observaciones"
                          name="description"
                          multiline
                          //disabled={!props.editContract || (props.isAuthorized && status==="NO AUTORIZADO")}
                          rows={4}
                          variant="outlined"
                          onChange={(event) => setDescription(event.target.value)}
                          value={description}
                        />
                      </Grid>
                      <Grid item>
                        <UploadDocument
                          handleDocumentToUpload={handleDocumentToUpload}
                        />
                      </Grid>

                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    {/* <Grid item>
                      {loadingDocument && <LinearProgress />}
                      <Button
                        size="small"
                        disabled={disabledModal || loadingDocument}
                        variant="contained"
                        //color="primary"
                        startIcon={<DescriptionIcon />}
                        onClick={() => window.open("https://portubien.com.co/appRic_desarrollo/OMedicas/view_historia_clinica/19/1234/77")}
                       >
                        EPICRISIS
                      </Button>
                    </Grid> */}

                    <Grid item>
                      {loadingDocument && <LinearProgress />}
                      <Button
                        size="small"
                        disabled={disabledModal || loadingDocument}
                        variant="contained"
                        //color="primary"
                        startIcon={<DescriptionIcon />}
                        onClick={() => viewDocumentIncapacity()}
                      >
                        INCAPACIDAD
                      </Button>
                    </Grid>

                    <Grid item>
                      {loadingSubmit && <LinearProgress />}
                      <Button
                        size="small"
                        disabled={disabledModal}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={() => sendIncapacityToEmployer()}
                      >
                        Enviar a Empleador
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Container>
          </Paper>
        </Grid>
      </Fade>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(SendIncapacityToEmployerModal);