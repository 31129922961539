import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import PulseLoanding from '../assets/static/PulseLoanding.svg';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function BackdropLoading(props) {
  const classes = useStyles();

  return (
      <Backdrop className={classes.backdrop} open={props.open}>
        {/* <CircularProgress color="inherit" /> */}
        {/* <CircularProgress /> */}
        <img src={PulseLoanding} width="10%" height="10%"/>
      </Backdrop>
  );
}
