import React, { useState, useEffect } from 'react';
// redux
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
//import Login from './Login'
import GeneralInfoForm from '../components/GeneralInfoForm';
import ContactInfoForm from '../components/ContactInfoForm';
import EmployerInfoForm from '../components/EmployerInfoForm';
import ShowAlert from '../components/ShowAlert';
import FaceDetection from './FaceDetection';
import VerificationCode from '../components/VerificationCode';
// actions
import { setPatient } from '../actions';

// Services
import { hisServices } from '../services/hisServices';
import { appointmentService } from '../services/appointmentService';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Datos Generales', 'Datos EPS', 'Datos Empleador', 'Confimación de identidad'];
}


const UpdateInfoSteper = (props) => {

  const classes = useStyles();
  const [employerInfo, setEmployerInfo] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [alert, handleAlert] = useState({ show: false, type: null, title: null, message: null, strong: null, action: null });
  const [form, setFormValues] = useState({
    documento: '',
    tipo_documento: '',
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    nombre_completo: '',
    fecha_nacimiento: '',
    genero: '',
    departamento: '',
    municipio: '',
    barrio: '',
    email: '',
    telefono: '',
    direccion: ''
  });
  const [verificationCodeInput, setVerificationCodeInput] = useState('a')
  const [originalVerificationCode, setOriginalVerificationCode] = useState('b')
  const [formContratos, setFormContratosValues] = useState({
    documento: '',
    tipo_documento: '',
    contrato: '',
    regimen: '',
    nivel_usuario: '',
    fecha_vigencia: '',
    prioridad: ''
  });
  const [formEmpleador, setFormEmpleadorValues] = useState({
    employer_name: '',
    employer_phone: '',
    employer_email: '',
    employer_authorization: true
  });
  const [lastContrat, setLastContrat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableFinishButton, setDisableFinishButton] = useState(false);

  const handleVerificationCodeInput = event => {
    setDisableFinishButton(false);
    setVerificationCodeInput(event.target.value);
  }

  const handleInput = event => {
    setFormValues({
      ...form,
      [event.target.name]: event.target.value
    })
  };

  const handleInputFormContratos = event => {
    setFormContratosValues({
      ...formContratos,
      [event.target.name]: event.target.value
    })
  };

  const handleInputFormEmpleador = event => {
    if (event.target.name === 'employer_authorization') {
      setFormEmpleadorValues({
        ...formEmpleador,
        [event.target.name]: event.target.checked
      })
    } else {
      setFormEmpleadorValues({
        ...formEmpleador,
        [event.target.name]: event.target.value
      })
    }
  };


  const handleSubmit = async () => {
    const check = await hisServices.checkVerificationCode(props.user, verificationCodeInput);

    if (check.data.result) {
      if (!props.patients) {
        //
        setLoading(true);
        let data = await hisServices.registerPatient(form, props.clinic.cli_id);
        setLoading(false);
        props.setPatient(data.data.data);
      } else {
        //
        setLoading(true);
        let data = await hisServices.updatePatient(form, props.clinic.cli_id);
        props.setPatient(data.data.data);
        setLoading(false);
      }
      setLoading(true);
      let data = await hisServices.registerContratPatient(formContratos, props.clinic.cli_id);

      //Create Employer
      await appointmentService.createEmployer(props.user, formEmpleador)

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoading(false);

    } else {
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `Código erróneo`,
        strong: "Intenta nuevamente"
      })
      setTimeout(() => handleAlert({ ...alert, show: false }), 4000)
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <GeneralInfoForm form={form} handleInput={handleInput} clinicId={props.clinic.cli_id} />;
      case 1:
        return <ContactInfoForm
          clinic={props.clinic}
          user={props.user}
          form={formContratos}
          handleInput={handleInputFormContratos}
        />;
      case 2:
        return <EmployerInfoForm
          clinic={props.clinic}
          user={props.user}
          form={formEmpleador}
          handleInput={handleInputFormEmpleador}
        />;
      case 3:
        return <VerificationCode
          user={props.user}
          handleVerificationCodeInput={handleVerificationCodeInput}
          handleAlert={handleAlert}
        />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const steps = getSteps();

  const handleNext = () => {
    if ((activeStep + 1) === 3) {
      setDisableFinishButton(true);
    } else {
      setDisableFinishButton(false);
    }
    const generalFormIsEmty = Object.entries(form).filter(a => a[1] === '').length !== 0;
    const contratsFormIsEmty = false;
    if ((activeStep === 0 && generalFormIsEmty) || (activeStep === 1 && contratsFormIsEmty)) {
      setLoading(true);
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `Todos los campos son obligatorios`,
        strong: "Por favor Completa el Formulario"
      })
      setLoading(false);
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000)
    } else {
      handleAlert({ ...alert, show: false })
      setLoading(true);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoading(false);
    }
  };

  const handleBack = () => {
    setDisableFinishButton(false);
    setLoading(true);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setLoading(false);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getData = async (url) => {
    const requestOptions = {
      method: 'GET',
    };
    setLoading(true);
    const resp = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    setLoading(false);
    return resp;
  }

  const getEmployerInformation = async () =>{
    const resp = await appointmentService.getEmployerInfo(props.user);
      console.log('resp employer  ', resp)
      setFormEmpleadorValues({
        ...formEmpleador,
        employer_name: resp.data[0]?.employer_name,
        employer_phone: resp.data[0]?.employer_phone,
        employer_email: resp.data[0]?.employer_email,
        employer_authorization: resp.data[0]?.employer_authorization
      })
      setEmployerInfo(resp.data[0]);
  }

  useEffect(async () => {
    console.log('hola');
    setLoading(true);
    getEmployerInformation();
    const pat = props.patients ? props.patients[0] : null;

    if (!pat) {
      setFormValues({
        ...form,
        email: props.user.user.username,
        nombre_completo: `${props.user.user.first_name} ${props.user.user.last_name}`
      });
      setFormContratosValues({
        ...formContratos,
        documento: props.user.card_id,
        fecha_vigencia: (new Date()).toISOString().split('T')[0],
        prioridad: 1,
        tipo_documento: props.user.card_id_type
      })
      setLoading(false);
      handleAlert({
        ...alert,
        show: true,
        type: "info",
        message: `Aún no estás registrado en ${props.clinic.name}`,
        strong: "Por favor Completa el Formulario"
      })
    } else {
      setLoading(true);
      setFormValues({
        ...form,
        primer_nombre: pat.primer_nombre,
        segundo_nombre: pat.segundo_nombre,
        primer_apellido: pat.primer_apellido,
        segundo_apellido: pat.segundo_apellido,
        tipo_documento: pat.tipo_documento,
        documento: pat.documento,
        telefono: pat.telefono,
        genero: pat.genero,
        fecha_nacimiento: pat.fecha_nacimiento,
        direccion: pat.direccion,
        departamento: pat.departamento,
        municipio: pat.municipio,
        barrio: pat.barrio,
        email: props.user.user.username,
        nombre_completo: `${props.user.user.first_name} ${props.user.user.last_name}`
      });

      //const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getUltimoContratoPaciente/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`
      const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getContratoPacienteSelect/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`
      
      //const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + '/api/getUltimoContratoPaciente/19/1010021353/CC';
      const lastContratData = await getData(urlLastContrat);

      setLastContrat(lastContratData.data);
      setFormContratosValues({
        ...formContratos,
        documento: props.user.card_id,
        tipo_documento: props.user.card_id_type,
        contrato: lastContratData.data ? lastContratData.data[0].contrato : '',
        regimen: lastContratData.data ? lastContratData.data[0].regimen : '',
        nivel_usuario: lastContratData.data ? lastContratData.data[0].nivel_usuario : '',
        fecha_vigencia: (new Date()).toISOString().split('T')[0],
        prioridad: lastContratData.data ? parseInt(lastContratData.data[0].MPOrd) + 1 : 1
      })
    }
    setLoading(false);
  }, [])

  return (
    <div className={classes.root}>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}> ¡Completado Satisfactoriamente!</Typography>
            <Button onClick={handleReset}>Volver</Button>
            {/* <VerificationCode/> */}
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div className={classes.buttons}>
              <Button
                disabled={props.disableCancelButton}
                onClick={props.handleOpenCloseUpdateInfoModal}
                className={classes.button}
              >
                Cancelar
              </Button>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Atrás
              </Button>
              {loading ?
                <Grid>
                  <CircularProgress style={{ marginTop: "25px", marginLeft: "5px" }} size={30} />
                </Grid> :
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={disableFinishButton}
                  onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                </Button>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  clinic: state.clinic,
  user: state.user,
  patients: state.patients
})

const mapDispatchToProps = {
  setPatient
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInfoSteper);