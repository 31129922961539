import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridFilterToolbarButton } from '@material-ui/data-grid';
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  makeStyles,
  TextField,
  Grid
} from '@material-ui/core';
import ShowAlert from '../../components/ShowAlert';
import IncapacitiesBottomNavigation from '../../components/staff/IncapacitiesBottomNavigation';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import red from '@material-ui/core/colors/red';
// redux
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
import useBrowserContextCommunication from 'react-window-communication-hook';
// Services
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: "20px",
    //borderRadius: '0px!important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  field: {
    width: "100%",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 160,
  },

}));

const clinicsMaping =  clinicsM.Maping();

const PresurgeryProcedureTable = (props) => {

  const classes = useStyles();
  const [title, setTitle] = useState('Procedimientos prequirúrgicos');
  const [defectColor, setDefectColor] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date - (1000 * 60 * 60 * 24)));
  const [presurgeriesLoading, setPresurgeriesLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [communicationState, postMessage] = useBrowserContextCommunication("DashboardStaffChanel");

  const columnsPresurgeries = [
    { field: 'codigo_procedimiento', headerName: 'Código', width: 110, renderCell: renderCellExpand },
    { field: 'fecha_ordenamiento', headerName: 'Fecha', width: 110, renderCell: renderCellExpand },
    { field: 'doc_paciente', headerName: 'Documento', width: 110, renderCell: renderCellExpand },
    { field: 'nombre_procedimiento', headerName: 'Nombre de procedimiento', width: 200, renderCell: renderCellExpand },
    { field: 'historia_clinica', headerName: 'Historia Clínica', width: 230, renderCell: renderCellExpand },
    { field: 'folio', headerName: 'folio', width: 110, renderCell: renderCellExpand },
    { field: 'estado', headerName: 'Estado', width: 130, renderCell: renderCellExpand },
    /* {
      field: 'Aprobar',
      headerName: 'Radicar',
      width: 120,
      renderCell: (params) => (
        <IconButton
        disabled = {title === 'Histórico de procedimientos prequirurgicos'}
          onClick={() => handleApprovalPreseurgery(params)}
        >
          {title === 'Histórico de procedimientos prequirurgicos'? "" : <CheckCircleIcon color="primary" />}
        </IconButton>
      ),
    }, */
    /* {
      field: 'Cancelar',
      headerName: 'Cancelar',
      width: 100,
      renderCell: (params) => (
        <IconButton
          //onClick={() => handleCancelAppoinments(params)}
        >
          <CancelIcon style={{ color: red[700] }} />
        </IconButton>
      ),
    }, */
  ]

  function CustomToolbar() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.textField}>
          <Grid>
            <Typography variant="subtitle2" color={defectColor ? "primary" : "error"} gutterBottom>
              Buscar por fecha

            </Typography>
          </Grid>
          <Grid>
            <KeyboardDatePicker
              variant="dialog"
              disabled={title === 'Histórico de incapacidades radicadas'}
              margin="dense"
              inputVariant="standard"
              id="date-picker-dialog"
              showTodayButton={true}
              format="dd/MM/yyyy"
              value={selectedDate}
              InputProps={{
                disableUnderline: true,
                disabled: true
              }}
              InputAdornmentProps={{ position: "start" }}
              onChange={handleDateChange}
            />

          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setDefectColor(true);
    await getAllPresurgeriesByDate(date);
    //setTimeout(() => console.log("valor nuevo"), 4000);
  };

  const getAllPresurgeriesByDate = async (selected_date) => {
    //Get data
    setPresurgeriesLoading(true);
    const resp = await staffAppointmentService.getAllPresurgeriesByDate(selected_date.toISOString(), props.clinic);
    // parse data
    console.log("fecha enhviada ", selected_date.toISOString());
    console.log("desde resp ", resp);
    const rowsToRender = resp.data.map((row, index) => {
      //const appointmentInfo = row.appointment_info_from_citapp;
      return {
        id: index,
        fecha_ordenamiento: row.fecha_ordenamiento,
        codigo_procedimiento: row.codigo_procedimiento,
        historia_clinica: row.historia_clinica,
        doc_paciente: row.doc_paciente,
        tipo_doc: row.tipo_doc,
        nombre_procedimiento: row.nombre_procedimiento,
        folio: row.folio,
        estado: row.estado
      }
    })
    console.log("PRINT DATA FROM PYTHON", rowsToRender)
    setRows(rowsToRender);
    setPresurgeriesLoading(false);
  }

  const handleApprovalPreseurgery = (params) => {
    props.handleOpenClosePresurgeryAcceptModal(params.row);
    console.log("params row ", params.row)
    //changePresurgeryStatus(params.row, "Radicado por EPS");
  }

  const changePresurgeryStatus = async (row, new_status) => {
    //Get data
    setPresurgeriesLoading(true);
    const resp = await staffAppointmentService.changePresurgeryStatus(props.clinic, row, new_status);

    await getAllPresurgeriesByDate(selectedDate);
    setPresurgeriesLoading(false);
  }

  const getAllHistorycPresurgeries = async () => {
    setPresurgeriesLoading(true);
    const allHistorycPresurgeries = await staffAppointmentService.getAllHistorycPresurgeries(props.clinic);
    setRows(allHistorycPresurgeries.data)
    setPresurgeriesLoading(false);
  }

  const handleSwitchChange = async (selected) => {
    if (selected === 'toRadic') {
      setTitle('Procedimientos prequirúrgicos');
      // set new rows data
      //setRows(rowser);
      await getAllPresurgeriesByDate(selectedDate);
    } else {
      setTitle('Histórico de procedimientos prequirurgicos');
      // set new rows data
      getAllHistorycPresurgeries();
      //setRows(priorityAppoint);
    }
  };

  const handleRowSelected = async (params) => {
    handleSeePresurgeryButton(params);
  }

  const handleSeePresurgeryButton = (params) => {
    //const ceroTramUser = params.row?.cerotram_user;
    //    props.setUserSelected(ceroTramUser);
    //props.setCitaNumSelected(params.row.cita_num);
    props.handleOpenClosePresurgeryAcceptModal(params.row);
  }


  useEffect(async () => {
    await getAllPresurgeriesByDate(selectedDate);

  }, [])

  useEffect(async () => {
    if (communicationState.lastMessage) {
      const { reloadPresurgeries } = communicationState.lastMessage
      if (reloadPresurgeries) {
        await getAllPresurgeriesByDate(selectedDate);
      }
    }
  }, [communicationState])


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        components={{
          Toolbar: CustomToolbar
        }}
        onRowClick={(params) => {
          handleRowSelected(params);
        }}
        rows={rows}
        loading={presurgeriesLoading}
        columns={columnsPresurgeries}
        pageSize={4}
      />
      {/* <IncapacitiesBottomNavigation
       handleSwitchChange={handleSwitchChange}
      /> */}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(PresurgeryProcedureTable);