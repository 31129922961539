import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
// redux
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';


// Actions
import { loginRequest, setPatient } from '../actions';

// services
import { authenticationService } from '../services/authenticationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  circle: {
    size: 60,
  },
}));

const FormLogin = (props) => {
  const classes = useStyles();
  let history = useHistory();

  const [form, setValues] = useState({
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);
  const [emailValidator, setEmailValidator] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(false);

  const handleInput = event => {
    setPasswordValidator(false);
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.name == 'email' && !regEmail.test(event.target.value)) {
      setEmailValidator(true);
    } else {
      setEmailValidator(false);
    }
    if (event.target.name == 'email') {
      setValues({
        ...form,
        [event.target.name]: event.target.value.toLowerCase()
      })
    }
    else{
      setValues({
        ...form,
        [event.target.name]: event.target.value
      })
    }

  }

  const register = () => {
    history.push("/register");
  }

  const resetpassword = () => {
    history.push("/resetpassword");
  }


  const handleSubmit = async (event) => {

    setLoading(true);
    event.preventDefault();

    if (form.email == "") {
      alert("El correo electrónico no puede estar vacío");
      setEmailValidator(true);
      setLoading(false);
    } else if (form.password == "") {
      alert("La contraseña no puede estar vacía");
      setPasswordValidator(true);
      setLoading(false);
    } else {

      const tokens = await authenticationService.manualLogin(form.email, form.password);
      if (tokens.access) {
        const resp = await authenticationService.login(form.email, null, null, props.clinic.cli_id);
        if (resp.error) {
          alert(`Ups.. No te encontramos en la ${props.clinic.name} Intenta nuevamente o registrate en esta clínica!`);

          history.push("/");
          setLoading(false);
        } else {
          props.setPatient(resp.patients);
          props.loginRequest(resp.ceroTramuser);
          if (resp.ceroTramuser.user.is_staff) {
            history.push("/staff/dashboard");
          } else {
            history.push("/dashboard");
          }
          setLoading(false);
        }
      } else {
        setEmailValidator(true);
        setPasswordValidator(true);
        alert("Correo o contraseña incorrectos");
        setLoading(false);
      }
    }


  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>

      <TextField
        error={emailValidator}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="email"
        id="email"
        label="Correo electrónico"
        name="email"
        autoComplete="email"
        helperText={emailValidator && "Correo electrónico no válido*"}
        autoFocus
        inputProps={{ style: { textTransform: "lowercase" } }}
        onChange={handleInput}
      />

      <TextField
        error={passwordValidator}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Contraseña"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={handleInput}
      />
      <Grid container>
        {loading ?
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center">
            <CircularProgress className={classes.circle} /></Grid> :
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            Ingresar
          </Button>}
      </Grid>


      <Grid container >
        <Grid item xs>
          <Link onClick={resetpassword} variant="body2">
            Olvidé mi Contraseña
          </Link>
        </Grid>
        <Grid item>
          <Link onClick={register} variant="body2">
            {"Quiero Regístrarme"}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}
const mapStateToProps = (state) => ({
  clinic: state.clinic
})

const mapDispatchToProps = {
  loginRequest,
  setPatient
};

export default connect(mapStateToProps, mapDispatchToProps)(FormLogin);