import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridFilterToolbarButton } from '@material-ui/data-grid';
import { Typography, Button, Tooltip, IconButton } from '@material-ui/core';
//icons
import { MonetizationOnRoundedIcon, PublishRoundedIcon } from '@material-ui/icons/';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import ShowAlert from '../../components/ShowAlert';
import renderCellExpand from '../../components/renderCellExpand'
import AppointmentsBottomNavigation from '../../components/staff/AppointmentsBottomNavigation';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import useBrowserContextCommunication from 'react-window-communication-hook';
// Services
import { appointmentService } from '../../services/appointmentService';
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';

const AppointmentsTable = (props) => {
  const [hasToPay, setHasToPay] = useState({ show: false, id: null, valueToPay: 0 });
  const [linkToTransferFiles, setLinkToTransferFiles] = useState("#")
  const [appointmentSelected, setAppointmentSelected] = useState(0)
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [communicationState, postMessage] = useBrowserContextCommunication("DashboardStaffChanel");
  const [rows, setRows] = useState(props.rows);
  const [rowsInitial, setRowsInitial] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
        {/* <Tooltip title="Aprovar cita">
            <IconButton
              //disabled={!hasToPay.show}
              //onClick={() => handlePay()}
            >
              <CheckCircleIcon color="primary"/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancelar cita">
            <IconButton
              //disabled={!(linkToTransferFiles !== "#")}
              //onClick={() => openTransferFileModal()}
            >
              <CancelIcon style={{ color: red[700] }} />
            </IconButton>
          </Tooltip> */}
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: 'cita_num', headerName: 'Número Cita', width: 115, renderCell: renderCellExpand },
    { field: 'appointment_date', headerName: 'Fecha', width: 115, renderCell: renderCellExpand },
    { field: 'appointment_time', headerName: 'Hora', width: 115, filterable: false, renderCell: renderCellExpand },
    {
      field: 'Paciente',
      headerName: 'Paciente',
      width: 250,
      renderCell: renderCellExpand,
      sortable: false,
      valueGetter: (params) => {
        const firstName = params.row?.cerotram_user?.user?.first_name;
        const lastName = params.row?.cerotram_user?.user?.last_name;
        const card_id = params.row?.cerotram_user?.card_id;
        return `${firstName || ''} ${lastName || ''} ${card_id || ''}`
      },
    },
    { field: 'medico', headerName: 'Médico', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'especialidad', headerName: 'Especialidad', width: 200, filterable: false, renderCell: renderCellExpand },
    { field: 'status', headerName: 'Estado', width: 150, filterable: false, renderCell: renderCellExpand },
    //{ field: 'value_to_pay', headerName: 'Cuota Moderadora', width: 100, filterable: false, renderCell: renderCellExpand },
    {
      field: 'value_to_pay',
      headerName: 'Cuota Moderadora',
      width: 250,
      renderCell: (params) => (
        <strong>
          {params.row.paid === false? <div style={{color: red[700] }} >{params.row.value_to_pay}</div>: <div style={{color: teal[500] }} >{params.row.value_to_pay}</div>}
        </strong>
      ),
    },

    
   /* 
   {params.row.paid === false? <div style="color: #000000de; ">{params.row?.cita_num}</div> : <div style="color: #000000de; ">{params.row?.cita_num}</div>}
   sortable: false,
      valueGetter: (params) => {
        const cita_num = params.row?.cita_num;
        const element = <h1 style={{ color: 'red' }}>Hello world</h1>
        return element
      },*/
   
  /* {
      field: 'Cancelar',
      headerName: 'Cancelar cita',
      width: 115,
      renderCell: (params) => (
        <Tooltip title="Cancelar cita">
            <IconButton
            disabled={params.row.estado==="AUTORIZADO"? true : false}
              onClick={() => handleCancelAppoinments(params)}
            >
              {params.row.estado==="AUTORIZADO"?  "": <CancelIcon style={{ color: red[700] }} />}
              
            </IconButton>
          </Tooltip>
      ),
    },
     {
      field: 'Aprobar',
      headerName: 'Aprobar',
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleApprovalAppoinments(params)}
        >
          <CheckCircleIcon color="primary"/>
        </IconButton>
      ),
    },
    {
      field: 'Cancelar',
      headerName: 'Cancelar',
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleCancelAppoinments(params)}
        >
          <CancelIcon style={{ color: red[700] }} />
        </IconButton>
      ),
    }, */
    /*
    {
      field: 'Contrato',
      headerName: 'Contrato',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<FindInPageIcon />}
          onClick={() => handleSeeContratctButton(params)}
        >
        Ver
      </Button>
      ),
    },
    */
  ]

  const clinicsMaping =  clinicsM.Maping();




  const handleCancelAppoinments = (params) => {

    const clinicIdCitapp = clinicsMaping[props.clinic.cli_id];
    const valueToEncrypt = "" + clinicIdCitapp + "|" + params.row.cerotram_user.card_id + `|${params.row.cerotram_user.card_id_type}|` + params.row.cita_num +
      "|Cancelacion por Citapp desde el correo|" + params.row.cerotram_user.user.username + "|" + params.row.appointment_date + "|"
      + params.row.appointment_time + "|" + params.row.paciente + "|" + params.row.medico;

    const urlCancelAppoinmentsModal = `https://portubien.com.co/citapp_user_desa_0//citas/cancelarCitaCorreo/` + encrypt(valueToEncrypt) + '/0_tram/0_staff/' + params.row.cita_num;
    console.log("Url citapp ", urlCancelAppoinmentsModal);
    props.handleOpenCloseCancelAppoinmentsModal(urlCancelAppoinmentsModal);

  }

  function encrypt(valueToEncrypt) {
    const value = btoa(valueToEncrypt);
    //console.log("Valor encriptado =", value);
    return value;
  }

  const handleRowSelected = async (params) => {
    if (props.title === "Pendientes de autorización") {
      handleSeeContratctButton(params);
      setAppointmentSelected(params);
    }
  }

  const handleCheckBox = (newSelection) => {
    const newSet = new Set(newSelection.selectionModel);
    const lastSet = new Set(selectionModel);
    let newRowselected = [...newSet].filter(x => !lastSet.has(x));
    if (newRowselected.length === 0) newRowselected = newSelection.selectionModel;
    if (newRowselected.length > 1) newRowselected = []

    const params = { row: props.rows[parseInt(newRowselected[0])] }

    if (newRowselected.length === 1) {
      //console.log("params desde checkbox ",params);
      //handleRowSelected(params)
      setAppointmentSelected(appointmentSelected);
    } else {
      //setLinkToTransferFiles("#")
      //setHasToPay({show: false})
      setAppointmentSelected(appointmentSelected);
    }
    setSelectionModel(newRowselected);
  }
//guillermo landazuri inico del modal
  const handleSeeContratctButton = (params) => {
    const ceroTramUser = params.row?.cerotram_user;
    props.setUserSelected(ceroTramUser);
    props.setCitaNumSelected(params.row.cita_num);
    props.handleOpenCloseUpdateInfoModal(ceroTramUser, params);
  }

  const handleApprovalAppoinments = async (params) => {
    props.setBackdropLoading(true);
    await staffAppointmentService.changeCeroTramAppointmentStatus(
      params.row?.cita_num,
      "aprobada",
      params.row?.cerotram_user?.id
    )
    postMessage({
      AppointmentStatusChanged: true
    })
    props.setBackdropLoading(false);
  }

  const handleSwitchChange = (selected) => {
    props.handleSwitchChange(selected);
  }
  
  const handleSearchChange = (e) => {
    if (e.target.value !== ''){
      setRows((rowsInitial) => rowsInitial.filter((row) =>
        Object.values(row).some((value) =>
        typeof value === "string" && value.toLowerCase().includes(e.target.value.toLowerCase())
      )));
    } 
    else {
      setRows(rowsInitial);
    }
  }
  
  useEffect(() => {
    setRows(props.rows);
    setRowsInitial(props.rows);
  }, [props.rows]);

 
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <div style={{ textAlign: "center", marginBottom: "20px"}}>
        <div>          
          <input type="text" placeholder="Buscar..." className="search"
          onChange={handleSearchChange} style={{ fontSize: "17px", paddingRight: "5px" }}></input>
        </div>
      </div>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        pageSize={10}
        components={{
          Toolbar: CustomToolbar
        }}
        disableColumnMenu={true}
        loading={props.appointmentLoading}
        onRowClick={(params) => {
          handleRowSelected(params);
        }}
        //checkboxSelection={true}

        hideFooterSelectedRowCount
        onSelectionModelChange={(newSelection) => {
          handleCheckBox(newSelection);
        }}
        selectionModel={selectionModel}
      />
      <AppointmentsBottomNavigation
        handleSwitchChange={handleSwitchChange}
      />
    </React.Fragment>
  );
}
export default AppointmentsTable;