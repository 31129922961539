import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
// redux
import { connect } from 'react-redux';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button'; 
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';


import estanciaPopImg from '../assets/static/clinics/estanciapopayan.jpg';
import nuestraIbagImg from '../assets/static/clinics/nuestraibague.jpg';
import nuestraCartImg from '../assets/static/clinics/nuestracartagena.jpg';
import rosalesPereImg from '../assets/static/clinics/rosalespereira.jpg';
import corazonMedImg from '../assets/static/clinics/corazonmedellin.jpg';
import antioquiaItagImg from '../assets/static/clinics/antioquiaitagui.jpg';
import antioquiaBellImg from '../assets/static/clinics/antioquiabello.jpg';
import versallesManiImg from '../assets/static/clinics/versallesmanizales.jpg';
import mercedBarrImg from '../assets/static/clinics/mercedbarranquilla.jpg';
import centralqArmeImg from '../assets/static/clinics/ccqarmenia.jpg';
import logoCuadrado from '../assets/static/logocuadrado.jpg'; 


import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


// Custom Components
import Copyright from '../components/Copyright';
import ShowAlert from '../components/ShowAlert';

// Actions
import { registerRequest } from '../actions';

// services
import { authenticationService } from '../services/authenticationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1, 'auto', 1),
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  complete: {
    background: `url(http://www.clinicantioquia.com.co/wp-content/themes/heart/images/bg_corazon.jpg)`,
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 10%',
    backgroundRepeat: 'no-repeat',
    //height: '1000%',
    width: "100%",
    minWidth: "100vh",
    height: "100%",
    minHeight: "100vh",
    backgroundSize: 'cover',
    //padding: theme.spacing(3, 9, 0),
    //borderRadius: '0px!important',
  },
  circle: {
    size: 60,
  },
}));

function SignUp(props) {
  const {format} = require('date-fns');
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [clinicValue, setClinicValue] = React.useState(clinics[0]);
  const [inputClinicValue, setInputClinicValue] = React.useState('');
  const [alert, handleAlert] = useState({ show: false, type: null, title: null, message: null, strong: null, action: null });
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const [form, setValues] = useState({
    firstName: '',
    lastName: '',
    dob: new Date(),
    cardId: '',
    email: '',
    clinic: '',
    password: '',
  });

  const handleInput = event => {
    setValues({
      ...form,
      [event.target.name]: event.target.value
    })
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    props.registerRequest(form);

    console.log("formulario",form)

    //Validate fields have content
    if(form.firstName && form.lastName && form.dob && form.cardId && form.email && form.clinic && form.password){
      try {
        const validation = await authenticationService.validationUser(form.cardId, form.clinic.clinicId);    
        if(validation.data){ 
            const response = await authenticationService.signUp(form);
            if (response.error) {
              handleAlert({
                ...alert,
                show: true,
                type: 'error',
                title: 'Ups..',
                message: response.error
              });
              setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
            } else {
              handleAlert({
                ...alert,
                show: true,
                type: 'success',
                title: 'Perfecto',
                message: response.success
              });
              setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
              history.push("/")
          }
        }else{
          handleAlert({
            ...alert,
            show: true,
            type: 'error',
            title: 'Ups..',
            message: '¡Este número de cédula no se encuentra registrado!'
          });
          setLoading(false);
          setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
        }
      } catch(error) {
        console.log("Valor del error",error)
        handleAlert({
          ...alert,
          show: true,
          type: 'error',
          title: 'Ups..',
          message: "No es posible crear el usuario, verifique la información"
        });
        setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
      }
    }else{
      
    if(!form.firstName || !form.lastName){
      handleAlert({
        ...alert,
        show: true,
        type: 'error',
        title: 'Ups..',
        message: "Por favor, ingrese un nombre válido"
      });
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
    }else if(!form.dob){
      handleAlert({
        ...alert,
        show: true,
        type: 'error',
        title: 'Ups..',
        message: "Por favor, ingrese una fecha de nacimiento válida"
      });
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
    }else if(!form.email){
      handleAlert({
        ...alert,
        show: true,
        type: 'error',
        title: 'Ups..',
        message: "Por favor, ingrese un correo electrónico válido"
      });
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
    }
    else{
      handleAlert({
        ...alert,
        show: true,
        type: 'error',
        title: 'Ups..',
        message: "Por favor, debe validar todos los campos correctamente"
      });
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
    }
    }
    
    setLoading(false);
  }

  return (
    <Container component="main" maxWidth="xl" maxHeight="xl" className={classes.complete}>
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <div className={classes.paper}>

          {alert.show ?
            <Grid>
              <ShowAlert
                {...alert}
              />
            </Grid>
            :
            <Grid>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Regístrate
              </Typography>
            </Grid>
          }


          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="Nombres"
                  autoFocus
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Apellidos"
                  name="lastName"
                  autoComplete="lname"
                  onChange={handleInput}
                />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    autoOk
                    name="dob"
                    inputVariant="outlined"
                    variant="inline"
                    id="dob" 
                    label="Fecha de nacimiento"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={date => {
                      handleDateChange(date)
                      setValues({
                        ...form,
                        "dob": date
                      })
                    }}
                    animateYearScrolling={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="cardId"
                  label="Núm de documento"
                  name="cardId"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setClinicValue(newValue);
                    console.log(newValue);
                    setValues({
                      ...form,
                      "clinic": newValue
                    })
                  }}
                  inputValue={inputClinicValue}
                  onInputChange={(event, newInputValue) => {
                    setInputClinicValue(newInputValue);
                  }}
                  id="clinics"
                  options={clinics}
                  getOptionLabel={(clinic) => `${clinic.name} - ${clinic.city}`}
                  renderInput={(params) => <TextField {...params} label="Selecciona una Clínica" variant="outlined" />}
                  name="clinic"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  onChange={handleInput}
                />
              </Grid>
            </Grid>
            {loading ?
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                className={classes.submit}>
                <CircularProgress className={classes.circle} /></Grid>
              :
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Registrarme
              </Button>
            }

            <Grid container justify="flex-end">
              <Grid item>
                <Link href="login/" variant="body2">
                  ¿Ya tienes una cuenta? Ingresar
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </Container>
  );
}

const clinics = [
 /*  { name: "HOSVITAL_NSDR_OS", city: "Cali valle", clinicId: 27 },
  { name: "Clinica Ospedale Pruebas", city: "Manizales", clinicId: 31 },
  {name: "Clínica Nuestra Pruebas", city: "Cali Valle", clinicId: 32},
  //{ name: "Clínica la Estancia", city: "Popayan Cauca", clinicId: 1 },
  { name: "Clínica la Estancia", city: "Popayan Cauca", clinicId: 3 },
  { name: "Clínica Nuestra", city: "Cali Valle", clinicId: 1 },
  { name: "Clínica Nuestra", city: "Ibagué Tolima", clinicId: 1 },
  { name: "Clínica Nuestra", city: "Cartagena Bolivar", clinicId: 1 },
  { name: "Clínica Los Rosales", city: "Pereira Risaralda", clinicId: 1 },
  { name: "Clínica Sagrado corazon", city: "Medellin Antioquia", clinicId: 1 },
  { name: "Clínica Antioquia", city: "Itagui Antioquia", clinicId: 1 },
  { name: "Clínica Antioquia", city: "Bello Antioquia", clinicId: 1 },
  { name: "Clínica Versalles", city: "Manizales Caldas", clinicId: 1 },
  { name: "Clínica La Merced", city: "Barranquilla Atlantico", clinicId: 1 },
  { name: "Clínica Central del Quindio", city: "Armenia Quindio", clinicId: 1 }, */


  {name: "HOSVITAL_NSDR_OS", city: "Cali valle",  clinicId: 27 },
  { name: "Clinica Ospedale Manizales", city: "",  clinicId: 31 },
  {name: "Los Rosales pruebas", city: "Pereira Risaralda",  clinicId: 29},
  {name: "Sagrado Corazón pruebas", city: "",  clinicId: 28},
  {name: "Clínica Antioquia", city: "Itagui Antioquia", clinicId: 14},
  {name: "Clínica Antioquia", city: "Bello Antioquia", clinicId: 14},
  {name: "Clínica Central del Quindio", city: "Armenia Quindio",  clinicId: 10},
  {name: "Sagrado Corazón", city: "",  clinicId: 27},
  {name: "Clínica Nuestra Pruebas", city: "Cali Valle",  clinicId: 32},
  //{name: "Clinica Local", city: "", image: "", clinicId: 22},
  {name: "Clínica Nuestra", city: "Cali Valle",  clinicId: 11},
  {name: "Clínica Nuestra", city: "Cartagena Bolivar",  clinicId: 9},
  {name: "Clínica Nuestra", city: "Ibagué Tolima",  clinicId: 6},
  //{name: "Clínica Los Rosales", city: "Pereira Risaralda",  clinicId: 7},
  //{name: "Clínica Rosales Pruebas", city: "", image: "", clinicId: 21},
  //{name: "Clínica Corpas", city: "Bogotá D.C", image: "", clinicId: 8},
  //{name: "Desarrollo", city: "", image: "", clinicId: 17},
  //{name: "Clínica la Estancia", city: "Popayan Cauca", clinicId: 15},
  {name: "Clínica la Estancia", city: "Popayan Cauca", clinicId: 3},
  //{name: "G-OCHO", city: "", image: "", clinicId: 18},
  {name: "G-OCHO PRUEBAS", city: "",  clinicId: 2},
  {name: "Clínica La Merced", city: "Barranquilla Atlantico",  clinicId: 16},
  {name: "La Merced Pruebas", city: "",  clinicId: 20},
  {name: "Clínica Sagrado corazon", city: "Medellin Antioquia",  clinicId: 12},
  {name: "Clínica Versalles", city: "Manizales Caldas",  clinicId: 13},
  {name: "CCQ Pruebas", city: "",  clinicId: 19}

];

const mapDispatchToProps = {
  registerRequest,
}

export default connect(null, mapDispatchToProps)(SignUp);