import React, { useState, useEffect } from 'react';
// redux
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

function GeneralInfoForm(props) {

  const [docType, setDocType] = useState('');
  const [gender, setGender] = useState('');
  const [patient, setPatient] = useState('');
  const [selectedDate, handleDateChange] = useState(new Date());



  const [state, setState] = useState('');
  const [town, setTown] = useState('');
  const [nbhd, setNbhd] = useState('');
  const [allStates, setAllStates] = useState([]);
  const [allTowns, setAllTowns] = useState([]);
  const [allNbhd, setAllNbhd] = useState([]);
  //const [form, setFormValues] = useState(props.form);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCity, setLoadingCity] = useState(false);
  const [loadingNgbh, setLoadingNgbh] = useState(false);

  const getData = async (url) => {
    const requestOptions = {
      method: 'GET',
    };
    const resp = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    return resp;
  }

  const handleStateChange = async (stateCode) => {
    /* get All Towns from clinic Id and State Code*/
    setLoadingCity(true);
    const urlTowns = process.env.REACT_APP_URL_HOST_AMS + `/api/getListMunicipios/${props.clinicId}/${stateCode}`
    const respAllTowns = await getData(urlTowns);
    setAllTowns(respAllTowns.data);
    setLoadingCity(false);
  }

  const handleTownChange = async (TownCode) => {
    /* get All Towns from clinic Id and State Code*/
    setLoadingNgbh(true);
    const urlNbhds = process.env.REACT_APP_URL_HOST_AMS + `/api/getListBarrios/${props.clinicId}/${state}/${TownCode}`
    const respAllNbhd = await getData(urlNbhds);
    setAllNbhd(respAllNbhd.data);
    setLoadingNgbh(false);
  }

  useEffect(async () => {
    //
    const pat = props.patients ? props.patients[0] : null;

    console.log('datos del post',props)

    /* get All States from clinic Id */
    setLoadingStates(true);
    const urlStates = process.env.REACT_APP_URL_HOST_AMS + `/api/getListDepartamentos/${props.clinicId}`
    const respAllStates = await getData(urlStates);
    //
    await setAllStates(respAllStates.data);
    setLoadingStates(false);
    if (pat) {
      setPatient(pat);
      setGender(pat.genero);
      setDocType(pat.tipo_documento);
      handleDateChange(new Date(pat.fecha_nacimiento));

      /* Set Departamento (state)*/
      await setState(pat.departamento)

      /* get and set All tows from clinic Id and State code (MDCodD) */
      await handleStateChange(pat.departamento);
      await setTown(pat.municipio);

      setLoadingNgbh(true);
      /* get and set All NBHD from clinic Id and State code (MDCodD) */
      const urlNbhds = process.env.REACT_APP_URL_HOST_AMS + `/api/getListBarrios/${props.clinicId}/${pat.departamento}/${pat.municipio}`
      const respAllNbhd = await getData(urlNbhds);
      //
      await setAllNbhd(respAllNbhd.data);
      await setNbhd(pat.barrio);
      setLoadingNgbh(false);
    }
  }, [props.clinicId])

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    container: {
      margin: theme.spacing(1),
    }

  }));

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  return (
    <Container component="main" maxWidth="xl" style={{ padding: '2%' }}>

      <Grid container
        direction="column"
        justify="space-between"
        alignItems="space-between"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6" gutterBottom>
            INFORMACIÓN GENERAL
          </Typography>
        </Grid>

        <Grid item>
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item >
              <TextField
                disabled={props.staff}
                required
                error={false}
                id="firstName"
                name="primer_nombre"
                label="Primer Nombre"
                value={props.form.primer_nombre}
                onChange={props.handleInput}
                autoComplete="given-name"
              />
            </Grid>


            <Grid item >
              <TextField
                disabled={props.staff}
                id="middleName"
                name="segundo_nombre"
                label="Segundo Nombre"
                //value={patient ? patient.MPNom2 : ''}
                value={props.form.segundo_nombre}
                onChange={props.handleInput}
                autoComplete="given-name2"
              />
            </Grid>

            <Grid item >
              <TextField
                disabled={props.staff}
                required
                id="lastName"
                name="primer_apellido"
                //value={patient ? patient.MPApe1 : ''}
                value={props.form.primer_apellido}
                onChange={props.handleInput}
                label="Primer Apellido"
              />
            </Grid>
            <Grid item >
              <TextField
                disabled={props.staff}
                id="lastName2"
                name="segundo_apellido"
                //value={patient ? patient.MPApe2 : ''}
                value={props.form.segundo_apellido}
                onChange={props.handleInput}
                label="Segundo Apellido"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
          >

            <Grid item >
              <FormControl className={useStyles.formControl}>
                <InputLabel id="cardIdTypeLabel" >Tipo</InputLabel>
                <Select
                  disabled={props.staff}
                  style={{ width: 180 }}
                  labelId="cardIdTypeLabel"
                  name="tipo_documento"
                  id="cardIdType"
                  value={docType}
                  onChange={(event) => {
                    setDocType(event.target.value);
                    props.handleInput(event);
                  }}
                >
                  <MenuItem value={'CC'}>CC</MenuItem>
                  <MenuItem value={'TI'}>TI</MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid item >
              <TextField
                disabled={props.staff}
                id="cardId"
                //value={patient ? patient.MPCedu : ''}
                value={props.form.documento}
                onChange={props.handleInput}
                label="Numero de Documento"
                name="documento"
              />
            </Grid>


            <Grid item >
              <TextField
                disabled={props.staff}
                required
                id="phone"
                name="telefono"
                label="Telefono"
                //value={patient ? patient.MPTele : ''}
                value={props.form.telefono}
                onChange={props.handleInput}
              />
            </Grid>


            <Grid item >
              <FormControl className={useStyles.formControl} >
                <InputLabel id="GenderLabel">Género</InputLabel>
                <Select
                  disabled={props.staff}
                  required
                  style={{ width: 180 }}
                  labelId="GenderLabel"
                  id="gender"
                  name="genero"
                  value={gender}
                  onChange={(event) => {
                    setGender(event.target.value);
                    props.handleInput(event);
                  }}
                >
                  <MenuItem value={'M'}>Masculino</MenuItem>
                  <MenuItem value={'F'}>Femenino</MenuItem>
                  <MenuItem value={'N'}>No Binario</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item >
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
          >

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item >
                <KeyboardDatePicker
                  disabled={props.staff}
                  disableFuture
                  autoOk
                  style={{ width: 180 }}
                  name="fecha_nacimiento"
                  variant="inline"
                  id="dob"
                  label="Fecha de nacimiento"
                  format="dd/MMM/yyyy"
                  value={selectedDate}
                  onChange={date => {
                    handleDateChange(date);
                    props.handleInput({
                      target: {
                        name: 'fecha_nacimiento',
                        value: isValidDate(date) ? date?.toISOString().split('T')[0] : " "
                      }
                    });
                  }}
                  animateYearScrolling={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid item >
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
          >

            <Grid item >
              <TextField
                style={{ width: 180 }}
                required
                id="address"
                label="Dirección"
                name="direccion"
                //value={patient ? patient.MPDire : ''}
                value={props.form.direccion}
                onChange={props.handleInput}
              />
            </Grid>
            <Grid item >
              <FormControl className={useStyles.formControl} >
                <InputLabel id="StateLabel">Departamento</InputLabel>
                <Select
                  style={{ width: 180 }}
                  required
                  labelId="StateLabel"
                  id="state"
                  name="departamento"
                  value={state}
                  onChange={(event) => {
                    setState(event.target.value)
                    handleStateChange(event.target.value);
                    props.handleInput(event);
                  }}
                >
                  {allStates && (allStates.map((state, index) => (
                    <MenuItem key={index} value={state.codigo_departamento}>{state.departamento}</MenuItem>
                  )))}
                </Select>
                {loadingStates && <LinearProgress />}
              </FormControl>
            </Grid>
            <Grid item >
              <FormControl className={useStyles.formControl}>
                <InputLabel id="TownLabel">Municipio</InputLabel>
                <Select
                  style={{ width: 180 }}
                  required
                  labelId="TownLabel"
                  id="town"
                  name="municipio"
                  value={town}
                  onChange={(event) => {
                    setTown(event.target.value);
                    handleTownChange(event.target.value);
                    props.handleInput(event);
                  }}
                >
                  {allTowns && (allTowns.map((town, index) => (
                    <MenuItem key={index} value={town.codigo_municipio}>{town.municipio}</MenuItem>
                  )))}
                </Select>
                {loadingCity && <LinearProgress />}
              </FormControl>
            </Grid>
            <Grid item >
              <FormControl className={useStyles.formControl}>
                <InputLabel id="BarrioLabel">Barrio</InputLabel>
                <Select
                  style={{ width: 180 }}
                  required
                  labelId="BarrioLabel"
                  id="nbhd"
                  name="barrio"
                  value={nbhd}
                  onChange={(event) => {
                    setNbhd(event.target.value);
                    props.handleInput(event);
                  }}

                >
                  {allNbhd && (allNbhd.map((nbhd, index) => (
                    <MenuItem key={index} value={nbhd.codigo_barrio}>{nbhd.barrio}</MenuItem>
                  )))}
                </Select>
                {loadingNgbh && <LinearProgress />}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}


const mapStateToProps = (state) => ({
   clinic: state.clinic,
  user: state.user,
  patients: state.patients 
})

/* const mapDispatchToProps = {
  setClinic,
} */

export default connect(mapStateToProps, null)(GeneralInfoForm);
