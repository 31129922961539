import axios from "axios";

const getAllAppointments = async (clinicId) => {
  // Parse data
  const data = JSON.stringify({
    clinic_id: clinicId,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/get/appointments/ecosystem";

  const resultData = await axios({ method: "post", url: url, data: data }).then(
    (result) => result.data
  );
  return resultData;
};

const getAllHistorycAppointments = async (clinicId) => {
  // Parse data
  const data = JSON.stringify({
    clinic_id: clinicId,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/history/appointments";

  const resultData = await axios({ method: "post", url: url, data: data }).then(
    (result) => result.data
  );
  return resultData;
};

const getAllHistorycIncapacities = async (clinic) => {
  // Parse data
  const data = JSON.stringify({
    clinic: clinic,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/historyc_incapacities";

  const resultData = await axios({ method: "post", url: url, data: data }).then(
    (result) => result.data
  );
  return resultData;
};

const cancelarcita = async (data) => {
  const baseUrl = "https://ecodigital.portubien.co/citapp_user_desa_0/";

  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  // andpoint url
  const url = baseUrl + "citas/CancelacionCitaUsarioJson";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: data,
    config,
  }).then((result) => result.data);
  return resultData;
};

const insertMedicalAppointments = async (dataFromCitapp) => {
  const baseUrl = "https://ecodigital.portubien.co/citapp_user_desa_0/";

  const decodeData = atob(dataFromCitapp);
  const dataToSend = JSON.parse(decodeData);
  var bodyFormData = new FormData();
  //Object.entries(decodeData).forEach(([k, v]) => bodyFormData.append(k, v))
  bodyFormData.append("clinica", dataToSend.clinica);
  bodyFormData.append("data", JSON.stringify(dataToSend.data));

  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  // andpoint url
  const url = baseUrl + "citas/insertCitaMedica";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: bodyFormData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const crearTeleconsulta = async (dataRic) => {
  const baseUrl = "https://ecodigital.portubien.co/appRic_desarrollo/";

  var bodyFormData = new FormData();
  bodyFormData.append("datos_teleconsulta", JSON.stringify(dataRic));

  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  // andpoint url
  const url = baseUrl + "ApiTeleconsulta/crearTeleconsulta";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: bodyFormData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const createAppointmentInCeroTram = async (data) => {
  // Parse data
  const dataToSend = JSON.stringify(data);

  // endpoint url
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/create/apointment/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getAppointmentInfo = async (cita_num) => {
  // Parse data
  const dataToSend = JSON.stringify({
    cita_num: cita_num,
  });

  // endpoint url
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/get/apointmentinfo/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const scheduleOrderedAppointmentCeroTram = async (user, hiscsec) => {
  // Parse data
  const dataToSend = JSON.stringify({
    user: user,
    hiscsec: hiscsec,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST + "/cerotram/schedule/orderedappointment/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const changeCeroTramAppointmentStatus = async (cita_num, new_status, cerotram_user_id) => {
  // Parse data
  const dataToSend = JSON.stringify({
    cita_num,
    new_status,
    cerotram_user_id
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/change/appointment/status";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const recordAppointmentStatusChanged = async (formData) => {
  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/create/appointment_status_changed";
  const resultData = await axios({
    method: "POST",
    url: url,
    data: formData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const recordAppointmentStatusChangedSanitas = async (formData) => {
  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/create/sanitas/appointment_status_changed_sanitas";
  const resultData = await axios({
    method: "POST",
    url: url,
    data: formData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const getAllPriorityPetitionAppointments = async (clinic_id) => {
  // Parse data
  const dataToSend = JSON.stringify({
    clinic_id: clinic_id,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/priority_petition_appointments";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getAllIncapacitiesByDate = async (Initial_date, clinic) => {
  // Parse data
  const dataToSend = JSON.stringify({
    clinic: clinic,
    incapacity_initial_date: Initial_date,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/all_date_incapacities";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getAllAtendidaAppointments = async (clinic) => {
  // Parse data
  const dataToSend = JSON.stringify({
    clinic: clinic,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/all/atendida/appointments";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getAllPresurgeriesByDate = async (Initial_date, clinic) => {
  // Parse data
  const dataToSend = JSON.stringify({
    clinic: clinic,
    presurgery_initial_date: Initial_date,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/all/presurgeries_by_date";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

/* const changeIncapacityStatus = async (clinic, incapacity, status_changed_to, description) => {
  // Parse data
  const dataToSend = JSON.stringify({
    "clinic": clinic,
    "incapacity": incapacity,
    "status_changed_to": status_changed_to,
    "description": description
  });

  // endpoint url
  const url =  process.env.REACT_APP_URL_HOST +  "/cerotram/api/staff/get/change_incapacity_status";

  const resultData = await axios({ method: 'POST', url: url, data: dataToSend }).then(result => result.data)
  return resultData
} */

const changeIncapacityStatus = async (
  clinic,
  incapacity,
  status_changed_to,
  description,
  formDataDocument
) => {
  // Parse data
  const dataToSend = JSON.stringify({
    clinic: clinic,
    incapacity: incapacity,
    status_changed_to: status_changed_to,
    description: description,
  });
  const formData = new FormData();
  formData.append("json", dataToSend);
  formData.append("file", formDataDocument);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/change_incapacity_status";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: formData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const changePresurgeryStatus = async (
  clinic,
  presurgery,
  status_changed_to,
  description
) => {
  // Parse data
  const dataToSend = JSON.stringify({
    clinic: clinic,
    presurgery: presurgery,
    status_changed_to: status_changed_to,
    description: description,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/get/change_presurgery_status";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const viewDocumentIncapacity = async (clinic_id, card_id, folio, tipo) => {
  const baseUrl = "https://ecodigital.portubien.co/appRic_desarrollo/";

  var bodyFormData = new FormData();
  bodyFormData.append("clinica", clinic_id);
  bodyFormData.append("cc", card_id);
  bodyFormData.append("folio", folio);
  bodyFormData.append("tipo", tipo);
  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // andpoint url
  const url = baseUrl + "OMedicas/view_incapacidad_0_tram";
  const resultData = await axios({
    responseType: "arraybuffer",
    method: "POST",
    url: url,
    data: bodyFormData,
    config,
  }).then((result) => result);
  return resultData;
};

const notifyToCardId = async (card_id, card_id_type, title, message, extra) => {
  const dataToSend = JSON.stringify({
    card_id: card_id,
    card_id_type: card_id_type,
    title: title,
    message: message,
    extra: extra,
  });

  const url =
    process.env.REACT_APP_URL_HOST + "/cerotram/api/send/notificationApp/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result);
  return resultData;
};

const changePriorityPetitionAppointmentStatus = async (
  priorityPetitionApointmentId,
  new_status,
  staff_description
) => {
  // Parse data
  const dataToSend = JSON.stringify({
    priorityPetitionApointmentId,
    new_status,
    staff_description,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/change_priority_petition_appointment_status";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const sanitasEndpoint = async (card_id_type, card_id) => {
  // Parse data
  // endpoint url
  //const url = process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/user_contract?document=38796146&document_type=CC";
  //const url = process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/user_contract?document="+card_id+"&document_type="+card_id_type;
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/staff/user_contract?document=" +
    card_id +
    "&document_type=" +
    card_id_type;
  const resultData = await axios({ method: "GET", url: url }).then(
    (result) => result.data
  );
  return resultData;
};

const rightsValidatorSanitasEndpoint = async (card_id_type, card_id) => {
    // endpoint url
  //const url = process.env.REACT_APP_PROD_URL_HOST + "/rcf/cerotram/sanitas_http/rights_validator/?identification_number=" + card_id + "&identification_type=" + card_id_type;
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/sanitas_http/rights_validator/?identification_number=" + card_id + "&identification_type=" + card_id_type;
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );  
  return resultData;  
};

const rightsValidatorSaludTotalEndpoint = async (card_id_type, card_id) => {
  // endpoint url
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/saludtotal_http/rights_validator/?identification_number=94324117&identification_type=CC";
  //const url = process.env.REACT_APP_URL_HOST + "/cerotram/saludtotal_http/rights_validator/?identification_number=" + card_id + "&identification_type=CC";
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );  
  return resultData;  
};

const rightsValidatorCompensarEndpoint = async (card_id_type, card_id) => {
  // endpoint url
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/compensar_http/consultar_afiliado/?identification_number=" + card_id + "&identification_type=" + card_id_type;
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );  
  return resultData;
};

const epsRightsValidatorEndpoint = async (card_id_type, card_id, url) => {
  const dataToSend = {
    username: "validador",
    password: "UN7V9pq6tXSmF7y"
  };
  const urlBearerToken = "https://ecodigital.portubien.co/rcf/cerotram/api/token/";
  const resultBearerToken = await axios({ method: "POST", url: urlBearerToken, data: dataToSend,}).then(
    (result) => result.data
  );

  const urlEndpoint = url + "/?identification_number=" + card_id + "&identification_type=" + card_id_type;
  const resultData = await axios({ method: "GET", url: urlEndpoint, headers: { 
    'Authorization': `Bearer ${resultBearerToken.access}`
  }}).then((result) => result.data);
  return resultData;  
}

const consultaNitEpsEndpoint = async (clinic_id, contrato) => {
  const dataToSend = JSON.stringify({
    id: clinic_id,
    token: "6bd8321047138711d5716095193e444bb5c1069f",
    contrato: contrato,
  });
  // endpoint url
  const url = "https://ecodigital.portubien.co/apiws/restserver/ConsultarNitEps";
  const resultData = await axios({ method: "POST", url: url, data: dataToSend,}).then(
    (result) => result
  );  
  return resultData;  
};

const getUrlValidadorDerechos = async (nit) => {
  const dataToSend = JSON.stringify({
    nit: nit,
    bandera_local: 1
  });
  const url = "https://ecodigital.portubien.co/apiws/restserver/UrlValidacion";
  const resultData = await axios({ method: "POST", url: url, data: dataToSend,}).then(
    (result) => result
  );  
  return resultData;  
};

const getWaitingList = async (clinic_id) => {
  // endpoint url
  const url = "https://ecodigital.portubien.co/appric/Cron/getListaEsperaPorClinica/" + clinic_id
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );    
  return resultData; 
};

const updateWaitingList = async (estado, id) => {
  const dataToSend = JSON.stringify({
    estado: estado,
    id: id
  });
  const url = "https://ecodigital.portubien.co/appric/cron/actualizarEstadoRegistro";
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const getBancaryTransferFile = async (cita_num) => {
  // Parse data
  const dataToSend = JSON.stringify({
    cita_num: cita_num,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST + "/cerotram/api/get/bancary/transfer/file/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const authCompensarList = async () => {
  const newDate = new Date()
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const url = "https://ecodigital.portubien.co/compensar/public/api/consultar_paciente/" + date + '-' + month + '-' + year
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );
  return resultData['ConsultarAfiliadoOut']['DatosBasicos'];
}

const authProgramCompensar = async (identification_number, identification_type) => {
  const url = "https://ecodigital.portubien.co/compensar/public/api/consultar_afiliado/" + identification_number + "/" + identification_type
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );
  console.log("authProgram:", resultData)
  return resultData['Programa'][0]['PlanAutorizacion'];
}

const numberAuthorizationCompensar = async (dob, identification_type, identification_number, cups_code, phone, identification_med, program, appointment) => {
  const url = "https://ecodigital.portubien.co/compensar/public/api/autorizacion/" + dob + "/" + identification_type + "/" + identification_number + "/" + cups_code + "/" + phone + "/" + identification_med + "/" + program + "/" + appointment
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );
  
  const position = resultData.search("<NumeroAutorizacion>")
  if (position !== -1){
    const crop = resultData.substring(position+20, position+35);
    return crop;
  }
  return false;
}

const AppointmentMessageCompensar = async (documento, idxTipoDocumento, authorization, authProgram) => {
  const url = "https://ecodigital.portubien.co/compensar/public/api/cita_cumplida/" + documento + "/" + idxTipoDocumento + "/" + authorization + "/" + authProgram
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data
  );
  const position = resultData.search("<Mensaje>")
  const positionFinal = resultData.search("</Mensaje>")
  const crop = resultData.substring(position+9, positionFinal);

  return crop;
}

const getAllSteps = async (clinic_id) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/getListaCondicionalesClinica/" + clinic_id
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data);  
  return resultData;
}

const getAllStepsEspecialidades = async (clinic_id) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/getListaCondicionalesEspecialidadesClinica/" + clinic_id
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data);  
  return resultData;
}

const getAllStepsEspecialistas = async (clinic_id) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/getListaCondicionalesEspecialistasClinica/" + clinic_id
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data);  
  return resultData;
}

const updateStep = async (step_id, campo_verificar, step_condicional, activo) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/actualizarInformacionRegistro"
  const dataToSend = JSON.stringify({
    id: step_id,
    campo_verificar: campo_verificar,
    step_condicional: step_condicional,
    activo: activo
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const updateStepEspecialidad = async (step_id, especialidad_validar, eps_condicional, mensaje_condicion, activo, bot, mensaje_informativo) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/actualizarInformacionRegistroEspecialidades"
  const dataToSend = JSON.stringify({
    id: step_id,
    especialidad_validar: especialidad_validar,
    eps_condicional: eps_condicional,
    mensaje_condicion: mensaje_condicion,
    activo: activo,
    visualizar_bot: bot,
    mensaje_informativo: mensaje_informativo
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const updateStepEspecialistas = async (step_id, especialista_validar, eps_condicional, especialidad_validar, mensaje_condicion, activo, bot, mensaje_informativo) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/actualizarInformacionRegistroEspecialistas"
  const dataToSend = JSON.stringify({
    id: step_id,
    especialista_validar: especialista_validar,
    eps_condicional: eps_condicional,
    especialidad_validar: especialidad_validar,
    mensaje_condicion: mensaje_condicion,
    activo: activo,
    visualizar_bot: bot,
    mensaje_informativo: mensaje_informativo
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const createStep = async (clinica_id, campo_verificar, step_condicional) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/insertarRegistroCondicionales"
  const dataToSend = JSON.stringify({
    clinica_id: clinica_id,
    campo_verificar: campo_verificar,
    step_condicional: step_condicional,
    activo: 0
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const createStepEspecialidad = async (clinica_id, especialidad_validar, eps_condicional, mensaje_condicion, bot, mensaje_informativo) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/insertarRegistroCondicionalesEspecialidad"
  const dataToSend = JSON.stringify({
    clinica_id: clinica_id,
    especialidad_validar: especialidad_validar,
    eps_condicional: eps_condicional,
    mensaje_condicion: mensaje_condicion,
    visualizar_bot: bot,
    activo: 0,
    mensaje_informativo: mensaje_informativo
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const createStepEspecialista = async (clinica_id, especialidad_validar, eps_condicional, especialista_validar, mensaje_condicion, bot, mensaje_informativo) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/insertarRegistroCondicionalesEspecialista"
  const dataToSend = JSON.stringify({
    clinica_id: clinica_id,
    especialidad_validar: especialidad_validar,
    eps_condicional: eps_condicional,
    especialista_validar: especialista_validar,
    mensaje_condicion: mensaje_condicion,
    visualizar_bot: bot,
    activo: 0,
    mensaje_informativo: mensaje_informativo
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const getEpsHomologada = async (clinica_id) => {
  const url = "https://ecodigital.portubien.co/apiws/restserver/ConsultarEps"
  const dataToSend = JSON.stringify({
    id: clinica_id,
    token: '6bd8321047138711d5716095193e444bb5c1069f'
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const getEspecialidadesHomologada = async (clinica_id) => {
  const url = "https://ecodigital.portubien.co/apiws/consulta_externa/consultarEspecialidadesActivas"
  const dataToSend = JSON.stringify({
    id: clinica_id,
    token: '6bd8321047138711d5716095193e444bb5c1069f',
    nombreEspecialidad: '',
    codigoEspecialidad: 0
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const getEspecialistasHomologada = async (clinica_id, cod_especialidad) => {
  const url = "https://ecodigital.portubien.co/apiws/restserver/getMedicosExepciones"
  const dataToSend = JSON.stringify({
    id: clinica_id,
    token: '6bd8321047138711d5716095193e444bb5c1069f',
    codesp: cod_especialidad,
    cod_sede: '001'
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

const getInformacionRecordatorios = async (clinic_id) => {
  const url = "https://ecodigital.portubien.co/appric/step_condicionales/getInformacionRecordatorios/" + clinic_id
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data);  
  return resultData;
}

const getReporteDemandaInsatisfecha = async (clinic_id, start_date, end_date) => {
  const url = "https://ecodigital.portubien.co/appric/Cron/getReporteDemandaInsatisfecha/" + clinic_id + "/" + start_date + "/" + end_date 
  const resultData = await axios({ method: "GET", url: url}).then(
    (result) => result.data);  
  return resultData;
}

const updateAppointment = async (formData) => {
  // axios config
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/update/appointment";
  const resultData = await axios({
    method: "POST",
    url: url,
    data: formData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const getAllCrons = async (clinic_id) => {
    const url = "https://ecodigital.portubien.co/apiws/restserver/obtener_datos_cron"
    const dataToSend = JSON.stringify({
        id_clinica: clinic_id,
        token: '6bd8321047138711d5716095193e444bb5c1069f'
    });
    const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
        (result) => result.data
    );  
    return resultData;
}

const updateCron = async (id_clinica, flag, estado) => {
  const url = "https://ecodigital.portubien.co/apiws/restserver/actualizar_datos_cron"
  const dataToSend = JSON.stringify({
    id_clinica: id_clinica,
    flag: flag,
    estado: estado,
    token: '6bd8321047138711d5716095193e444bb5c1069f'
  });
  const resultData = await axios({ method: "POST", url: url, data: dataToSend}).then(
    (result) => result.data
  );  
  return resultData;
}

export const staffAppointmentService = {
    getAllHistorycAppointments,
    cancelarcita,
    getAllAppointments,
    insertMedicalAppointments,
    crearTeleconsulta,
    createAppointmentInCeroTram,
    getAppointmentInfo,
    scheduleOrderedAppointmentCeroTram,
    changeCeroTramAppointmentStatus,
    recordAppointmentStatusChanged,
    recordAppointmentStatusChangedSanitas,
    getAllPriorityPetitionAppointments,
    changePriorityPetitionAppointmentStatus,
    getAllIncapacitiesByDate,
    changeIncapacityStatus,
    getAllHistorycIncapacities,
    viewDocumentIncapacity,
    getAllPresurgeriesByDate,
    changePresurgeryStatus,
    sanitasEndpoint,
    getAllAtendidaAppointments,
    notifyToCardId,
    getBancaryTransferFile,
    rightsValidatorSanitasEndpoint,
    rightsValidatorSaludTotalEndpoint,  
    rightsValidatorCompensarEndpoint,
    consultaNitEpsEndpoint,
    getUrlValidadorDerechos,
    epsRightsValidatorEndpoint,
    getWaitingList,
    authCompensarList,
    updateWaitingList,
    authProgramCompensar,
    numberAuthorizationCompensar,
    AppointmentMessageCompensar,
    getAllSteps,
    getAllStepsEspecialidades,
    getAllStepsEspecialistas,
    updateStep,
    updateStepEspecialidad,
    updateStepEspecialistas,
    createStep,
    createStepEspecialidad,
    createStepEspecialista,
    getEpsHomologada,
    getInformacionRecordatorios,
    getReporteDemandaInsatisfecha,
    updateAppointment,
    getAllCrons,
    updateCron,
    getEspecialidadesHomologada,
    getEspecialistasHomologada
};
