import Webcam from "react-webcam";
import React, { useRef } from 'react';

const FaceRegister = (props) => {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    function drawImge() {
        const video = webcamRef.current;
        const canvas = canvasRef.current;
        if (video && canvas) {
            var ctx = canvas.getContext('2d');

            canvas.width = video.video.videoWidth;
            canvas.height = video.video.videoHeight;

            // We want also the canvas to display de image mirrored
            ctx.translate(canvas.width, 0);
            ctx.scale(-1, 1);
            ctx.drawImage(video.video, 0, 0, canvas.width, canvas.height);
            ctx.scale(-1, 1);
            ctx.translate(-canvas.width, 0);

            ctx.font = "250px Arial";
            ctx.textAlign = "center";
            ctx.fillText("1", canvas.width/2, canvas.height/2);
            setTimeout(drawImge, 33);
        }
    }
    setTimeout(drawImge, 33);
    return (
        <>
            <Webcam
                ref={webcamRef}
                mirrored
                style={{width: "0%", height: "0%"}}
                videoConstraints={{ width: 1280, height: 720, facingMode: "user"}}
            />
            <canvas ref={canvasRef} style={{ width: "90%", height: "90%" }} />
        </>
    )
}

export default FaceRegister;