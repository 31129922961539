import React, { useState, useEffect } from 'react';
// redux
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


const VerificationCode = (props) => {

    const [sendCode, setSendCode] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        handleSendCode()
    }, []);

    const handleSendCode = async () => {
        setLoading(true);
        const body = JSON.stringify(props.user);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body
        }; 

        fetch(process.env.REACT_APP_URL_HOST + '/cerotram/send/code/verification/', requestOptions)
            .then(resp => resp.json())
            .then(data => {
                if (data.verificationCode) {
                    setLoading(false);
                    setSendCode(true);
                    props.handleAlert({
                        ...alert,
                        show: true,
                        type: "success",
                        strong: "Revisa tu correo ¡El código se envió correctamente!"
                    })
                    setTimeout(() => props.handleAlert({ ...alert, show: false }), 10000)
                } else {
                    alert("Ups.. No pudimos enviar el codigo. Intenta nuevamente")
                    setLoading(false);
                    setSendCode(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                setSendCode(false);
            })
        /* setTimeout(() => {
            setLoading(false);
            setSendCode(true);
        }, 5000); */
    }


    return (
        <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="h6" gutterBottom >
                SOLICITAR CÓDIGO DE VERIFICACIÓN
            </Typography>
            <Grid container spacing={3} direction="column" justify="center" alignItems="center">
                <Grid>
                    <Typography variant="body1" align='center' style={{ marginTop: "20px" }}>
                        Para verificar su identidad, le hemos enviado un código de cuatro dígitos a <br></br><strong>{props.user.email}</strong>
                    </Typography>
                </Grid>
                <Grid item >
                    <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="space-between"
                        alignItems="center">

                        <Grid item >
                            {loading ?
                                <CircularProgress size={40} />
                                :
                                <TextField
                                    id="verifiCode"
                                    name="verification_code"
                                    label="Escriba el codigo aquí"
                                    onChange={props.handleVerificationCodeInput}
                                    variant="outlined"
                                />
                            }

                        </Grid>
                        <Grid item >
                            <Button
                                disabled={!props.disableFinishButton}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => handleSendCode()}>
                                Reenviar Código
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </Grid>
    )
}


/* const mapStateToProps = (state) =>({
    clinic: state.clinic,
    user: state.user,
    patients: state.patients
  })
  
  const mapDispatchToProps = {
    setPatient
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(UpdateInfoSteper); */

export default VerificationCode;