import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";

import ShowAlert from "../../components/ShowAlert";

import { makeStyles } from "@material-ui/core/styles";
// redux
import { connect } from "react-redux";

import { staffAppointmentService } from "../../services/staff/staffAppointmentService";
import { appointmentService } from "../../services/appointmentService";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    heroContent: {
        backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
        //backgroundImage: `url(${Background})`,
        backgroundPosition: "center 0%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: theme.spacing(14, 0, 0.6),
        paddingBottom: "20px",
        //borderRadius: '0px!important',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
    },
    field: {
        width: "100%",
        marginTop: "15px",
    },
    paper: {
        width: "90%",
        height: "100%",
        marginTop: "10px",
        marginBottom: "17%",
        paddingTop: "20px",
        paddingBottom: "10px",
        paddingRight: "20px",
    },
}));

function ContractInfoForm(props) {
    const classes = useStyles();
    const idTeleconsulta = props.selectedValueRowTable?.row?.tele_consult_id;
    const [regimen, setRegimen] = useState(props.regimen);
    const [eps, setEps] = useState(props.eps);
    const [service, setService] = useState("Servicio de prueba 1");
    const [allServices, setAllServices] = useState([
        "Servicio de prueba 1",
        "Servicio de prueba 2",
    ]);
    const [level, setLevel] = useState(props.level);
    const [status, setStatus] = useState("PENDIENTE");
    const [contratType, setContratType] = useState("CONTRATO DE PRUEBA");
    const [patientType, setPatientType] = useState("PGP");
    const [allEps, setAllEps] = useState(props.allEps);
    const [allContratType, setAllContratType] = useState([
        "CONTRATO DE PRUEBA",
        "OTRO MÁS",
        "Y OTRO MÁS",
    ]);
    const [allPatientType, setAllPatientType] = useState(["PGP", "EVENTO"]);
    const [allRegimen, setAllRegimen] = useState(props.allRegimen);
    const [allLevels, setAllLevels] = useState(props.allLevels);
    const [allStatus, setAllStatus] = useState([
        "PENDIENTE",
        "AUTORIZADO",
        "NO AUTORIZADO",
    ]);
    const [showreason, setShowreason] = useState(false);
    const [motivo, setMotivo] = useState("NO TIENE CONVENIO");
    const [allMotivos, setallMotivos] = useState([
        "NO TIENE CONVENIO",
        "PACIENTE NO CONFIRMA",
    ]);
    const [loadingEps, setLoadingEps] = useState(false);
    const [loadingRegimen, setLoadingRegimen] = useState(false);
    const [loadingLevel, setLoadingLevel] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingMotivo, setloadingMotivo] = useState(false);
    const [description, setDescription] = useState("");
    const [estado, setEstado] = useState("");
    const [hasPayment, setHasPayment] = useState("");
    const [mostarEstado, setMostarEstado] = useState("");
    const [alert, handleAlert] = useState({
        show: false,
        type: null,
        title: null,
        message: null,
        strong: null,
        action: null,
    });
    const [urlFileTransfer, setUrlFileTransfer] = useState("");
    const [urlAuthorizationFile, setUrlAuthorizationFile] = useState("");

    const [notifyLoading, setNotifyLoading] = useState(false);
    const [notificationForm, setNotificationForm] = useState({
        title: "",
        message: "",
    });
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        //guillermo landazuri pruebas
        // AuthorizationFile();
        // getPayStatusAppointment(props.selectedValueRowTable?.row?.tele_consult_id);
        // console.log(
        //   "props.selectedValueRowTable?.row?",
        //   props.selectedValueRowTable.row
        // );
        console.log("props", props)

        const fetchData = async () => {
        await getRightsValidator(props.user.card_id, props.user.card_id_type, props.eps, props.clinic.cli_id);
        await AuthorizationFile();
        await getPayStatusAppointment(props.selectedValueRowTable?.row?.tele_consult_id);
        };

        fetchData();

        if (props.submitLoading) {
        validacion();
        }
    }, [props.selectedValueRowTable.row.tele_consult_id, props.submitLoading]);

    const getPayStatusAppointment = async (cita_num) => {
        try{
        const infoPago = await appointmentService.getPayStatusAppointment(cita_num);
        setHasPayment(infoPago?.estado);
        console.log("infoPago", infoPago?.estado);
        } catch (error) {
        // Manejo de errores
        console.error("Error al obtener el estado de pago de la cita:", error);
        }
    };

    const AuthorizationFile = async () => {
        try {
        const authorization = await appointmentService.getAuthorization(
            props?.selectedValueRowTable?.row?.cerotram_user?.id,
            props.selectedValueRowTable?.row?.cita_num
        );
        if (authorization.length > 0) {
            setUrlAuthorizationFile(authorization[0].documento);
        } else {
            setUrlAuthorizationFile("");
        }
        } catch (error) {
        // Manejo de errores
        console.error("Error al obtener la autorización:", error);
        }
    };

    const getRightsValidator = async (card_id, card_id_type, contrato, clinic_id) => {
        try {
        const nitEps = await staffAppointmentService.consultaNitEpsEndpoint(clinic_id, contrato);

        try {
            const urlValidadorDerechos = await staffAppointmentService.getUrlValidadorDerechos(nitEps.data[0].nit);

            if (urlValidadorDerechos.data.status) {
            try{  
                const ValidadorDerechos = await staffAppointmentService.epsRightsValidatorEndpoint(card_id_type, card_id, urlValidadorDerechos.data.url_local);
                handleChange(ValidadorDerechos, urlValidadorDerechos.data.eps);
            } catch {
                console.log("No se pudo obtener el validador de derechos del paciente")
            }
            }
        } catch {
            console.log("No se pudo obtener información del validador de derechos.")
        }
        } catch {
        console.log("No se pudo obtener la consulta nit eps.")
        } 
    }

    const handleChange = (data, eps) => {
        // Envio de datos al padre
        const matchingRegimen = allRegimen.find(regimenKey => regimenKey.codigo_regimen === regimen);
        const matchingLevels = allLevels.find(levelKey => levelKey.codigo_tipo === level);

        props.handleDataFromChild(data, eps, matchingRegimen.nombre_regimen, matchingLevels.tipo_afiliado);
    };

    const ShowDocument = () => {
        window.open(urlAuthorizationFile);
    };

    //Dialog Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleUrlFileTransfer = () => {
        window.open(process.env.REACT_APP_URL_HOST + urlFileTransfer);
    };

    const handleNotifyTextInput = (event) => {
        setNotificationForm({
        ...notificationForm,
        [event.target.name]: event.target.value,
        });
    };

    const handleOpenDialogForm = async (action) => {
        const fileTransfer = await staffAppointmentService.getBancaryTransferFile(
        props.selectedValueRowTable?.row?.cita_num
        );
        if (fileTransfer?.data?.bancary_transfer_file) {
        setUrlFileTransfer(fileTransfer?.data?.bancary_transfer_file);
        console.log("fileTransfer", fileTransfer?.data?.bancary_transfer_file);
        } else {
        setUrlFileTransfer("");
        }
        console.log("datos action", action);

        if (hasPayment === "Pendiente de pago" || hasPayment === "Pendiente Pago") {
        setNotificationForm({
            ...notificationForm,
            title: "Recordatorio de pago",
            message: `Aún no registramos el pago de cuota moderadora para su cita de ${props.selectedValueRowTable.row.info_dataRic.especialidad}, recuerde realizarlo lo antes posible`,
        });
        }
        if (hasPayment === "Pagado") {
        setNotificationForm({
            ...notificationForm,
            title: "Pago verificado",
            message: `Hemos registrado con éxito el pago de su cuota moderadora para la cita de ${props.selectedValueRowTable.row.info_dataRic.especialidad}`,
        });
        }
        setOpen(true);
    };

    const handleNotify = async () => {
        const cardId = props.selectedValueRowTable.row.cerotram_user.card_id;
        const extra = null;
        try {
        setNotifyLoading(true);
        const resp = await staffAppointmentService.notifyToCardId(
            cardId.trim(),
            "CC",
            notificationForm.title,
            notificationForm.message,
            extra
        );
        console.log("respuesta", resp);
        setNotifyLoading(false);
        handleClose(); //close alert dialog
        handleAlert({
            ...alert,
            show: true,
            type: "success",
            message: ``,
            strong: "Notificación enviada correctamente",
        });
        setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
        } catch (error) {
        handleAlert({
            ...alert,
            show: true,
            type: "error",
            message: ``,
            strong: "Error enviando notificación, inténtelo nuevamente",
        });
        setTimeout(() => handleAlert({ ...alert, show: false }), 5000);

        console.log(error);
        setNotifyLoading(false);
        }
    }; //end Dialog functions

    const getData = async (url) => {
        console.log("PROPS FROM FORM CONTRATO: ", props);
        const requestOptions = {
        method: "GET",
        };
        const resp = await fetch(url, requestOptions).then((resp) => resp.json());
        return resp;
    };

    const handleEPSChange = async (contract) => {
        /* Get All regiments for clinic id and number contract */
        setLoadingRegimen(true);
        const urlRegimens =
        process.env.REACT_APP_URL_HOST_AMS +
        `/api/getListRegimenes/${props.clinic.cli_id}/${contract}`;
        const respAllRegimens = await getData(urlRegimens);
        setAllRegimen(respAllRegimens.data);
        setLoadingRegimen(false);
    };

    const handleRegimenChange = async (codigo_regimen) => {
        /* Get All levels for clinic id and number contract */
        setLoadingLevel(true);
        const contract = eps;
        const urlLevels =
        process.env.REACT_APP_URL_HOST_AMS +
        `/api/getListNivelesUsuario/${props.clinic.cli_id}/${contract}/${codigo_regimen}`;
        const respAllLevels = await getData(urlLevels);
        setAllLevels(respAllLevels.data);
        setLoadingLevel(false);
    };

    const getInfoifSanitas = async () => {
        //setAppointmentLoading(true);
        const respSanitas = await staffAppointmentService.sanitasEndpoint(
        props.user?.card_id_type,
        props.user.card_id
        );

        if (respSanitas?.role_user[1]?.value) {
        if (respSanitas?.status_user.trim() === "VIGENTE") {
            console.log("Vigecia de sanitas", respSanitas?.status_user.trim());
            setStatus("AUTORIZADO");
        } else {
        }
        }
    };

    const validacion = async () =>{
        if(props.level !== level){
        let message = `Se actualizo correctamente el Level  de su cita ${props.selectedValueRowTable.row.info_dataRic.especialidad}`
        notify(message);
        } 
        else if( props.regimen !== regimen){
        let message = `Se actualizo correctamente el Regimen  de su cita ${props.selectedValueRowTable.row.info_dataRic.especialidad}`
        notify(message);
        }
        else if(props.eps !== eps){
        let message = `Se actualizo correctamente la EPS  de su cita ${props.selectedValueRowTable.row.info_dataRic.especialidad}`
        notify(message);
        }
        else if(status != "PENDIENTE"){
        let message = `Se actualizo correctamente el Estado  de su cita ${props.selectedValueRowTable.row.info_dataRic.especialidad}`
        notify(message);
        }
        else if(description != ""){
        let message = `Se actualizo correctamente la descripcion de su cita ${props.selectedValueRowTable.row.info_dataRic.especialidad}`
        notify(message);
        }
        else {
        let message = `Se actualizo correctamente la información del contrato de su cita ${props.selectedValueRowTable.row.info_dataRic.especialidad}`
        notify(message);
        }
    }

    const notify = async (message) => {
        const cardId = props.selectedValueRowTable.row.cerotram_user.card_id;
        const extra = null;
        try {
        setNotifyLoading(true);
        
        const resp = await staffAppointmentService.notifyToCardId(
            cardId.trim(),
            "CC",
            "Actualización",
            message,
            extra
        );
        console.log("respuesta", resp);
        
        } catch (error) {
        console.log(error);
        
        }
    };

    const verMOtivo = (motivo) => {
        //console.log(`Option selected:`, motivo);

        console.log(
        "Datos del paciente:",
        props.selectedValueRowTable.row.info_dataRic.telefono_ws
        );
        if (motivo === "NO AUTORIZADO") {
        setShowreason(true);
        } else {
        setShowreason(false);
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <Grid container spacing={2}>
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                item xs={6}
                >
                <div style={{ width: "100%", marginBottom: "5px" }}>
                    {" "}
                    {alert.show && <ShowAlert {...alert} />}
                </div>
                    <Grid item>
                    <Typography variant="h6" gutterBottom color="primary">
                        INFORMACIÓN PACIENTE Y CITA
                    </Typography>
                    </Grid>
                    <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    spacing={1}
                    >
                    <Grid item>
                        <Typography component="h5">
                        <strong>Nombre paciente:</strong> {props.user?.user?.first_name}{" "}
                        {props.user?.user?.last_name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Identificación:</strong> {props.user?.card_id}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Tipo identificación:</strong> {props.user?.card_id_type}
                        </Typography>
                    </Grid>          
                    <Grid item>
                        <Typography component="h5">
                        <strong>Teléfono:</strong>{" "}
                        {props?.selectedValueRowTable?.row?.info_dataRic?.telefono_ws}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Número cita:</strong>{" "}
                        {props?.selectedValueRowTable?.row?.cita_num}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Lugar cita:</strong>{" "}
                        {props?.selectedValueRowTable?.row?.info_dataRic.cit_lugar}
                        </Typography>
                    </Grid>          
                    <Grid item>
                        <Typography component="h5">
                        <strong>Especialidad:</strong>{" "}
                        {props?.selectedValueRowTable?.row?.info_dataRic?.especialidad}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Fecha programación:</strong>{" "}
                        {props?.selectedValueRowTable?.row?.info_dataRic?.fecha}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Hora programación:</strong>{" "}
                        {props?.selectedValueRowTable?.row?.info_dataRic?.hora}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Correo electrónico:</strong> {props.selectedValueRowTable?.row?.info_dataRic?.mail}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h5">
                        <strong>Nombre médico:</strong>{" "}
                        {props?.selectedValueRowTable?.row?.info_dataRic?.nombre_medico}
                        </Typography>
                    </Grid>

                    {urlAuthorizationFile != "" ? (
                        <Grid item>
                        <Typography component="h5">
                            <strong>Autorización: </strong>
                            <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={ShowDocument}
                            >
                            Ver Documento
                            </Button>
                        </Typography>
                        </Grid>
                    ) : (
                        <Grid item></Grid>
                    )}
                    {props.mostarEstado != "Reservada" ? (
                        <Grid item>
                        <Typography component="h5">
                            <strong>Estado:</strong> {props.mostarEstado}
                        </Typography>
                        </Grid>
                    ) : (
                        <Grid item></Grid>
                    )}
                    </Grid>
                    <Grid item style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <Divider />
                    </Grid>
                </Grid>

                {props.mostarEstado == "Reservada" ? (
                <Grid 
                container 
                spacing={1}
                item xs={6}
                >                    
                    <Grid item>
                    <Typography variant="h6" gutterBottom color="primary">
                        DATOS DE CONTRATO
                    </Typography>
                    </Grid>
                    <Grid item>
                    {props.selectedValueRowTable?.row?.value_to_pay > 0 ? (
                        <Grid>
                        <Typography component="h6" gutterBottom>
                            <strong>ESTADO DE PAGO: {hasPayment}</strong>
                        </Typography>
                        <Typography component="h6" gutterBottom>
                            <strong>
                            CUOTA: ${props.selectedValueRowTable?.row?.value_to_pay}
                            </strong>
                            {hasPayment != "Pendiente Pago" ? (
                            <Button
                                style={{ marginLeft: 15 }}
                                size="small"
                                disabled={notifyLoading}
                                variant="contained"
                                color="primary"
                                endIcon={<NotificationsActiveIcon />}
                                onClick={() => handleOpenDialogForm("Recordar")}
                            >
                                Notificar
                            </Button>
                            ) : (
                            <Button
                                style={{ marginLeft: 15 }}
                                size="small"
                                disabled={notifyLoading}
                                variant="contained"
                                color="primary"
                                endIcon={<NotificationsActiveIcon />}
                                onClick={() => handleOpenDialogForm("Verificar")}
                            >
                                Validar
                            </Button>
                            )}
                        </Typography>
                        </Grid>
                    ) : (
                        <Grid>
                        <Typography component="h6" gutterBottom>
                            <strong>CITA EXCENTA DE PAGO</strong>
                        </Typography>
                        </Grid>
                    )}
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel id="epsLabel">Eps</InputLabel>
                    <Select
                        required
                        labelId="epsLabel"
                        id="eps"
                        name="contrato"
                        value={eps}
                        onChange={(event) => {
                        setEps(event.target.value);
                        handleEPSChange(event.target.value);
                        props.handleInput(event);
                        }}
                        fullWidth
                        disabled={!props.editContract}
                    >
                        {allEps &&
                        allEps.map((epss, index) => (
                            <MenuItem key={index} value={epss.contrato}>
                            {epss.empresa_descripcion}
                            </MenuItem>
                        ))}
                    </Select>
                    {loadingEps && <LinearProgress />}
                    </Grid>

                    {/* Only isSanitas true */}
                    {props.isSanitas && (
                    <Grid item xs={6}>
                        <InputLabel id="contratTypeLabel">Tipo de contrato</InputLabel>
                        <Select
                        required
                        labelId="contratTypeLabel"
                        id="contratType"
                        name="contratTypeLabel"
                        value={contratType}
                        onChange={(event) => {
                            setContratType(event.target.value);
                            /* handleEPSChange(event.target.value);
                        props.handleInput(event); */
                        }}
                        fullWidth
                        disabled={!props.editContract}
                        >
                        {allContratType &&
                            allContratType.map((contratType, index) => (
                            <MenuItem key={index} value={contratType}>
                                {contratType}
                            </MenuItem>
                            ))}
                        </Select>
                        {loadingEps && <LinearProgress />}
                    </Grid>
                    )}
                    {props.isSanitas && (
                    <Grid item xs={6}>
                        <InputLabel id="patientTypeLabel">Tipo de paciente</InputLabel>
                        <Select
                        required
                        labelId="patientTypeLabel"
                        id="patientType"
                        name="patientTypeLabel"
                        value={patientType}
                        onChange={(event) => {
                            setPatientType(event.target.value);
                            props.handleInput(event);
                        }}
                        fullWidth
                        disabled={!props.editContract}
                        >
                        {allPatientType &&
                            allPatientType.map((patientType, index) => (
                            <MenuItem key={index} value={patientType}>
                                {patientType}
                            </MenuItem>
                            ))}
                        </Select>
                        {loadingEps && <LinearProgress />}
                    </Grid>
                    )}
                    {props.isSanitas && (
                    <Grid item xs={12}>
                        <InputLabel id="serviceLabel">Portafolio de servicios</InputLabel>
                        <Select
                        required
                        labelId="serviceLabel"
                        id="service"
                        name="serviceLabel"
                        value={service}
                        onChange={(event) => {
                            setService(event.target.value);
                            props.handleInput(event);
                        }}
                        fullWidth
                        disabled={!props.editContract}
                        >
                        {allServices &&
                            allServices.map((services, index) => (
                            <MenuItem key={index} value={services}>
                                {services}
                            </MenuItem>
                            ))}
                        </Select>
                        {loadingEps && <LinearProgress />}
                    </Grid>
                    )}
                    {/* END Only isSanitas true */}

                    <Grid item xs={12}>
                    <InputLabel id="RegimenLabel">Regimen</InputLabel>
                    <Select
                        required
                        labelId="RegimenLabel"
                        id="regimen"
                        name="regimen"
                        value={regimen}
                        onChange={(event) => {
                        setRegimen(event.target.value);
                        handleRegimenChange(event.target.value);
                        props.handleInput(event);
                        }}
                        fullWidth
                        disabled={!props.editContract}
                    >
                        {allRegimen &&
                        allRegimen.map((regimen, index) => (
                            <MenuItem key={index} value={regimen.codigo_regimen}>
                            {regimen.nombre_regimen}
                            </MenuItem>
                        ))}
                    </Select>
                    {loadingRegimen && <LinearProgress />}
                    </Grid>
                    <Grid item xs={12}>
                    <InputLabel id="LevelLabel">Level</InputLabel>
                    <Select
                        required
                        labelId="LevelLabel"
                        id="level"
                        name="nivel_usuario"
                        value={level}
                        onChange={(event) => {
                        setLevel(event.target.value);
                        props.handleInput(event);
                        }}
                        fullWidth
                        disabled={!props.editContract}
                    >
                        {allLevels &&
                        allLevels.map((level, index) => (
                            <MenuItem key={index} value={level.codigo_tipo}>
                            {level.tipo_afiliado}
                            </MenuItem>
                        ))}
                    </Select>
                    {loadingLevel && <LinearProgress />}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <InputLabel id="StatusLabel">Estado de la cita</InputLabel>
                    <Select
                        required
                        labelId="StatusLabel"
                        id="status"
                        name="status_changed_to"
                        value={status}
                        onChange={(event) => {
                        setStatus(event.target.value);
                        props.handleInput(event);
                        verMOtivo(event.target.value);
                        }}
                        fullWidth
                        disabled={!props.editContract}
                    >
                        {allStatus &&
                        allStatus.map((status, index) => (
                            <MenuItem key={index} value={status}>
                            {status}
                            </MenuItem>
                        ))}
                    </Select>
                    {loadingStatus && <LinearProgress />}
                    </Grid>

                    {showreason && (
                    <Grid item xs={12} sm={12}>
                        <InputLabel id="Motivo">Motivo</InputLabel>
                        <Select
                        required
                        labelId="Motivo"
                        id="motivo"
                        value={motivo}
                        name="motivo_to"
                        onChange={(event) => {
                            setMotivo(event.target.value);
                            props.handleInput(event);
                            console.log(`motivo selected:`, event.target.value);
                        }}
                        fullWidth
                        disabled={!props.editContract}
                        >
                        {allMotivos &&
                            allMotivos.map((reason, index) => (
                            <MenuItem key={index} value={reason}>
                                {reason}
                            </MenuItem>
                            ))}
                        </Select>
                        {loadingMotivo && <LinearProgress />}
                    </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        className={classes.field}
                        id="outlined-multiline-static"
                        label="Observaciones"
                        name="description"
                        multiline
                        disabled={
                        !props.editContract /* || ((props.isAuthorized && props.isSanitas) && status === "NO AUTORIZADO") */
                        }
                        rows={4}
                        variant={
                        /* props.isAuthorized && status === "NO AUTORIZADO" ? "filled" : */ "outlined"
                        }
                        onChange={(event) => {
                        setDescription(event.target.value);
                        props.handleInput(event);
                        }}
                        value={
                        /* props.isAuthorized && status === "NO AUTORIZADO" ? "" :  */ description
                        }
                    />
                    </Grid>
                </Grid>
                ) : (
                <Grid item></Grid>
                )}
            </Grid>
                
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                Enviar notificación a paciente
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Por favor diligencie los campos presentes, tenga en cuenta que esta
                    notificación se enviará al dispositivo movil del paciente
                </DialogContentText>
                <TextField
                    autoFocus
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    margin="dense"
                    name="title"
                    onChange={(event) => handleNotifyTextInput(event)}
                    value={notificationForm.title}
                    id="title"
                    label="Título de la notificación"
                    type="text"
                    fullWidth
                />
                <TextField
                    style={{ marginTop: 30 }}
                    variant="outlined"
                    rows={4}
                    multiline
                    onChange={(event) => handleNotifyTextInput(event)}
                    value={notificationForm.message}
                    name="message"
                    id="message"
                    label="Mensaje de la notificación"
                    type="text"
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                {urlFileTransfer && (
                    <Button
                    onClick={handleUrlFileTransfer}
                    disabled={notifyLoading}
                    color="primary"
                    variant="contained"
                    >
                    Ver Comprobante
                    </Button>
                )}

                <Button
                    onClick={handleClose}
                    disabled={notifyLoading}
                    color="primary"
                    variant="outlined"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={handleNotify}
                    disabled={notifyLoading}
                    color="primary"
                    variant="contained"
                >
                    Enviar notificación
                </Button>
                </DialogActions>
            </Dialog>        
        </Container>
    );
}

/* const mapStateToProps = (state) =>({
  clinic: state.clinic,
  user: state.user,
  patients: state.patients
}) */

/* const mapDispatchToProps = {
  setClinic,
} */

//export default connect(mapStateToProps, null)(ContactInfoForm);
export default ContractInfoForm;
