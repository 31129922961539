import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Typography,
} from '@material-ui/core';
import ShowAlert from '../../components/ShowAlert';
import { connect } from 'react-redux';
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
import renderCellExpand from '../../components/renderCellExpand';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/CloudUpload';

const StaffCrons = (props) => {
    const [title, setTitle] = useState('Crontabs ISA');
    const [rowsLoading, setRowsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);

    const columnsCondicionales = [
        { field: 'cron_id', headerName: 'ID', width: 70},
        { field: 'nombre_visualizar_staff', headerName: 'Nombre', width: 250},   
        { field: 'descripcion_flag_cron', headerName: 'Descripcion', renderCell: renderCellExpand, width: 350},
        { field: 'estado_flag_cron', headerName: 'Activo', width: 100, type: 'boolean', editable: true, renderCell: (params) => {      
            return params.row.estado_flag_cron ? (
              <CheckIcon style={{ color: 'green'}} onClick={toggleActivo(params.row)}
              />
            ) : (
              <CloseIcon style={{ color: 'gray' }} onClick={toggleActivo(params.row)}
              />
            );
        },},
        { field: 'guardar', headerName: 'Guardar', width: 140, renderCell: renderCellExpand, renderCell: (params) => {
            return  (
                <DoneIcon style={{ color: 'green' }} onClick={() => editCron(params.row, props.clinic.cli_id)}/>                      
            )
        }, },
    ]

    useEffect(async () => {
        await getAllData();
    }, [isUpdate])

    const getAllData = async () => {
        setRowsLoading(true);

        var list = [];
        var count = 0;

        const resp = await staffAppointmentService.getAllCrons(props.clinic.cli_id);
        count =  Object.keys(resp).length;
        if (count > 0) {    
            for (let j = 0; j < count; j++) {     
                const row = { ...resp[j], id: resp[j].cron_id };
                list.push(row);
            }     
        }

        list.sort((a, b) => a.cron_id - b.cron_id);

        const rowsToRender = list.map((row, index) => {
            return {
                ...row,
                index: index,
            }
        })

        setRows(rowsToRender);
        setRowsLoading(false);
    }

    const toggleActivo = React.useCallback(
        (clickedRow) => () => {
            setRows((prevRows) =>
                prevRows.map((row) =>
                row.id === clickedRow.id ? { ...clickedRow, estado_flag_cron: !clickedRow.estado_flag_cron } : row,
                ),
            );
        },
        [],
    );

    const editCron = (row, clinic_id) => {
        const resp = staffAppointmentService.updateCron(clinic_id, row.nombre_flag_cron, row.estado_flag_cron ? 'true' : 'false');
        alert('Cron actualizado correctamente');
        setIsUpdate(!isUpdate);
    }

    return (
        <React.Fragment>
        <Typography variant="h6" gutterBottom>
            {title}
        </Typography>
        {alert.show && (<ShowAlert{...alert}/>)}
        <DataGrid
            autoHeight
            rows={rows}
            loading={rowsLoading}
            columns={columnsCondicionales}
            pageSize={10}
            disableSelectionOnClick
        />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    lastContrat: state.lastContrat,
    clinic: state.clinic
})

export default connect(mapStateToProps, null)(StaffCrons);