import axios from "axios";
//Mapeo de clinicas
import { clinicsM } from "./clinicsM";

const getAllAppointments = async (clinicId, user) => {
  // Parse data
  const data = JSON.stringify({
    clinic_id: clinicId,
    user: user,
  });

  // endpoint url
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/get/appointments";

  const resultData = await axios({ method: "post", url: url, data: data }).then(
    (result) => result
  );
  return resultData;
};

const insertMedicalAppointments = async (dataFromCitapp) => {
  const baseUrl = "https://portubien.com.co/citapp_user_desa_0/";

  const decodeData = atob(dataFromCitapp);
  const dataToSend = JSON.parse(decodeData);
  var bodyFormData = new FormData();
  //Object.entries(decodeData).forEach(([k, v]) => bodyFormData.append(k, v))
  bodyFormData.append("clinica", dataToSend.clinica);
  bodyFormData.append("data", JSON.stringify(dataToSend.data));

  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  // andpoint url
  const url = baseUrl + "citas/insertCitaMedica";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: bodyFormData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const crearTeleconsulta = async (dataRic) => {
  const baseUrl = "https://portubien.com.co/appRic_desarrollo/";

  var bodyFormData = new FormData();
  bodyFormData.append("datos_teleconsulta", JSON.stringify(dataRic));
  bodyFormData.append(
    "flag_staff",
    JSON.stringify("CITA PENDIENTE DE CONFIRMACION")
  );

  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  // andpoint url
  const url = baseUrl + "Apipresencial/crearConsultaTeleconsulta";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: bodyFormData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const clinicsMaping = clinicsM.Maping();

const crearConsultaPresencial = async (dataRic) => {
  const baseUrl = "https://portubien.com.co/appRic_desarrollo/";

  var bodyFormData = new FormData();
  bodyFormData.append("datos_presencial", JSON.stringify(dataRic));
  bodyFormData.append(
    "flag_staff",
    JSON.stringify("CITA PENDIENTE DE CONFIRMACION")
  );

  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  // andpoint url
  const url = baseUrl + "Apipresencial/crearConsultaPresencial";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: bodyFormData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const getDocumentsAppointments = async (clinic_id, card_id, cita_num) => {
  const url = `https://portubien.com.co/appRic_desarrollo/historias/view_AllDocuments/${clinicsMaping[clinic_id]}/${card_id}/${cita_num}`;
  //url ='https://portubien.com.co/appRic_desarrollo/historias/view_AllDocuments/27/94324117/289173'
  console.log("url service", url);
  const resultData = await axios.get(url).then((result) => result.data);
  return resultData;
};

const createAppointmentInCeroTram = async (data) => {
  // Parse data
  const dataToSend = JSON.stringify(data);

  // endpoint url
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/create/apointment/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getAppointmentInfo = async (cita_num, cerotram_user_id) => {
  // Parse data
  const dataToSend = JSON.stringify({
    cita_num: cita_num,
    cerotram_user_id: cerotram_user_id
  });

  // endpoint url
  const url = process.env.REACT_APP_URL_HOST + "/cerotram/get/apointmentinfo/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const scheduleOrderedAppointmentCeroTram = async (user, hiscsec) => {
  // Parse data
  const dataToSend = JSON.stringify({
    user: user,
    hiscsec: hiscsec,
  });

  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST + "/cerotram/schedule/orderedappointment/";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const createPriorityPetitionAppointment = async (formData) => {
  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/patient/petition/priority/appointment";
  const resultData = await axios({
    method: "POST",
    url: url,
    data: formData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const getAllIncapacities = async (user, clinic) => {
  // Parse data
  const dataToSend = JSON.stringify({
    user: user,
    clinic: clinic,
  });

  // endpoint url
  //const url= process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/get/priority_petition_appointments";
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/patient/get/all_incapacities";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const createEmployer = async (user, formEmpleador) => {
  // Parse data
  const dataToSend = JSON.stringify({
    user: user,
    employer_name: formEmpleador.employer_name,
    employer_phone: formEmpleador.employer_phone,
    employer_email: formEmpleador.employer_email,
    employer_authorization: formEmpleador.employer_authorization,
  });

  // endpoint url
  //const url= process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/get/priority_petition_appointments";
  const url =
    process.env.REACT_APP_URL_HOST + "/cerotram/api/patient/create/employer";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getEmployerInfo = async (user) => {
  // Parse data
  const dataToSend = {
    user: user,
  };
  /*    const dataToSend = JSON.stringiffy({
    "user": user,
    "clinic":clinic
  }); */
  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST + "/cerotram/api/patient/get/employer";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const sendIncapacityToEmployer = async (
  user,
  clinic,
  description,
  incapacityUrl
) => {
  // Parse data
  const dataToSend = {
    user: user,
    clinic: clinic,
    description: description,
    incapacityUrl: incapacityUrl,
  };
  /*    const dataToSend = JSON.stringify({
    "user": user,
    "clinic":clinic
  }); */
  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/patient/send/incapacity/to/employer";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const viewDocumentIncapacity = async (clinic_id, card_id, folio, tipo) => {
  const baseUrl = "https://portubien.com.co/appRic_desarrollo/";

  var bodyFormData = new FormData();
  bodyFormData.append("clinica", clinic_id);
  bodyFormData.append("cc", card_id);
  bodyFormData.append("folio", folio);
  bodyFormData.append("tipo", tipo);
  // axios config
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // andpoint url
  const url = baseUrl + "OMedicas/view_incapacidad_0_tram";
  const resultData = await axios({
    responseType: "arraybuffer",
    method: "POST",
    url: url,
    data: bodyFormData,
    config,
  }).then((result) => result);
  return resultData;
};

const getAllIncapacitiesCeroTramites = async (user, cli_id) => {
  // Parse data
  const dataToSend = JSON.stringify({
    user: user,
    clinic_id: cli_id,
  });
  // endpoint url
  //const url= process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/get/priority_petition_appointments";
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/patient/get/all_incapacities";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getAllPresurgeryProcedures = async (user, clinic) => {
  // Parse data
  const dataToSend = {
    clinic: clinic,
    user: {
      card_id: "18497463",
    },
  };
  /*    const dataToSend = JSON.stringify({
    "user": user,
    "clinic":clinic
  }); */
  // endpoint url
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/patient/get/all_presurgeries";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const disableIncapacityNotifications = async (
  user,
  disable_incapacity_notify
) => {
  // Parse data
  const dataToSend = JSON.stringify({
    user: user,
    incapacity_notify: disable_incapacity_notify,
  });

  // endpoint url
  //const url= process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/get/priority_petition_appointments";
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/disable/nofications/incapacities";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getIncapacityNotifications = async (user) => {
  // Parse data
  const dataToSend = JSON.stringify({
    user: user,
  });

  // endpoint url
  //const url= process.env.REACT_APP_URL_HOST + "/cerotram/api/staff/get/priority_petition_appointments";
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/api/get/nofications/incapacities";

  const resultData = await axios({
    method: "POST",
    url: url,
    data: dataToSend,
  }).then((result) => result.data);
  return resultData;
};

const getPayStatusAppointment = async (citaNum) => {
  // Parse data
  const formData = new FormData();
  formData.append("cita", citaNum);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // endpoint url
  const url =
    "https://portubien.com.co/appRic_desarrollo/Apipresencial/consultarEstadoPago";

  const resultData = await axios({
    method: "post",
    url: url,
    data: formData,
    config,
  }).then((result) => result.data);
  return resultData;
};

const getAuthorization = async (usuario, numero_cita) => {
  console.log("Servicio getAuthorization");
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/documento_adjunto/?usuario=" +
    usuario +
    "&numero_cita=" +
    numero_cita;
  return fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log("error", error));
};

const EstadoCerotram = async (
  numero_cita,
  tipo_documento,
  documento,
  clinica_id,
  cerotram_user_id
) => {
  const url =
    process.env.REACT_APP_URL_HOST +
    "/cerotram/citas/estado_cita/?numero_cita=" +
    numero_cita +
    "&tipo_documento=" +
    tipo_documento +
    "&documento=" +
    documento +
    "&clinica_id=" +
    clinica_id + 
    "&cerotram_user_id=" +
    cerotram_user_id;

  const resultData = await axios({ method: "GET", url: url }).then(
    (result) => result.data
  );
  return resultData;
};

export const appointmentService = {
  getAllAppointments,
  insertMedicalAppointments,
  getAuthorization,
  crearTeleconsulta,
  crearConsultaPresencial,
  createAppointmentInCeroTram,
  getAppointmentInfo,
  scheduleOrderedAppointmentCeroTram,
  createPriorityPetitionAppointment,
  getAllIncapacities,
  getAllIncapacitiesCeroTramites,
  viewDocumentIncapacity,
  getAllPresurgeryProcedures,
  createEmployer,
  getEmployerInfo,
  sendIncapacityToEmployer,
  disableIncapacityNotifications,
  getIncapacityNotifications,
  getDocumentsAppointments,
  getPayStatusAppointment,
  EstadoCerotram,
};
