import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridFilterToolbarButton } from '@material-ui/data-grid';
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  makeStyles,
  TextField,
  Grid
} from '@material-ui/core';
import ShowAlert from '../../components/ShowAlert';
import IncapacitiesBottomNavigation from '../../components/staff/IncapacitiesBottomNavigation';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import red from '@material-ui/core/colors/red';
// redux
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
import useBrowserContextCommunication from 'react-window-communication-hook';
// Services
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: "20px",
    //borderRadius: '0px!important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  field: {
    width: "100%",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 160,
  },

}));

const clinicsMaping =  clinicsM.Maping();

const StaffAdminIncapacitiesTable = (props) => {

  const classes = useStyles();
  const [title, setTitle] = useState('Incapacidades por radicar');
  const [defectColor, setDefectColor] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date - (1000 * 60 * 60 * 24)));
  const [incapacitiesLoading, setIncapacitiesLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [communicationState, postMessage] = useBrowserContextCommunication("DashboardStaffChanel");

  const columnsIncapacities = [
    { field: 'Fch_Inicia_Incap', headerName: 'Inicia', width: 108, renderCell: renderCellExpand },
    { field: 'Fch_Fin_Incap', headerName: 'Finaliza', width: 108, renderCell: renderCellExpand },
    { field: 'Dias_Incap', headerName: 'Días', width: 81, renderCell: renderCellExpand },
    { field: 'Nombre_Medico', headerName: 'Médico', width: 110, renderCell: renderCellExpand },
    { field: 'Tipo_de_Incapacidad', headerName: 'Tipo', width: 81, renderCell: renderCellExpand },
    { field: 'Causa_Externa', headerName: 'Causa Externa', width: 150, renderCell: renderCellExpand },
    { field: 'Nombre_Diag', headerName: 'Diagnóstico', width: 150, renderCell: renderCellExpand },
    { field: 'estado', headerName: 'Estado', width: 100, renderCell: renderCellExpand },
    {
      field: 'Aprobar',
      headerName: 'Radicar',
      width: 120,
      renderCell: (params) => (
        <IconButton
          disabled={title === 'Histórico de incapacidades radicadas'}
          onClick={() => handleApprovalIncapacity(params)}
        >
          {title === 'Histórico de incapacidades radicadas' ? "" : <CheckCircleIcon color="primary" />}
        </IconButton>
      ),
    },
    /* {
      field: 'Cancelar',
      headerName: 'Cancelar',
      width: 100,
      renderCell: (params) => (
        <IconButton
          //onClick={() => handleCancelAppoinments(params)}
        >
          <CancelIcon style={{ color: red[700] }} />
        </IconButton>
      ),
    }, */
  ]

  function CustomToolbar() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.textField}>
          <Grid>
            <Typography variant="subtitle2" color={defectColor ? "primary" : "error"} gutterBottom>
              Buscar por fecha

            </Typography>
          </Grid>
          <Grid>
            <KeyboardDatePicker
              variant="dialog"
              disabled={title === 'Histórico de incapacidades radicadas'}
              margin="dense"
              inputVariant="standard"
              id="date-picker-dialog"
              showTodayButton={true}
              format="dd/MM/yyyy"
              value={selectedDate}
              InputProps={{
                disableUnderline: true,
                disabled: true
              }}
              InputAdornmentProps={{ position: "start" }}
              onChange={handleDateChange}
            />

          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setDefectColor(true);
    await getAllIncapacitiesByDate(date);
    //setTimeout(() => console.log("valor nuevo"), 4000);
  };

  const getAllIncapacitiesByDate = async (selected_date) => {
    //Get data
    setIncapacitiesLoading(true);
    const resp = await staffAppointmentService.getAllIncapacitiesByDate(selected_date.toISOString(), props.clinic);
    // parse data
    console.log("desde resp ", resp);
    const rowsToRender = resp.data.map((row, index) => {
      //const appointmentInfo = row.appointment_info_from_citapp;
      return {
        ...row,
        id: index,
      }
    })
    console.log("PRINT DATA FROM PYTHON", rowsToRender)
    setRows(rowsToRender);
    setIncapacitiesLoading(false);
  }

  const handleApprovalIncapacity = (params) => {
    props.handleOpenCloseIncapacityAcceptModal(params.row);
    console.log("params row ", params.row)
    //changeIncapacityStatus(params.row, "Radicado por EPS");
  }

  const changeIncapacityStatus = async (row, new_status) => {
    //Get data
    setIncapacitiesLoading(true);
    const resp = await staffAppointmentService.changeIncapacityStatus(props.clinic, row, new_status);

    await getAllIncapacitiesByDate(selectedDate);
    setIncapacitiesLoading(false);
  }

  const getAllHistorycIncapacities = async () => {
    setIncapacitiesLoading(true);
    const allHistorycIncapacities = await staffAppointmentService.getAllHistorycIncapacities(props.clinic);
    setRows(allHistorycIncapacities.data)
    setIncapacitiesLoading(false);
  }

  const handleSwitchChange = async (selected) => {
    if (selected === 'toRadic') {
      setTitle('Incapacidades por radicar');
      // set new rows data
      //setRows(rowser);
      await getAllIncapacitiesByDate(selectedDate);
    } else {
      setTitle('Histórico de incapacidades radicadas');
      // set new rows data
      getAllHistorycIncapacities();
      //setRows(priorityAppoint);
    }
  };


  useEffect(async () => {
    await getAllIncapacitiesByDate(selectedDate);

  }, [])

  useEffect(async () => {
    if (communicationState.lastMessage) {
      const { reloadIncapacities } = communicationState.lastMessage
      if (reloadIncapacities) {
        await getAllIncapacitiesByDate(selectedDate);
      }
    }
  }, [communicationState])


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        components={{
          Toolbar: CustomToolbar
        }}
        rows={rows}
        loading={incapacitiesLoading}
        columns={columnsIncapacities}
        pageSize={4}
      />
      <IncapacitiesBottomNavigation
        handleSwitchChange={handleSwitchChange}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(StaffAdminIncapacitiesTable);