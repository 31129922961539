import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// redux
import { connect } from 'react-redux';

//Actions
import {setClinic} from '../actions';




const useStyles = makeStyles({
  root: {
    width: 300,
  },
  media: {
    height: 140,
  },
});

const ClinicCard = (props) => {

    const classes = useStyles();
    
    return (
      <div onClick={() => {props.handleClick()}}>
        <Card className={classes.root}>
          <CardActionArea>
              <CardMedia
              className={classes.media}
              image={props.clinic.image}
              title={`${props.clinic.name} - ${props.clinic.clinicId}`}
              />
              <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                  {props.clinic.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                  {props.clinic.city}
              </Typography>
              </CardContent>
          </CardActionArea>
        </Card>
        </div>
    );
};

export default ClinicCard;