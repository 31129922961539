import React, { useState, useEffect } from 'react';
import axios from 'axios';
// redux
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(14, 0, 0.6),
    paddingBottom: "20px",
    //borderRadius: '0px!important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  field: {
    width: "100%",
    marginBottom:"5px",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop:"10px",
    marginBottom: "17%",
    paddingTop: "20px",
    paddingBottom: "10px",
    paddingRight: "20px",
  },
  
}));

function UploadDocument(props) {

  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState([]);
  //const [description, setDescription] = useState("")

  const submitForm = async (fileRecived) => {
   if(fileRecived != undefined){
      await setSelectedFile([...selectedFile, fileRecived]);
    }
    
    
    
    const formData = new FormData();
    //formData.append("description", description);
    formData.append("file", fileRecived);
   await props.handleDocumentToUpload(fileRecived);
    /* axios
      .post("https://611363e250b2.ngrok.io/cerotram/api/patient/petition/priority/appointment", formData)
      .then((res) => {
        alert("Solicitud enviada");
      })
      .catch((err) => alert("Error al enviar solicitud " + selectedFile.name)); */
  };

  return (
    <form >
      <Container component="main" maxWidth="sm" spacing={3}  >
          <Grid>

            {/* <TextField
              className={classes.field}
              id="outlined-multiline-static"
              label="Observaciones"
              multiline
              rows={4}
              variant="outlined"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            /> */}
            <input
              hidden
              id="raised-button-file"
              type="file"
              multiple
              //onFileSelectSuccess={(e) => setSelectedFile(e.target.files[0])}
              //onFileSelectError={({ error }) => alert(error)}
              onClick={(e) => (e.target.value="")}
              onChange={(e) => (submitForm(e.target.files[0]))}
            />
            <Grid>
            { (selectedFile.map((status, index) => (
                            
                            <Typography variant="subtitle2">                              
                                Archivo seleccionado: <strong >{status.name}</strong>
                              <IconButton onClick={()=>(selectedFile.splice(index, 1), setSelectedFile([...selectedFile]))} size="small"> 
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                          </Typography>
                          )))}

            {/* {selectedFile.name &&
              
                <Typography variant="subtitle2">
                  Archivo seleccionado: <strong >{selectedFile?.name}</strong>
                  <IconButton onClick={()=>setSelectedFile("")} size="small"> 
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
                
            } */}
            </Grid>
            <Grid container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
               >
              <label htmlFor="raised-button-file">
                <Button                
                  disableElevation="false"
                  variant="outlined"
                  color="default"
                  component="span"
                  startIcon={<CloudUploadIcon/>}  
                  size="small"
                  >
                  Adjuntar
                </Button>
              </label>                      
            </Grid>
          </Grid>
      </Container>
    </form>
  );
}


const mapStateToProps = (state) => ({
  clinic: state.clinic,
  user: state.user,
  patients: state.patients
})

/* const mapDispatchToProps = {
  setClinic,
} */

export default connect(mapStateToProps, null)(UploadDocument);
