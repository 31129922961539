import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const ShowAlert = ({show, type, title, message, strong, action}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert
        severity={type}
        action={action ? (
        <Button color="inherit" size="small">
          {action}
        </Button> ):
        null}
      >
        <AlertTitle>
          {title}
        </AlertTitle>
        {message} {strong && (<strong>{strong}</strong>)}
      </Alert>
    </div>
  );
}

export default ShowAlert;