import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
// redux
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';


// Actions
import { loginRequest, setPatient } from '../actions';

// services
import { authenticationService } from '../services/authenticationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  circle: {
    size: 60,
  },
}));

const FromResetPassword = (props) => {

  const classes = useStyles();
  //const [form, setValues] = useState(props.form);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordAgainValidator, setPasswordAgainValidator] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(false);

 
  useEffect(async () => {
  }, [])

  return (
    <form className={classes.form} noValidate>

      <TextField
        error={props.passwordValidator}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="text"
        id="password"
        label="Escriba una contraseña"
        name="password"
        helperText={props.passwordValidator && "la contraseña debe contener al menos 4 dígitos*"}
        autoFocus
        onChange={props.handleInputPassword}
      />

      <TextField
        error={props.passwordAgainValidator}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password_again"
        label="Escriba la contraseña nuevamente"
        type="text"
        id="password_again"
        helperText={props.passwordAgainValidator && "las contraseñas no coinciden*"}
        autoComplete="current-password"
        onChange={props.handleInputPassword}
      />
      <Grid container>
        {/* {loading ?
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center">
            <CircularProgress className={classes.circle} /></Grid> :
          <Button
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
            disabled={passwordValidator || passwordAgainValidator}
            className={classes.submit}>
            Actualizar contraseña
          </Button>} */}
      </Grid>
    </form>
  );
}
const mapStateToProps = (state) => ({
  clinic: state.clinic
})

const mapDispatchToProps = {
  loginRequest,
  setPatient
};

export default connect(mapStateToProps, mapDispatchToProps)(FromResetPassword);