import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

/* Components */
import Dashboard from '../containers/dashboard/Dashboard.js';
import Login from '../containers/Login';
import ResetPassword from '../containers/ResetPassword';
import ChatWindow from '../containers/ChatWindow';
import ChatUserWindow from '../components/staff/ChatUserWindow';
import Register from '../containers/Register';
import NotFound from '../containers/NotFound';
//import FaceCapture from '../containers/FaceCapture';
import FaceRegister from '../containers/FaceRegister';
//import Layout from '../components/Layout';
import SliderAllClinics from '../containers/SliderAllClinics';
import CitappModal from '../containers/dashboard/CitappModal'
import AllClinics from '../containers/AllClinics';
import SelectClinic from '../containers/SelectClinic';
import ConfirmAppointment from '../containers/ConfirmAppointment';
import StaffConfirmAppointment from '../containers/StaffConfirmAppointment';
import DashboardStaff from '../containers/dashboard-staff/DashboardStaff.js';
import PriorityAppointmentModal from '../containers/dashboard/PriorityAppointmentModal';

/* import LandingPage from '../containers/LandingPage/LandingPage' */

const App = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={SelectClinic}></Route>
            <Route exact path="/dashboard" component={Dashboard}></Route>
            <Route exact path="/staff/dashboard/" component={DashboardStaff}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/register" component={Register}></Route>
            <Route exact path="/facecapture" component={FaceRegister}></Route>
            <Route exact path="/sliderclinics" component={SliderAllClinics}></Route>
            <Route exact path="/clinics" component={AllClinics}></Route>
            <Route exact path="/selectclinic" component={SelectClinic}></Route>
            <Route exact path="/modal" component={CitappModal}></Route>
            <Route path="/patient/updateinformation/:dataFromCitapp" component={ConfirmAppointment}></Route>
            <Route path="/staff/updateinformation/:dataFromCitapp" component={StaffConfirmAppointment}></Route>
            <Route path="/patient/createpriorityappointment/:dataFromCitapp" component={PriorityAppointmentModal}></Route>
            <Route path="/resetpassword/" component={ResetPassword}></Route>
            <Route path="/clientchat/" component={ChatWindow}></Route>
            <Route path="/clientuserchat/" component={ChatUserWindow}></Route>
            {/* <Route exact path="/landing" component={CitappModal}></Route> */}
            <Route component={NotFound}></Route>
        </Switch>
    </BrowserRouter>
)

export default App;