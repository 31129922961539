import React, {useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
// redux
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ClinicCard from '../components/ClinicCard'
import Copyright from '../components/Copyright';
import AutoComplete from '../components/AutoComplete';
//Actions
import {setClinic} from '../actions';
//Mapeo de clinicas
import { clinicsM } from '../services/clinicsM';

 
// Clinics Images
import estanciaPopImg from '../assets/static/clinics/estanciapopayan.jpg';
import nuestraIbagImg from '../assets/static/clinics/nuestraibague.jpg';
import nuestraCartImg from '../assets/static/clinics/nuestracartagena.jpg';
import rosalesPereImg from '../assets/static/clinics/rosalespereira.jpg';
import corazonMedImg from '../assets/static/clinics/corazonmedellin.jpg';
import antioquiaItagImg from '../assets/static/clinics/antioquiaitagui.jpg';
import antioquiaBellImg from '../assets/static/clinics/antioquiabello.jpg';
import versallesManiImg from '../assets/static/clinics/versallesmanizales.jpg';
import mercedBarrImg from '../assets/static/clinics/mercedbarranquilla.jpg';
import centralqArmeImg from '../assets/static/clinics/ccqarmenia.jpg';
import logoCuadrado from '../assets/static/logocuadrado.jpg';
/* import bannerAllclinics from '../assets/static/clinics/bannerAllclinics.jpg'; */

import LogoOspedalex70 from '../assets/static/LogoOspedalex70.png';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`, 
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 35%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(8, 0, 6),
    //borderRadius: '0px!important',
    },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  paper: {
    width: '33.333%',
    marginLeft:'33.333%',    
  },
}));

function SelectClinic(props) {

  const [allClinics, setAllclinics] = useState([]);
  const classes = useStyles();
  let history = useHistory();

  const handleClick = (clinic) => {
    props.setClinic(clinic);
    history.push("/login");
  }

  useEffect(async() => {

    const requestOptions = {
      method: 'GET',
    };
  
    const response = await fetch(
      process.env.REACT_APP_URL_HOST_AMS + '/api/getListClinicas',
      requestOptions
    ).then(resp => resp.json())

    setAllclinics(response.data);
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Paper className={classes.paper}>
          <Container maxWidth="sm">
            {/* <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              {props.clinic?.name}
            </Typography> */}
            <Grid container spacing={2} justify="center">
                <Grid item>
                <img src={LogoOspedalex70} alt="Logo"></img>
                </Grid>
            </Grid>

            <Typography variant="h6" align="center" color="textSecondary" paragraph>
                Para continuar, Por favor Selecciona una de nuestras Clínicas
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button component={Link} to="/register" variant="contained" color="primary">
                      Quiero Registrarme
                  </Button>
                </Grid>
                <Grid item>
                  <Button component={Link} to={{ pathname: 'http://www.ospedale.com.co/'}} target="_blank" variant="outlined" color="primary">
                    Pagina principal
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
          </Paper>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
          {/* End hero unit */}
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={4}>
            {clinics.map((clinic, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <ClinicCard handleClick={() => handleClick(clinic)} clinic={clinic} />
              </Grid>
            ))}
            {/* <Grid>
              <img src={bannerAllclinics} alt="banner"></img>
            </Grid>
            <Grid>
              <AutoComplete
                id="selectClinic"
                getOptionLabel={(clinic) => clinic.cli_nombre_clinica}
                options={allClinics}
                handleSelection={handleClick}
                label={"Selecciona una Clínica"}
              />
            </Grid> */}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
const clinics = clinicsM.Clinic();

const mapStateToProps = (state) =>({
  clinic: state.clinic
})

const mapDispatchToProps = {
  setClinic,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectClinic);