import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';

const useStyles = makeStyles({
  root: {
    width: "30%",
  },
});

export default function SimpleBottomNavigation(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        const selected = newValue === 0 ? 'authorized': 'history';
        props.handleSwitchChange(selected);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Por Autorizar" icon={<AssignmentTurnedInRoundedIcon />} />
      <BottomNavigationAction label="Historial" icon={<RestoreIcon />} />
    </BottomNavigation>
  );
}