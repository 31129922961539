import axios from 'axios';


const sendMessage = async (email, message) => {
  // Parse data
  const data = JSON.stringify({
    "email": email,
    "message": message
  });

  // endpoint url
  const url= process.env.REACT_APP_URL_HOST + "/cerotram/api/send/chat/response/";

  const resultData = await axios({ method:'post', url:url, data:data }).then(result => result.data)
  return resultData
}

const sendMessageUser = async (email, message) => {
  // Parse data
  const data = JSON.stringify({
    "email": email,
    "message": message
  });

  // endpoint url
  const url= process.env.REACT_APP_URL_HOST + "/cerotram/api/send/chatuser/response/";

  const resultData = await axios({ method:'post', url:url, data:data }).then(result => result.data)
  return resultData
}

  export const chatService = {
    sendMessage,
    sendMessageUser
  };