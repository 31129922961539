import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { authenticationService } from '../services/authenticationService';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  select: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const FormDocumentToResetPassword = (props) => {
  const classes = useStyles();
  const [documentInfo, setDocumentInfo] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleDocumentInput = event => {
    const { value } = event.target;
    if (!isNaN(value) || value === "") { // Solo acepta números o cadena vacía
      props.setDocumentInfo(value);
      props.setDisableFinishButton(false);
      setDocumentInfo(value);
    } else {
      props.setDisableFinishButton(true);
    }
  };
  
  const handleDocumentTypeChange = event => {
    const { value } = event.target;
    props.setDocumentType(value);
    setDocumentType(value);
    setError(''); // Limpiar el mensaje de error cuando se cambia el tipo de documento
  };
  
  const handleSubmit = async () => {
    if (!documentType) {
      setError('Por favor seleccione un tipo de documento.');
      return;
    }
    
    setLoading(true);
    console.log('Enviando formulario con documento:', documentInfo, 'y tipo de documento:', documentType);
    setLoading(false);
  };
  
  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="text"
        id="document"
        label="Ingrese el número de documento"
        name="document"
        value={documentInfo}
        autoFocus
        onChange={handleDocumentInput}
      />
      <FormControl variant="outlined" className={classes.select} error={Boolean(error)}>
        <InputLabel id="document-type-label">Tipo de documento</InputLabel>
        <Select
          labelId="document-type-label"
          id="document-type"
          value={documentType}
          onChange={handleDocumentTypeChange}
          label="Tipo de documento"
        >
          <MenuItem value="">Seleccionar</MenuItem>
          <MenuItem value="CC">Cédula de Ciudadanía</MenuItem>
          <MenuItem value="TI">Tarjeta de Identidad</MenuItem>
          <MenuItem value="RC">Registro Civil</MenuItem>
          <MenuItem value="CE">Cédula de Extranjería</MenuItem>
          <MenuItem value="PA">Pasaporte</MenuItem>
          <MenuItem value="PEP">Permiso Especial de Permanencia</MenuItem>
          <MenuItem value="PE">Permiso Extranjero</MenuItem>
          <MenuItem value="PT">Permiso de Protección Temporal</MenuItem>
          <MenuItem value="SC">Salvoconducto</MenuItem>
          <MenuItem value="AS">Adulto sin Identificación</MenuItem>
        </Select>
        {error && <span>{error}</span>}
      </FormControl>
    </form>
  );
};

export default FormDocumentToResetPassword;
