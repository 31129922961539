import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
// redux
import { connect } from 'react-redux';

import { appointmentService } from '../services/appointmentService';


function EmployerInfoForm(props) {

  const [employerInfo, setEmployerInfo] = useState([]);
  const [eps, setEps] = useState('');
  const [level, setLevel] = useState('');
  const [allEps, setAllEps] = useState([]);
  const [allRegimen, setAllRegimen] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [loadingEps, setLoadingEps] = useState(false);
  const [loadingRegimen, setLoadingRegimen] = useState(false);
  const [loadingLevel, setLoadingLevel] = useState(false);

  const getData = async (url) => {
    const requestOptions = {
      method: 'GET',
    };
    const resp = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    return resp;
  }

  const handleEPSChange = async (contract) => {
    /* Get All regiments for clinic id and number contract */
    setLoadingRegimen(true);
    const urlRegimens = process.env.REACT_APP_URL_HOST_AMS + `/api/getListRegimenes/${props.clinic.cli_id}/${contract}`
    const respAllRegimens = await getData(urlRegimens);
    setAllRegimen(respAllRegimens.data);
    setLoadingRegimen(false);
  }

  const handleRegimenChange = async (codigo_regimen) => {
    /* Get All levels for clinic id and number contract */
    setLoadingLevel(true);
    const contract = eps;
    const urlLevels = process.env.REACT_APP_URL_HOST_AMS + `/api/getListNivelesUsuario/${props.clinic.cli_id}/${contract}/${codigo_regimen}`
    const respAllLevels = await getData(urlLevels);
    setAllLevels(respAllLevels.data);
    setLoadingLevel(false);
  }

  useEffect(async () => {
    //const resp = await appointmentService.getEmployerInfo(props.user);
    //console.log("valor employer",resp);
    //setEmployerInfo(resp.data[0])
    // setEmployerInfo(resp)

    /* Get employer info for user */

    /* setLoadingEps(true);
    const urlContracts = process.env.REACT_APP_URL_HOST_AMS + `/api/getListContratos/${props.clinic.cli_id}`
    const respAllEps = await getData(urlContracts);
    
    await setAllEps(respAllEps.data);
    setLoadingEps(false);
    const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getUltimoContratoPaciente/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`;
    const lastContratData = await getData(urlLastContrat);
    if (lastContratData.data) {
      await setEps(lastContratData.data[0].contrato);
      await handleEPSChange(lastContratData.data[0].contrato);
      await setRegimen(lastContratData.data[0].regimen);
      const urlLevels = process.env.REACT_APP_URL_HOST_AMS + `/api/getListNivelesUsuario/${props.clinic.cli_id}/${lastContratData.data[0].contrato}/${lastContratData.data[0].regimen}`
      const respAllLevels = await getData(urlLevels);
      await setAllLevels(respAllLevels.data);
      await setLevel(lastContratData.data[0].nivel_usuario);
    } */
  }, [])

  return (
    <Container component="main" maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        INFORMACIÓN DE EMPLEADOR
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            style={{ marginLeft: "15px", marginRight: "15px", marginTop: "20px" }}
            id="EmployerName"
            name="employer_name"
            label="Nombre de la empresa"
            value={props.form.employer_name}
            onChange={props.handleInput}
            fullWidth

          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            style={{ marginLeft: "15px", marginRight: "15px", marginTop: "20px" }}
            error={false}
            id="EmployerPhone"
            name="employer_phone"
            label="Teléfono de la empresa"
            value={props.form.employer_phone}
            onChange={props.handleInput}
            autoComplete="given-name"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            style={{ marginLeft: "15px", marginRight: "15px", marginTop: "20px" }}
            error={false}
            id="EmployerEmail"
            name="employer_email"
            label="Email de la empresa"
            value={props.form.employer_email}
            onChange={props.handleInput}
            autoComplete="given-name"
            fullWidth
          />
        </Grid>
        <Grid>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                checked={props.form.employer_authorization}
                name="employer_authorization"
                onChange={props.handleInput}
              />
            } label="Autorizo radicar las incapacidades ante mi empleador" />
          </FormGroup>
        </Grid>
      </Grid>
    </Container>
  );
}

/* const mapStateToProps = (state) =>({
  clinic: state.clinic,
  user: state.user,
  patients: state.patients
}) */

/* const mapDispatchToProps = {
  setClinic,
} */

//export default connect(mapStateToProps, null)(ContactInfoForm);
const mapStateToProps = (state) => ({
  clinic: state.clinic,
  user: state.user,
  patients: state.patients
})


export default connect(mapStateToProps)(EmployerInfoForm);