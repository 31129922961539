import React from 'react';
import { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

// My MU icons
import MicIcon from '@material-ui/icons/Mic';

// My components
import Recorder from 'react-mp3-recorder'

// My MU Components
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
      minWidth: '100%',
      textAlign: 'center',
    },
    action: {
      display: 'flex',
      justifyContent: 'space-around',
    },
}));
  
  

const VoiceRecorder = () => {

    const [isParsingData, setIsParsingData] = useState(false);
    const classes = useStyles();

    function onRecordingComplete(blob) {
        console.log(blob);
        // send to backend blob information, render loader spinner and wait to response
        /* Send data */
        setTimeout(() => {
            setIsParsingData(false);
        }, 2500);
        /* load Spinner */
        setIsParsingData(true);

    }

    function onRecordingError(blob) {
        console.log(blob);
        //mandar alert con error
    }

    return (
        <Card className={classes.root}>
            <CardContent>
                { isParsingData ?
                    <Typography variant="subtitle2" align="center">
                        ¡Estamos validando la información!
                    </Typography>:
                    <div>
                        <Typography variant="subtitle1" align="center">
                            Presiona el icono y lee la frase:
                        </Typography>
                        <Typography variant="subtitle2" align="center">
                            "Tú sabes quién soy y reconoces el ritmo de mi voz"
                        </Typography>
                    </div>
                }
            </CardContent>
            <CardActions className={classes.action}>
                { isParsingData ?
                    <CircularProgress/> :
                    <Recorder
                        onRecordingComplete={onRecordingComplete}
                        onRecordingError={onRecordingError}
                    />
                }
            </CardActions>
        </Card>
  );
}

export default VoiceRecorder;