const reducer = (state, action) => {
    switch (action.type) {
      case 'LOGIN_REQUEST':
        return {
          ...state,
          user: action.payload,
        }
      case 'LOGOUT_REQUEST':
        return {
          ...state,
          user: action.payload,
        }
      case 'REGISTER_REQUEST':
         return {
           ...state,
           user: action.payload
         }
      case 'FACE_LOGIN_REQUEST':
        return {
          ...state,
          faceAuth: action.payload
        }
      case 'SET_CLINIC':
        return {
          ...state,
          clinic: action.payload
        }
      case 'SET_PATIENT':
        return {
          ...state,
          patients: action.payload
        }
      case 'SET_LASTCONTRAT':
        return {
          ...state,
          lastContrat: action.payload
        }
      default: 
        return state;
    }
  }
  
  export default reducer;