import React, { useState, useEffect } from 'react';
// redux
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import GeneralInfoForm from '../components/GeneralInfoForm';
import ShowAlert from '../components/ShowAlert';
import VerificationCode from '../components/VerificationCode';
import ConfirmAppointmentInfo from '../components/ConfirmAppointmentInfo';
import BackdropLoading from '../components/BackdropLoading';
import useBrowserContextCommunication from 'react-window-communication-hook';
// actions
import { setPatient, loginRequest, setClinic } from '../actions';
// Services
import { hisServices } from '../services/hisServices';
import { appointmentService } from '../services/appointmentService';
import { authenticationService } from '../services/authenticationService';
//Mapeo de clinicas
import { clinicsM } from '../services/clinicsM';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  grid: {
    margin: theme.spacing(3)
  },
  buttongrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: "10px",
  },

}));

function getSteps() {
  return ['Datos Generales', 'Confimación de identidad'];
}


const UpdateAndConfirmInfoAppointSteper = (props) => {

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [alert, handleAlert] = useState({ show: false, type: null, title: null, message: null, strong: null, action: null });
  const [form, setFormValues] = useState({
    documento: '',
    tipo_documento: '',
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    nombre_completo: '',
    fecha_nacimiento: '',
    genero: '',
    departamento: '',
    municipio: '',
    barrio: '',
    email: '',
    telefono: '',
    direccion: ''
  });
  const [verificationCodeInput, setVerificationCodeInput] = useState('a')
  const [loading, setLoading] = useState(false);
  const [disableFinishButton, setDisableFinishButton] = useState(false);
  let { dataFromCitapp } = useParams();
  const [showInfoAppointment, setShowInfoAppointment] = useState(true);
  const [hasToPay, setHasToPay] = useState({ show: false, id: null, valueToPay: 0 });
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [communicationState, postMessage] = useBrowserContextCommunication("CitappModalIframe");
  const [patients, setPatients] = useState([]);
  const [user, setUser] = useState({});
  const [clinic, setClinic] = useState({});
  const [changeValue, setChangeValue] = useState(false)

  const clinicsMapingCerotram = clinicsM.Maping();
  const clinics = clinicsM.Clinic();

  const handleVerificationCodeInput = event => {
    if (event.target.value == '') {
      setDisableFinishButton(true);
    } else {
      setDisableFinishButton(false);
    }
    setVerificationCodeInput(event.target.value);
  }

  const handleInput = event => {
    setFormValues({
      ...form,
      [event.target.name]: event.target.value
    })
  };

  const handleSubmit = async () => {


    const check = await hisServices.checkVerificationCode(user, verificationCodeInput);

    if (check.data.result) {
      setLoading(true);
      let data = await hisServices.updatePatient(form, clinic.cli_id);
      if (data.data.data.length > 0) {
        props.setPatient(data.data.data);
        const result = await appointmentService.insertMedicalAppointments(dataFromCitapp);
        console.log('RESULT', result)
        //console.log("Este es ewl resultado de una cita creada ",atob(dataFromCitapp))
        handleAlert({ ...alert, show: false })
        if (result.resultadoDAO.validacion === 'true') {
          // send createNewAppointment variable to dashboard to update news appointments
          postMessage({ createNewAppointment: true });
          const decodeData = JSON.parse(atob(dataFromCitapp));

          //check if appointment sheduled is from ordered appointments
          if (decodeData.isOrdered) {
            //send ordered apointment id (hiscsec) to backed
            //for add to black list and no show again in ordered appointment table
            const orderedId = localStorage.getItem('orderedAppointmentId');
            if (orderedId) await appointmentService.scheduleOrderedAppointmentCeroTram(
              user,
              orderedId
            );
          }

          if (result.dataRic?.tipo === "2") {
            // As citapp, if have dataRic then send data to crearTeleconsulta method 
            try {
              var resCreateAppointment = await appointmentService.crearTeleconsulta(result.dataRic);
              console.log('resCreateAppointment', resCreateAppointment)
            }
            catch (e) {
              console.log('error', e)
            }

            const valueToPay = parseInt(result.dataRic.cuota_moderadora)
            if (valueToPay > 0) setHasToPay({ show: true, id: resCreateAppointment.ID, valueToPay: valueToPay });
          } else if (result.dataRic?.tipo === "4") {
            // As citapp, if have dataRic then send data to crearConsultaPresencial method       
            try {
              var resCreateAppointment = await appointmentService.crearConsultaPresencial(result.dataRic);
              console.log('resCreateAppointment', resCreateAppointment)
            }
            catch (e) {
              console.log('error', e)
            }
            const valueToPay = parseInt(result.dataRic.cuota_moderadora)
            if (valueToPay > 0) setHasToPay({ show: true, id: resCreateAppointment.ID, valueToPay: valueToPay });
          }
          else {
            const valueToPay = parseInt(result.resultadoDAO.cuota_moderadora)
            if (valueToPay > 0) setHasToPay({ show: true, id: result.resultadoDAO.nro_cita, valueToPay: valueToPay });
          }
          // send result of schedule appointment to cerotram backed to register appotment
          await appointmentService.createAppointmentInCeroTram({
            citappData: result,
            user: user,
            respCreateAppointment: result.dataRic ? resCreateAppointment : null,
            clinic: clinic
          });
          handleAlert({
            ...alert,
            show: true,
            type: "success",
            message: `Su solicitud ha sido recibida, le informaremos cuando sea revisada. Esta información fue enviada al correo:`,
            strong: `${user.user.username}`
          })
        }
        else if (result.resultadoDAO.validacion === 'existe') {
          setShowInfoAppointment(false);
          handleAlert({
            ...alert,
            show: true,
            type: "error",
            message: `Cita ocupada`,
            strong: `El horario de la cita seleccionada no se encuentra disponible,favor seleccionar un nuevo horario.`
          })
        } else {
          setShowInfoAppointment(false);
          handleAlert({
            ...alert,
            show: true,
            type: "error",
            message: `Cita no agendada`,
            strong: `No se logro establecer comunicación con el sistema medico, por favor intente mas tarde.`
          })
        }
      }
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `Código erróneo`,
        strong: "Intenta nuevamente"
      })
      setTimeout(() => handleAlert({ ...alert, show: false }), 4000)
      setLoading(false);
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <GeneralInfoForm form={form} staff={false} handleInput={handleInput} clinicId={clinic.cli_id} patients={patients} user={user} />;
      case 1:
        return <VerificationCode
          user={user}
          handleVerificationCodeInput={handleVerificationCodeInput}
          handleAlert={handleAlert}
          disableFinishButton={disableFinishButton}
        />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const steps = getSteps();
  const handleNext = () => {
    if ((activeStep + 1) === 1) {
      setDisableFinishButton(true);
    } else {
      setDisableFinishButton(false);
    }
    var generalFormIsEmty = Object.entries(form).filter(a => a[1] === '').length !== 0;
    const contratsFormIsEmty = false;
    console.log("Valor del formulario al darle siguiente ", form);
    console.log("valor de generalFormIsEmty ", generalFormIsEmty);
    if (generalFormIsEmty){
      var formulario = 0
      if (form.primer_nombre === ''){
        console.log("Valor primer_nombre esta vacio ");
        formulario = 1
      }
      if (form.primer_apellido === ''){
        console.log("Valor primer_apellido esta vacio ");
        formulario = 1
      }
      if (form.tipo_documento === ''){
        console.log("Valor tipo_documento esta vacio ");
        formulario = 1
      }
      if (form.documento === ''){
        console.log("Valor documento esta vacio ");
        formulario = 1
      }
      if (form.telefono === ''){
        console.log("Valor telefono esta vacio ");
        formulario = 1
      }
      if (form.genero === ''){
        console.log("Valor genero esta vacio ");
        formulario = 1
      }
      if (form.fecha_nacimiento === ''){
        console.log("Valor fecha_nacimiento esta vacio ");
        formulario = 1
      }
      if (form.direccion === ''){
        console.log("Valor direccion esta vacio ");
        formulario = 1
      }
      if (form.departamento === ''){
        console.log("Valor departamento esta vacio ");
        formulario = 1
      }
      if (form.municipio === ''){
        console.log("Valor municipio esta vacio ");
        formulario = 1
      }
      if (form.barrio === ''){
        console.log("Valor barrio esta vacio ");
        formulario = 1
      }
      if (formulario === 0){
        generalFormIsEmty = false
      }
      console.log("Valor formulario ", formulario);
    }
    if ((activeStep === 0 && generalFormIsEmty) || (activeStep === 1 && contratsFormIsEmty)) {
      setLoading(true);
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: `Todos los campos son obligatorios`,
        strong: "Por favor Completa el Formulario"
      })
      setLoading(false);
      setDisableFinishButton(false);
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000)
    } else {
      handleAlert({ ...alert, show: false })
      setLoading(true);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoading(false);
    }

  };

  const handleBack = () => {
    setDisableFinishButton(false);
    setLoading(true);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setLoading(false);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getData = async (url) => {
    const requestOptions = {
      method: 'GET',
    };
    setLoading(true);
    const resp = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    setLoading(false);
    return resp;
  }

  const handlePay = () => {
    setBackdropLoading(true);
    const baseUrl = "https://portubien.com.co/appRic_desarrollo/"
    const url = baseUrl + "ApiTeleconsulta/PasarelaPagos/" + btoa(`${hasToPay.id}|${hasToPay.valueToPay}`)
    window.location.replace(url);
    console.log(url);
  }


  const loadDataFromHis = async () => {
    const infoFromCitapp = JSON.parse(atob(dataFromCitapp));
    console.log('infoFromCitapp', infoFromCitapp)
    const resp = await authenticationService.login(null, null, infoFromCitapp.data.MPCedu.trim(), clinicsMapingCerotram[infoFromCitapp.clinica]);

    const respPatients = resp.patients;
    const respUser = resp.ceroTramuser;
    const respClinicId = clinicsMapingCerotram[infoFromCitapp.clinica];
    const found = clinics.find(clinic => clinic.cli_id === respClinicId);

    console.log('Valor de resp ', resp);
    props.setPatient(resp.patients);
    props.loginRequest(resp.ceroTramuser);

    props.setClinic(found);

    if (resp.error) {
      console.log("error de resp ", resp.error)
      handleAlert({
        ...alert,
        show: true,
        type: "info",
        message: 'No existe el usuario',
        strong: 'Usuario no existente'
      })
      //setDisableFinishButton(true);
    } else {
      setPatients(respPatients);
      setUser(respUser);
      setClinic(found);

      console.log('Valor de patients ', patients);
      console.log("Valor de ceroTramuser ", user);
      console.log("Valor de form ", form);
      //console.log("Valor de clinic.cli_id ", clinic.cli_id);
      if (user) {
        setChangeValue(true);
      }


    }
  }

  useEffect(async () => {
    setBackdropLoading(true);
    await loadDataFromHis();

    const pat = patients ? patients[0] : null;
    handleAlert({
      ...alert,
      show: true,
      type: "info",
      message: `Para continuar`,
      strong: " Por favor Actualiza tu informacion de contacto"
    })

    if (!pat) {
      setLoading(true);
      setFormValues({
        ...form,
        primer_nombre: user?.user?.first_name,
        primer_apellido: user?.user?.last_name,
        tipo_documento: user?.card_id_type,
        documento: user?.card_id,
        fecha_nacimiento: user?.dob,
        email: user?.user?.username,
        nombre_completo: `${user?.user?.first_name} ${user?.user?.last_name}`
      });
      setLoading(false);
      handleAlert({
        ...alert,
        show: true,
        type: "info",
        message: `Aún no estás registrado `,
        strong: "Por favor Completa el Formulario"
      })

    } else {
      setLoading(true);
      setFormValues({
        ...form,
        primer_nombre: pat?.primer_nombre,
        segundo_nombre: pat?.segundo_nombre,
        primer_apellido: pat?.primer_apellido,
        segundo_apellido: pat?.segundo_apellido,
        tipo_documento: pat?.tipo_documento,
        documento: pat?.documento,
        telefono: pat?.telefono,
        genero: pat?.genero,
        fecha_nacimiento: pat?.fecha_nacimiento,
        direccion: pat?.direccion,
        departamento: pat?.departamento,
        municipio: pat?.municipio,
        barrio: pat?.barrio,
        email: pat?.email,
        nombre_completo: pat?.nombre_completo
      });
    }

    setBackdropLoading(false);
    setLoading(false);
  }, [changeValue])


  return (
    <Grid className={classes.root}>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.grid}
            >
              <Grid item>
                <Typography className={classes.instructions} align='center' >
                  <strong>INFORMACION DE LA SOLICITUD</strong>
                  {/* Informacion actualizada correctamente le enviamos un correo de confirmacion a 
                  <strong> {props.user.user.username}</strong> */}
                </Typography>
              </Grid>
              <Grid item>
                <ConfirmAppointmentInfo
                  infoAppointment={JSON.parse(atob(dataFromCitapp))}
                  showInfoAppointment={showInfoAppointment}
                  hasToPay={hasToPay}
                />
                <Grid item className={classes.grid}>
                  <Typography style={{ color: "#ff0000", width: '95%', marginTop: '15px' }} align='center' >
                    <i> {hasToPay.valueToPay > 0 ? "Recuerde realizar su pago. Espere confirmación"
                      : "Solicitud enviada, espere confirmación"}</i>
                  </Typography>
                </Grid>
              </Grid>
              {hasToPay.show && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePay}
                    style={{ marginTop: "15px" }}
                  >
                    Paga Aquí
                  </Button>
                </Grid>
              )}

            </Grid>
          </div>
        ) : (

          <Grid className={classes.grid}>
            {getStepContent(activeStep)}
            <Grid className={activeStep === 1 ? classes.buttongrid : classes.buttons}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Atrás
              </Button>
              {loading ?
                <Grid >
                  <CircularProgress style={{ marginTop: "25px", marginLeft: "5px" }} size={30} />
                </Grid> :
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={disableFinishButton}
                  onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                </Button>
              }
            </Grid>
          </Grid>
        )}
      </div>
      <BackdropLoading
        open={backdropLoading}
      />
    </Grid>
  );
}


const mapStateToProps = (state) => ({
  /* clinic: state.clinic,
  user: state.user,
  patients: state.patients */
})

const mapDispatchToProps = {
  setPatient,
  setClinic,
  loginRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAndConfirmInfoAppointSteper);