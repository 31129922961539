import React from 'react';

import {
    CssBaseline, Drawer, Box, AppBar, Toolbar, List, Typography, Divider, IconButton,
    Container, Grid, Paper, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, withStyles
} from '@material-ui/core';

import ScheduleIcon from '@material-ui/icons/Schedule';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ComputerIcon from '@material-ui/icons/Computer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import clsx from 'clsx';

const drawerWidth = 240;

export default function SideMenuDashboard({
    open,
    pageTitle,
    secondaryListItems,
    handlePresurgeryClick,
    handleIncapacityClick,
    handleAppointmentsControlClick,
    handleAppointmentsGeneralClick,
    handleSelectAppointMenu,
    handleCloseAppointMenu,
    handleOpenAppointMenu,
    handleOpenCloseUpdateInfoModal,
    handleHomeClick,
    handleDrawerClose,
    clinic,
    anchorEl,
    clinicLogo
}) {
    const classes = useStyles();
    return (
    <Drawer
        variant="permanent"
        classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
    >
        <div className={classes.toolbarIcon}>
            <img src={clinicLogo} alt="Logo2" width="70%"></img>
            <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
            </IconButton>
        </div>
        <Divider />
        <List>
            <ListItem
                selected={pageTitle === clinic.name || pageTitle === "Página Principal" ? true : false}
                button
                onClick={handleHomeClick}
            >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Página Principal" />
            </ListItem>
            <ListItem
                button
                onClick={handleOpenCloseUpdateInfoModal}>
                <ListItemIcon>
                    <CreateIcon />
                </ListItemIcon>
                <ListItemText primary="Datos Personales" />
            </ListItem>
            <ListItem
                aria-controls="customized-menu"
                aria-haspopup="true"
                button
                onClick={handleOpenAppointMenu //() => handleOpenCloseCitappModal("https://portubien.com.co/citapp_user_desa_0/")
                }>
                <ListItemIcon>
                    <ScheduleIcon />
                </ListItemIcon>
                <ListItemText primary="Agendar citas" />
            </ListItem>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseAppointMenu}
                autoFocus={false}
            >
                <StyledMenuItem onClick={() => handleSelectAppointMenu('agendarcita')}>
                    <ListItemIcon>
                        <EventNoteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Agendar Cita" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleSelectAppointMenu('teleorientacion')}>
                    <ListItemIcon>
                        <ComputerIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Teleorientación" />
                </StyledMenuItem>
            </StyledMenu>
            <ListItem
                button
                onClick={handleAppointmentsGeneralClick}
                selected={pageTitle === "Mis Citas" ? true : false}
            >
                <ListItemIcon>
                    <FormatAlignJustifyIcon />
                </ListItemIcon>
                <ListItemText primary="Mis Citas" />
            </ListItem>
            <ListItem
                button
                onClick={handleAppointmentsControlClick}
                selected={pageTitle === "Citas de control" ? true : false}
            >
                <ListItemIcon>
                    <AddCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Citas de Control" />
            </ListItem>
            <ListItem
                button
                onClick={handleIncapacityClick}
                selected={pageTitle === "Mis Incapacidades" ? true : false}
            >
                <ListItemIcon>
                    <LocalHospitalIcon />
                </ListItemIcon>
                <ListItemText primary="Mis Incapacidades" />
            </ListItem>
            <ListItem
                button
                onClick={handlePresurgeryClick}
                selected={pageTitle === "Procedimientos Prequirúrgicos" ? true : false}
            >
                <ListItemIcon>
                    <LocalHospitalIcon />
                </ListItemIcon>
                <ListItemText primary="Prequirúrgicos" />
            </ListItem>
        </List>
        <Divider />
        <List>{secondaryListItems}</List>
    </Drawer>
    )
}

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 20px',
        ...theme.mixins.toolbar,
    },
}))

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);