import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridFilterToolbarButton } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import ShowAlert from '../../components/ShowAlert';
import Button from '@material-ui/core/Button';
// redux
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
import useBrowserContextCommunication from 'react-window-communication-hook';
// Services
import { appointmentService } from '../../services/appointmentService';
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';
//icons
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';

const clinicsMaping =  clinicsM.Maping();

const PriorityAppointmentTable = (props) => {

  const [title, setTitle] = useState('Solicitud de citas');
  const [appointmentSelected, setAppointmentSelected] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [communicationState, postMessage] = useBrowserContextCommunication("DashboardStaffChanel");


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridFilterToolbarButton />
        {/* <Tooltip title="Aprovar cita">
            <IconButton
              //disabled={!hasToPay.show}
              //onClick={() => handlePay()}
            >
              <CheckCircleIcon color="primary"/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancelar cita">
            <IconButton
              //disabled={!(linkToTransferFiles !== "#")}
              //onClick={() => openTransferFileModal()}
            >
              <CancelIcon style={{ color: red[700] }} />
            </IconButton>
          </Tooltip> */}
      </GridToolbarContainer>
    );
  }

  const columnsPriorityAppointm = [
    {
      field: 'created', headerName: 'Fecha', width: 150, renderCell: renderCellExpand,
      valueGetter: (params) => new Date(params.row.created).toLocaleString()
    },
    {
      field: 'MPCedu', headerName: 'Cedula', width: 130, renderCell: renderCellExpand,
      valueGetter: (params) => params.row.data.MPCedu
    },
    {
      field: 'MPNOMC', headerName: 'Nombre', width: 200, renderCell: renderCellExpand,
      valueGetter: (params) => params.row.data.MPNOMC
    },
    { field: 'description', headerName: 'Descripción', width: 200, renderCell: renderCellExpand },
    {
      field: 'especialidad', headerName: 'Especialidad', width: 200, renderCell: renderCellExpand,
      valueGetter: (params) => params.row.data.nameEsp
    },
    {
      field: 'agendar',
      headerName: 'Agendar',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          disableElevation
          size="small"
          color="primary"
          disabled={!(params.row.status === 'AUTORIZADO')}
          onClick={() => handleSchedule(params)}
        >
          Agendar
        </Button>
      ),
    },
    /* {
        field: 'OrdenMed',
        headerName: 'Orden Médica',
        width: 100,
        renderCell: (params) => (
            <IconButton
              onClick={() => handleDocVisualizer(params)}
            >
              <DescriptionIcon color="primary"/>
            </IconButton>
        ),
      }, */
  ]

  const getData = async (url) => {
    const requestOptions = {
      method: 'GET',
    };
    const resp = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    return resp;
  }


  const handleSchedule = async (params) => {
    localStorage.setItem('priorityAppointmentId', params.row.HISCSEC)
    // get last contrat
    /* const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getUltimoContratoPaciente/${props.clinic.cli_id}/${props.user.card_id}/CC`;
    const lastContratData = await getData(urlLastContrat);
    const contrat = lastContratData.data[0].contrato */
    //maping clinic id
    const clinicId = clinicsMaping[props.clinic.cli_id]
    //const MECodE = params.row.MECodE // codigo especialidad
    //const EspCodPIn = params.row.EspCodPIn.replace(/\s/g,'') // codigo tipo de cita sin espacios
    const urlCitapp = `https://portubien.com.co/citapp_user_desa_0/login/view_index2/${clinicId}/${params.row.data.MPTDoc.trim()}/${params.row.data.MPCedu.trim()}/${params.row.data.codCont.trim()}/${params.row.data.codeEsp}/${params.row.data.codeCups}/0_staff/schedule_priority_petition/${process.env.REACT_APP_FLAG_PRODUCTION_DEVELOP}/${params.row.priorityPetitionApointmentId}`;
    //const urlCitapp = "https://portubien.com.co/citapp_user_desa_0/login/view_index2"
    props.handleOpenCloseCitappModal(urlCitapp);
  }
  const handleDocVisualizer = (params) => {
    console.log("Url de acceso ", process.env.REACT_APP_URL_HOST + params.row.uploaded_file);
    props.handleOpenCloseCitappModal(process.env.REACT_APP_URL_HOST + params.row.uploaded_file);
  }

  const handleSeeContratctButton = (params) => {
    const ceroTramUser = params.row?.cerotram_user;
    const datesAndSpecialistInformation = {
      date1: params.row.date1,
      date2: params.row.date2,
      date3: params.row.date3,
      need_the_same_specialist: params.row.need_the_same_specialist,
      url_to_view_doc: params.row.uploaded_file
    }
    props.setUserSelected(ceroTramUser);
    props.setDatesAndSpecialistInformation(datesAndSpecialistInformation);
    props.setCitaNumSelected(params.row.cita_num);
    props.handleOpenCloseUpdateInfoModal(params.row?.cerotram_user, datesAndSpecialistInformation);
  }

  const handleRowSelected = async (params) => {
    handleSeeContratctButton(params);
    //setAppointmentSelected(params);
    props.setPriorityPetitionApointmentId(params.row.priorityPetitionApointmentId)
    console.log("params en priority selectedrow ", params);
    //handleDocVisualizer(params);
  }

  const handleCheckBox = (newSelection) => {

    const newSet = new Set(newSelection.selectionModel);
    const lastSet = new Set(selectionModel);
    let newRowselected = [...newSet].filter(x => !lastSet.has(x));
    if (newRowselected.length === 0) newRowselected = newSelection.selectionModel;
    if (newRowselected.length > 1) newRowselected = []

    //const params = {row: props.rows[parseInt(newRowselected[0])]}
    //handleRowSelected(params.row);
    //console.log("params en select checkboz ",props.rows);
    //console.log("row",newSelection);
    /*  if(newRowselected.length === 1){
       //console.log("params desde checkbox ",params);
       //handleRowSelected(params)
       //setAppointmentSelected(appointmentSelected);
     }else{
       //setLinkToTransferFiles("#")
       //setHasToPay({show: false})
       //setAppointmentSelected(appointmentSelected);
     } */
    setSelectionModel(newRowselected);
  }

  const getAllPriorityPetitionApointments = async () => {
    //Get data
    props.setPriorityLoading(true);
    const resp = await staffAppointmentService.getAllPriorityPetitionAppointments(props.clinic.cli_id);
    // parse data
    const rowsToRender = resp.data.map((row, index) => {
      const appointmentInfo = row.appointment_info_from_citapp;
      return {
        ...appointmentInfo,
        description: row.description,
        priorityPetitionApointmentId: row.id,
        id: index,
        cerotram_user: row.cerotram_user,
        status: row.status,
        date1: row.date1,
        date2: row.date2,
        date3: row.date3,
        need_the_same_specialist: row.need_the_same_specialist,
        created: row.created,
        uploaded_file: row.uploaded_file,
      }
    })
    console.log("PRINT DATA FROM PYTHON", rowsToRender)
    setRows(rowsToRender);
    props.setPriorityLoading(false);
  }



  useEffect(() => {
    getAllPriorityPetitionApointments();
  }, [])



  useEffect(() => {
    if (communicationState.lastMessage) {
      const { PriorityStatusChanged } = communicationState.lastMessage
      if (PriorityStatusChanged) {
        getAllPriorityPetitionApointments();
      }
    }
  }, [communicationState])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        rows={rows}
        columns={columnsPriorityAppointm}
        pageSize={4}
        components={{
          Toolbar: CustomToolbar
        }}
        disableColumnMenu={true}
        loading={props.priorityLoading}
        onRowClick={(params) => {
          if (params.row.status === 'PENDIENTE') handleRowSelected(params);
        }}
        //checkboxSelection={true}

        hideFooterSelectedRowCount
        onSelectionModelChange={(newSelection) => {
          handleCheckBox(newSelection);
        }}
        selectionModel={selectionModel}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(PriorityAppointmentTable);