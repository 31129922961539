import React, { useState, useRef, useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';


import Image from 'material-ui-image'
import CardMedia from '@material-ui/core/CardMedia';

import agendarImg from '../assets/static/tutorial_images/agendar_cita.jpeg';
import verIncap from '../assets/static/tutorial_images/ver_incapacidad.jpeg';
import proxCitas from '../assets/static/tutorial_images/ver_proximas_citas.jpeg';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


// Services
import { chatService } from '../services/chatService';

const useStyles = makeStyles((theme) => ({
    toolbar: {

    },
    button: {
        height: '100%',
    },
    paper: {
        height: '100%',
        width: '1hv',
        overflow: 'auto',
        padding: 15,
        background: 'linear-gradient(rgba(79, 85, 87,0.7),rgba(9, 127, 114,0.3))',
        //backgroundImage: `url(${Background})`, 
        backgroundPosition: 'center 10%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
    },
    paper_message: {
        padding: 5,
        borderRadius: 12,
        maxWidth: '90%'
    },
    gridcontainer: {
        width: '100%',
        maxWidth: '100%'
    },
    grid_input_button: {
        width: '100%',
        maxWidth: '100%'
    },
    paper_message_client: {
        backgroundColor: '#0db6a4',
        padding: 3,
        borderRadius: 12,
        marginRight: 10,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        maxWidth: '90%'
    },
    paper_message_server: {
        //backgroundColor: 'rgba(101, 137, 133,0.9)',
        padding: 3,
        borderRadius: 12,
        marginLeft: 10,
        marginTop: theme.spacing(1),
        maxWidth: '90%'
    },
    text_box_server: {
        color: '#097f72',
        fontSize: 15,
        marginRight: 4,
        marginLeft: 4,
        margin: 4
    },
    text_box: {
        color: 'white',
        fontSize: 15,
        marginRight: 4,
        marginLeft: 4,
        margin: 4
    },
    text_header: {
        color: 'white',
        fontSize: 20
    }
}));


const ChatWindow = (props) => {
    const classes = useStyles();
    const divRef = useRef(null);
    //const divRef = useRef<HTMLInputElement>(null);

    const [message, setMessage] = useState()
    const [email, setEmail] = useState(props.email)
    const [options, setOptions] = useState([])
    const [messagesServer, setMessagesServer] = useState([]);


    const sendPetition = async (value) => {
        const resp = await chatService.sendMessage(email, value);
        setMessagesServer([...messagesServer, { 'message': value, 'emisor': 'user' }, resp.data]);
        setOptions([resp.data.options])
    }

    const handleSend = () => {
        sendPetition(message)
        setMessage('')
    }

    const handleListClick = (a) => {
        sendPetition(a[0])
    }

    useEffect(() => {
        sendPetition(0)
    }, [])

    useEffect(() => {
        divRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }, [messagesServer])




    return (
        <Grid>
            <CssBaseline />
            <Grid>
                <AppBar position="relative">
                    <Toolbar className={classes.toolbar}>
                        <Typography className={classes.text_header}>
                            Sagrado Corazón
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Paper className={classes.paper}>
                    <Grid
                        className={classes.gridcontainer}
                        container
                        direction="column"
                        ref={divRef}
                    >
                        {messagesServer.map((server, index) => (
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems={server.emisor == 'server' ? "flex-start" : "flex-end"}
                            >

                                {server.emisor == 'server' ?
                                    <Paper className={classes.paper_message}>
                                        <Box className={classes.paper_message_server} key={index}>
                                            <Grid item>
                                                <Typography className={classes.text_box_server} >
                                                    {server.message}

                                                    {server.message.includes('whatsapp')
                                                        &&
                                                        <a href="https://api.whatsapp.com/send?phone=573167019941"> aquí</a>}

                                                    {server.message.includes('sugerencias')
                                                        &&
                                                        <a href="https://www.clinicasagradocorazon.com.co/ayudanos-a-mejorar/"> aquí</a>}

                                                    {server.message.includes('contraseña')
                                                        &&
                                                        <a href="https://dev-ct.portubien.co/resetpassword"> aquí</a>}
                                                </Typography>

                                                {server.message.includes('agendar') &&
                                                    <CardMedia
                                                        component='img'
                                                        //src={mainStaffDashboard}
                                                        image={agendarImg}
                                                    />}

                                                {server.message.includes('incapacidades') &&
                                                    <CardMedia
                                                        component='img'
                                                        //src={mainStaffDashboard}
                                                        image={verIncap}
                                                    />}

                                                {server.message.includes('próximas') &&
                                                    <CardMedia
                                                        component='img'
                                                        //src={mainStaffDashboard}
                                                        image={proxCitas}
                                                    />}

                                            </Grid>
                                            <List>
                                                {server.options.map((a, i) => (

                                                    <Grid>
                                                        <ListItem button divider onClick={() => handleListClick(a)}>
                                                            <Typography className={classes.text_box_server} >
                                                                <strong>{a}</strong>
                                                            </Typography>
                                                        </ListItem>
                                                        <Divider />
                                                    </Grid>

                                                ))}

                                            </List>

                                        </Box>
                                    </Paper>
                                    :
                                    <Box className={server.emisor == 'server' ? classes.paper_message_server : classes.paper_message_client} key={index}>
                                        <Grid item>
                                            <Typography className={server.emisor == 'server' ? classes.text_box_server : classes.text_box} >
                                                {server.message}
                                            </Typography>
                                        </Grid>
                                    </Box>
                                }
                            </Grid>
                        ))}

                    </Grid>
                </Paper>

                <Grid
                >

                    <Grid item position="relative">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth={true}
                            type="number"
                            id="message"
                            label="Escribe un mensaje"
                            name="message"
                            value={message}
                            onChange={(e) => setMessage(parseInt(e.target.value))}
                            InputProps={{
                                endAdornment: <Grid>
                                    <Divider orientation="vertical" flexItem />
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        size="large"
                                        onClick={() => {
                                            handleSend()
                                        }}
                                        disabled={message === "" ? true : false}>
                                        enviar
                                    </Button>
                                </Grid>
                            }}
                        />
                    </Grid>
                    <Grid item>

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default ChatWindow;