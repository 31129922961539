import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
// redux
import { connect } from 'react-redux';


function ContactInfoForm(props) {

  const [regimen, setRegimen] = useState('');
  const [eps, setEps] = useState('');
  const [level, setLevel] = useState('');
  const [allEps, setAllEps] = useState([]);
  const [allRegimen, setAllRegimen] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [loadingEps, setLoadingEps] = useState(false);
  const [loadingRegimen, setLoadingRegimen] = useState(false);
  const [loadingLevel, setLoadingLevel] = useState(false);

  const getData = async (url) => {
    const requestOptions = {
      method: 'GET',
    };
    const resp = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    return resp;
  }

  const handleEPSChange = async (contract) => {
    /* Get All regiments for clinic id and number contract */
    setLoadingRegimen(true);
    const urlRegimens = process.env.REACT_APP_URL_HOST_AMS + `/api/getListRegimenes/${props.clinic.cli_id}/${contract}`
    const respAllRegimens = await getData(urlRegimens);
    setAllRegimen(respAllRegimens.data);
    setLoadingRegimen(false);
  }

  const handleRegimenChange = async (codigo_regimen) => {
    /* Get All levels for clinic id and number contract */
    setLoadingLevel(true);
    const contract = eps;
    const urlLevels = process.env.REACT_APP_URL_HOST_AMS + `/api/getListNivelesUsuario/${props.clinic.cli_id}/${contract}/${codigo_regimen}`
    const respAllLevels = await getData(urlLevels);
    setAllLevels(respAllLevels.data);
    setLoadingLevel(false);
  }

  useEffect(async () => {

    /* Get All apss for clinic id */
    setLoadingEps(true);
    const urlContracts = process.env.REACT_APP_URL_HOST_AMS + `/api/getListContratos/${props.clinic.cli_id}`
    const respAllEps = await getData(urlContracts);
    
    setAllEps(respAllEps.data);
    setLoadingEps(false);
    const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getContratoPacienteSelect/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`;
    const lastContratData = await getData(urlLastContrat);
    console.log('api de contrato ',lastContratData);
    console.log('datos del contrato ',lastContratData.data);
    if (lastContratData.data) {
      setEps(lastContratData.data[0].contrato);
      await handleEPSChange(lastContratData.data[0].contrato);
      setRegimen(lastContratData.data[0].regimen);
      const urlLevels = process.env.REACT_APP_URL_HOST_AMS + `/api/getListNivelesUsuario/${props.clinic.cli_id}/${lastContratData.data[0].contrato}/${lastContratData.data[0].regimen}`
      const respAllLevels = await getData(urlLevels);
      setAllLevels(respAllLevels.data);
      setLevel(lastContratData.data[0].nivel_usuario);
    }
  }, [])

  return (
    <Container component="main" maxWidth="sm">
      <Typography variant="h6" gutterBottom>
       INFORMACIÓN DE CONTRATO
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12}>
          <InputLabel id="epsLabel">Eps</InputLabel>
          <Select
            required
            labelId="epsLabel"
            id="eps"
            name="contrato"
            value={eps}
            onChange={(event) => {
              setEps(event.target.value);
              handleEPSChange(event.target.value);
              props.handleInput(event);
            }}
            fullWidth
          >
            {allEps && (allEps.map((eps, index) => (
              <MenuItem key={index} value={eps.contrato}>{eps.empresa_descripcion}</MenuItem>
            )))}
          </Select>
          {loadingEps && <LinearProgress />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel id="RegimenLabel">Regimen</InputLabel>
          <Select
            required
            labelId="RegimenLabel"
            id="regimen"
            name="regimen"
            value={regimen}
            onChange={(event) => {
              setRegimen(event.target.value);
              handleRegimenChange(event.target.value);
              props.handleInput(event);
            }}
            fullWidth
          >
            {allRegimen && (allRegimen.map((regimen, index) => (
              <MenuItem key={index} value={regimen.codigo_regimen}>{regimen.nombre_regimen}</MenuItem>
            )))}
          </Select>
          {loadingRegimen && <LinearProgress />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel id="LevelLabel">Level</InputLabel>
          <Select
            required
            labelId="LevelLabel"
            id="level"
            name="nivel_usuario"
            value={level}
            onChange={(event) => {
              setLevel(event.target.value);
              props.handleInput(event);
            }}
            fullWidth
          >
            {allLevels && (allLevels.map((level, index) => (
              <MenuItem key={index} value={level.codigo_tipo}>{level.tipo_afiliado}</MenuItem>
            )))}
          </Select>
          {loadingLevel && <LinearProgress />}
        </Grid>
      </Grid>
    </Container>
  );
}

/* const mapStateToProps = (state) =>({
  clinic: state.clinic,
  user: state.user,
  patients: state.patients
}) */

/* const mapDispatchToProps = {
  setClinic,
} */

//export default connect(mapStateToProps, null)(ContactInfoForm);
export default ContactInfoForm;