import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ShowAlert from '../../components/ShowAlert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useBrowserContextCommunication from 'react-window-communication-hook';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DescriptionIcon from '@material-ui/icons/Description';
import ContractInfoForm from '../../components/staff/ContractInfoForm'
import UploadDocument from '../../components/UploadDocument'
// Services
import { hisServices } from '../../services/hisServices';
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
import { appointmentService } from '../../services/appointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  field: {
    width: "100%",
    marginTop: "2px",
  },
  grid: {
    height: "0px",
    marginLeft: "92%",
  },
  principalCont: {
    paddingLeft: "20px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingRight: "20px",
  },
  principalCont1: {
    paddingLeft: "10px",
    paddingTop: "1px",
    paddingBottom: "10px",
    paddingRight: "10px",
  },
  principalCont2: {
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "10px",
  },
}));

const MedicalOrdersAttendModal = (props) => {
  const classes = useStyles();
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [formDataDocument, setFormDataDocument] = useState(false);
  const [status, setStatus] = useState(props.paramsRowIncapacity?.estado);
  const [allStatus, setAllStatus] = useState(['TRAMITE INTERNO', 'RADICADO POR LA EPS']);
  const [communicationState, postMessage] = useBrowserContextCommunication("DashboardStaffChanel");
  const [disabledModal, setDisabledModal] = useState(false);
  const [description, setDescription] = useState("");
  const [alert, handleAlert] = useState({ show: false, type: null, title: null, message: null, strong: null, action: null });

  const [clinicalHistory, setClinicalHistory] = useState("")
  const [formulaMedica, setFormulaMedica] = useState("")
  const [incapacidad, setIncapacidad] = useState("")
  const [ordenes, setOrdenes] = useState("")

  const clinicsMaping =  clinicsM.Maping();


  const [notifyLoading, setNotifyLoading] = useState(false)
  const [notificationForm, setNotificationForm] = useState({
    "title": "",
    "message": ""
  });
  const [open, setOpen] = useState(false);

  //Dialog Functions
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotifyTextInput = (event) => {
    setNotificationForm({ ...notificationForm, [event.target.name]: event.target.value })
  }

  const handleOpenDialogForm = (action) => {
    if (action === 'Recordar') {
      setNotificationForm({
        ...notificationForm,
        "title": "Reclamar medicamentos",
        "message": `Ya puede reclamar los siguientes medicamentos, paracetamol, en la siguiente dirección: Av. 6N #18N-44 Versalles, Audifarma`
      })
    }
    setOpen(true);
  }

  const handleNotify = async () => {
    const cardId = props.paramsRowMedicalOrders?.CitCed?.trim();
    const extra = null;
    try {
      setNotifyLoading(true)
      const resp = await staffAppointmentService.notifyToCardId(cardId, 'CC', notificationForm.title, notificationForm.message, extra);
      console.log('respuesta', resp)
      setNotifyLoading(false)
      handleClose(); //close alert dialog
      handleAlert({
        ...alert,
        show: true,
        type: "success",
        message: ``,
        strong: "Notificación enviada correctamente"
      })
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000);

    } catch (error) {
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: ``,
        strong: "Error enviando notificación, inténtelo nuevamente"
      })
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000);

      console.log(error)
      setNotifyLoading(false)
    }
  } //end Dialog functions



  useEffect(() => {
    setClinicalHistory("");
    setIncapacidad("");
    setOrdenes("");
    setFormulaMedica("");
    handleGetDocumentsAppointments(props.clinic.cli_id, props.paramsRowMedicalOrders?.CitCed?.trim(), props.paramsRowMedicalOrders.CitNum)
    console.log("datos handleGetDocumentsAppointments" ,props);
    setDisabledModal(false);
  }, [props.paramsRowMedicalOrders?.CitNum])

  const handleGetDocumentsAppointments = async (clinic_id, card_id, cita_num) => {
    const resp = await appointmentService.getDocumentsAppointments(clinic_id, card_id, cita_num);
    console.log('respuesta', resp)

    if (resp.HC) {
      setClinicalHistory(resp.HC);
    }
    if (resp.Incapacidad) {
      setIncapacidad(resp.Incapacidad);
    }
    if (resp.Ordenes) {
      setOrdenes(resp.Ordenes);
    }
    if (resp.Formula_Medica) {
      setFormulaMedica(resp.Formula_Medica);
    }

  }


  const handleCloseButton = () => {
    setCloseModal(true);
    setDisabledModal(false);
    //setStatus('TRAMITE INTERNO');
    setDescription("");
    props.handleOpenCloseMedicalOrdersModal();
    postMessage({
      reloadIncapacities: true
    });
  }

  const handleDocumentToUpload = (document) => {
    setFormDataDocument(document);

  }

  const viewDocumentIncapacity = async () => {
    //Get data
    setLoadingDocument(true);
    console.log("Props Value", props.paramsRowMedicalOrders);
    const clinicId = clinicsMaping[props.clinic.cli_id]
    /* try {
      const resp = await staffAppointmentService.viewDocumentIncapacity(clinicId,
        props.paramsRowIncapacity.nro_Docum,
        props.paramsRowIncapacity.Folio,
        null);
      var file = new Blob([resp.data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

    } catch {
      alert("Error al crear archivo");
    } */
    setLoadingDocument(false);
  }

  const viewDocuments = (url) => {
    window.open(url)
  }

  const handleOrderedCitapp = () => {
    const url = `https://portubien.com.co/citapp_user_desa_0/login/view_index2/${props.clinic.cli_id}/CC/${props.paramsRowMedicalOrders?.CitCed?.trim()}/${props.paramsRowMedicalOrders?.CitNroCto?.trim()}/${props.paramsRowMedicalOrders.CitEspMed}/${props.paramsRowMedicalOrders?.CitPro?.trim()}/0_tram/${process.env.REACT_APP_FLAG_PRODUCTION_DEVELOP}`
    console.log('url citapp', url)
    props.handleOpenCloseCitappModal(url)
  }

  const handleNotifyFormulaMedica = async () => {
    const title = 'Recordatorio de Medicamentos';
    const message = 'Por favor reclamar sus medicamentos en la siguiente dirección: Av. 6N #18N-44 Versalles, Audifarma'
    const extra = null;
    try {
      setNotifyLoading(true)
      const resp = await staffAppointmentService.notifyToCardId(props.paramsRowMedicalOrders?.CitCed?.trim(), 'CC', title, message, extra);
      console.log('respuesta', resp)
      setNotifyLoading(false)

      handleAlert({
        ...alert,
        show: true,
        type: "success",
        message: ``,
        strong: "Notificación enviada correctamente"
      })
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000);

    } catch (error) {
      console.log(error)
      setNotifyLoading(false)
    }

  }

  const changeIncapacityStatus = async () => {
    //Send data
    setDisabledModal(true);
    setLoadingSubmit(true);
    console.log("valor de archivo ", formDataDocument)
    try {
      const formData = new FormData();
      //formData.append("file", props.clinic);
      const resp = await staffAppointmentService.changeIncapacityStatus(props.clinic, props.paramsRowIncapacity, status, description, formDataDocument);
      //const resp = await staffAppointmentService.changeIncapacityStatus(props.clinic, props.paramsRowIncapacity, status, description);
      handleAlert({
        ...alert,
        show: true,
        type: "success",
        message: ``,
        strong: "INCAPACIDAD RADICADA SATISFACTORIAMENTE"
      })
      setDisabledModal(true);
      setLoadingSubmit(false);

    } catch {
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: ``,
        strong: "ERROR AL RADICAR, INTENTE NUEVAMENTE"
      })
    }

    setLoadingSubmit(false);
    setTimeout(() => handleAlert({ ...alert, show: false }), 5000);
    //await getAllIncapacitiesByDate(selectedDate);
  }


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleOpenCloseMedicalOrdersModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Fade in={props.open}>
        <Grid
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={1}
        >
          <Grid className={classes.grid} >
            <IconButton edge="start" size="medium" onClick={() => handleCloseButton()} color="primary">
              <CloseIcon style={{ color: red[400] }} />
            </IconButton>
          </Grid>
          <Paper variant="outlined" className={classes.principalCont}>
            <Container component="main" maxWidth="sm" spacing={3}>
              <div style={{ width: "100%", marginBottom: "5px" }}> {alert.show && (
                <ShowAlert
                  {...alert}
                />
              )}</div>
              <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <Paper variant="outlined" className={classes.principalCont1}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography variant="h6" gutterBottom>
                          Documentación de la cita
                        </Typography>
                      </Grid>
                      {formulaMedica || ordenes || incapacidad || clinicalHistory ? ""
                        :
                        <Grid item style={{ marginTop: 5 }}>
                          <Paper variant="outlined" className={classes.principalCont2}>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography component="h5">
                                  <strong>No existe documentación para esta cita</strong>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      }

                      {formulaMedica &&
                        <Grid item >
                          <Paper variant="outlined" className={classes.principalCont2}>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography component="h5">
                                  <strong>Fórmula Médica</strong>
                                </Typography>
                              </Grid>
                              <Grid item >
                                <Button
                                  size="small"
                                  disabled={disabledModal || loadingDocument}
                                  variant="contained"
                                  //color="primary"
                                  startIcon={<DescriptionIcon />}
                                  onClick={() => viewDocuments(formulaMedica)}
                                >
                                  Ver
                                </Button>

                                {notifyLoading ?

                                  <CircularProgress size={30} style={{ marginLeft: 7 }} />
                                  :
                                  <Button
                                    style={{ marginLeft: 7 }}
                                    size="small"
                                    disabled={disabledModal || notifyLoading}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DescriptionIcon />}
                                    onClick={() => handleOpenDialogForm('Recordar')}
                                  >
                                    Notificar a paciente
                                  </Button>
                                }

                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      }

                      {ordenes &&
                        <Grid item style={{ marginTop: 5 }}>
                          <Paper variant="outlined" className={classes.principalCont2}>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Typography component="h5">
                                  <strong>Ordenamiento Médico</strong>
                                </Typography>
                              </Grid>
                              <Grid item >
                                <Button
                                  size="small"
                                  disabled={disabledModal || loadingDocument}
                                  variant="contained"
                                  //color="primary"
                                  startIcon={<DescriptionIcon />}
                                  onClick={() => viewDocuments(ordenes)}
                                >
                                  Ver
                                </Button>
                                <Button
                                  style={{ marginLeft: 7 }}
                                  size="small"
                                  disabled={disabledModal || loadingDocument}
                                  variant="contained"
                                  color="primary"
                                  startIcon={<DescriptionIcon />}
                                  onClick={() => handleOrderedCitapp()}
                                >
                                  Agendar Cita a paciente
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      }

                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item style={{ marginTop: 5, padding: 20 }}>

                          {clinicalHistory &&
                            <Button
                              size="small"
                              disabled={disabledModal || loadingDocument}
                              variant="contained"
                              //color="primary"
                              startIcon={<DescriptionIcon />}
                              onClick={() => viewDocuments(clinicalHistory)}
                            >
                              Historia Clínica
                            </Button>
                          }
                          {incapacidad &&
                            <Button
                              size="small"
                              style={{ marginLeft: 7 }}
                              disabled={disabledModal || loadingDocument}
                              variant="contained"
                              //color="primary"
                              startIcon={<DescriptionIcon />}
                              onClick={() => viewDocuments(incapacidad)}
                            >
                              Incapacidad
                            </Button>
                          }

                        </Grid>
                      </Grid>

                    </Grid>
                  </Paper>
                </Grid>
              </Grid>

            </Container>
          </Paper>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Enviar notificación a paciente</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Por favor diligencie los campos presentes, tenga en cuenta que esta notificación se enviará al dispositivo movil del paciente
              </DialogContentText>
              <TextField
                autoFocus
                style={{ marginTop: 10 }}
                variant='outlined'
                name="title"
                onChange={(event) => handleNotifyTextInput(event)}
                value={notificationForm.title}
                id="title"
                label="Título de la notificación"
                type="text"
                fullWidth
              />
              <TextField
                style={{ marginTop: 30 }}
                variant='outlined'
                rows={4}
                multiline
                onChange={(event) => handleNotifyTextInput(event)}
                value={notificationForm.message}
                margin="dense"
                name="message"
                id="message"
                label="Mensaje de la notificación"
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                disabled={notifyLoading}
                color="primary"
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button
                onClick={handleNotify}
                disabled={notifyLoading}
                color="primary"
                variant="contained"
              >
                Enviar notificación
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Fade>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(MedicalOrdersAttendModal);