import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import UpdateAndConfirmInfoAppointSteper from './UpdateAndConfirmInfoAppointSteper'
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '5%',
    },
  }));



const ConfirmAppointment = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <UpdateAndConfirmInfoAppointSteper/>
        </div>
    )
}

export default ConfirmAppointment;