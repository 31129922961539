import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';
// Redux
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './reducers';
// custum theme
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
// Services
import { storeService } from './services/storeService';
//Mapeo de clinicas
import { clinicsM } from './services/clinicsM';

const theme = createMuiTheme({
  palette: {
    primary: {
      // Ospedale color primary.
      main: '#0db6a4',
      contrastText: '#EDEDF7',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#b3c7c6',
    },
  },
});

const clinicsMaping = clinicsM.Maping();

const initialState = {
  faceAuth: null,
  user: {},
  clinic: null,
  patients: {},
  clinicsMaping: clinicsMaping,
  lastContrat: '',
}

const currentStore = storeService.loadFromLocalStorage()
if (currentStore === undefined) localStorage.setItem('state', JSON.stringify(initialState));

const persistedState = storeService.loadFromLocalStorage();

const store = createStore(reducer, persistedState);

store.subscribe(() => storeService.saveToLocalStorage(store.getState()));

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
