import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import ShowAlert from '../components/ShowAlert';
import Button from '@material-ui/core/Button';
// redux
import { connect } from 'react-redux';
import renderCellExpand from './renderCellExpand';
// Services
import { appointmentService } from '../services/appointmentService';
//Mapeo de clinicas
import { clinicsM } from '../services/clinicsM';

const clinicsMaping = clinicsM.Maping();

const OrderedAppointmentsTable = (props) => {

  const [title, setTitle] = useState('Citas de Control Ordenadas');

  const columnsOrderedAppointm = [
    {
      field: 'agendar',
      headerName: 'Agendar',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          disableElevation
          size="small"
          color="primary"
          onClick={() => handleSchedule(params)}
        >
          Agendar
        </Button>
      ),
    },
    { field: 'fecha', headerName: 'Fecha', width: 110, renderCell: renderCellExpand },
    { field: 'MENomE', headerName: 'Especialidad', width: 200, renderCell: renderCellExpand },
    { field: 'Estado', headerName: 'Estado', width: 100, renderCell: renderCellExpand },
    { field: 'IntObsOrd', headerName: 'Observaciones', width: 150, renderCell: renderCellExpand },
    { field: 'PrNomb', headerName: 'Procedimiento', width: 290, renderCell: renderCellExpand },
  ]

  const getData = async (url) => {
    const requestOptions = {
      method: 'GET',
    };
    const resp = await fetch(
      url,
      requestOptions
    ).then(resp => resp.json())
    return resp;
  }


  const handleSchedule = async (params) => {
    localStorage.setItem('orderedAppointmentId', params.row.HISCSEC)
    // get last contrat
    /* const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getUltimoContratoPaciente/${props.clinic.cli_id}/${props.user.card_id}/CC`;
    const lastContratData = await getData(urlLastContrat);
    const contrat = lastContratData.data[0].contrato */
    //maping clinic id
    const clinicId = clinicsMaping[props.clinic.cli_id]
    const MECodE = params.row.MECodE // codigo especialidad
    const EspCodPIn = params.row.EspCodPIn.replace(/\s/g, '') // codigo tipo de cita sin espacios
    const urlCitapp = `https://portubien.com.co/citapp_user_desa_0/login/view_index2/${clinicId}/${props.user.card_id_type}/${props.user.card_id}/${props.lastContrat}/${MECodE}/${EspCodPIn}/0_tram/ordered/` + process.env.REACT_APP_FLAG_PRODUCTION_DEVELOP;
    //const urlCitapp = "https://portubien.com.co/citapp_user_desa_0/login/view_index2"
    props.handleOpenCloseCitappModal(urlCitapp);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        rows={props.rows}
        columns={columnsOrderedAppointm}
        pageSize={4}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(OrderedAppointmentsTable);