// Patient Dashboard
import React, { useState, useEffect } from "react";
// redux
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
//import material-ui elements
import {
  CssBaseline,
  Drawer,
  Box,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  Grid,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
//import Components
import CardInfo from "../../components/CardInfo";
import AppointmentsTable from "../../components/AppointmentsTable";
import OrderedAppointmentsTable from "../../components/OrderedAppointmentsTable";
import BackdropLoading from "../../components/BackdropLoading";
import ShowAlert from "../../components/ShowAlert";
import AlertDialog from "../../components/AlertDialog";
import { secondaryListItems } from "./listItems";
import Copyright from "../../components/Copyright";
import CitappModal from "./CitappModal";
import UpdateInfoModal from "./UpdateInfoModal";
import AppointmentsModal from "./AppointmentsModal";
import CancelAppoinmentsModal from "./CancelAppoinmentsModal";
import RescheduleCancelAppointment from "./RescheduleCancelAppointment";
import MyIncapacitiesTable from "./MyIncapacitiesTable";
import MyPresurgeryProcedureTable from "./MyPresurgeryProcedureTable";
import TransferFileAppointmentsModal from "./TransferFileAppointmentsModal";
import MyPresurgeryModal from "./MyPresurgeryModal";
import SendIncapacityToEmployerModal from "./SendIncapacityToEmployerModal";
import AppBarDashboard from "../../components/dashboard_components/AppBarDashboard";
import SideMenuDashboard from "../../components/dashboard_components/SideMenuDashboard";
// My icons
import ScheduleIcon from "@material-ui/icons/Schedule";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CreateIcon from "@material-ui/icons/Create";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import EventNoteIcon from "@material-ui/icons/EventNote";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ComputerIcon from "@material-ui/icons/Computer";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import useBrowserContextCommunication from "react-window-communication-hook";
// services
import { authenticationService } from "../../services/authenticationService";
import { appointmentService } from "../../services/appointmentService";
//Mapeo de clinicas
import { clinicsM } from "../../services/clinicsM";
//import imgs
import botonLogo from "../../assets/static/botonLogo.png";
import headerLogo from "../../assets/static/headerLogo.png";
import quindioLogo from "../../assets/static/logos_clinicas/central_del_quindio.png";
import antioquiaLogo from "../../assets/static/logos_clinicas/clinica_antioquia.png";
import estanciaLogo from "../../assets/static/logos_clinicas/clinica_la_estancia.png";
import mercedLogo from "../../assets/static/logos_clinicas/clinica_la_merced.png";
import nuestraLogo from "../../assets/static/logos_clinicas/clinica_nuestra.png";
import rosalesLogo from "../../assets/static/logos_clinicas/los_rosales.png";
import sagradocorazonLogo from "../../assets/static/logos_clinicas/sagrado_corazon.png";
//actions
import { setLastContrat } from "../../actions/";

const drawerWidth = 240;

function Dashboard(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [openCitappModal, setOpenCitappModal] = useState(false);
  const [openUpdateInfoModal, setOpenUpdateInfoModal] = useState(false);
  const [openAppointmentsModal, setOpenAppointmentsModal] = useState(false);
  const [appointmentsToShow, setAppointmentsToShow] = useState("general");
  const [pageTitle, setPageTitle] = useState("");
  const [totalLastAppoint, setTotalLastAppoint] = useState(0);
  const [totalNextAppoint, setTotalNextAppoint] = useState(0);
  const [totalControlAppoint, setTotalControlAppoint] = useState(0);
  const [urlCitapp, setUrlCitapp] = useState(
    "https://portubien.com.co/citapp_user_desa_0/"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastContrat, setLastContrat] = useState("");
  const [urlCancelAppoinmentsModal, setUrlCancelAppoinmentsModal] =
    useState("");
  const [
    urlRescheduleCancelAppointmentModal,
    setUrlRescheduleCancelAppointmentModal,
  ] = useState("");
  const [urlRescheduleAppointmentModal, setUrlRescheduleAppointmentModal] =
    useState("");

  const [openCancelAppoinmentsModal, setOpenCancelAppoinmentsModal] =
    useState(false);
  const [
    openRescheduleCancelAppointmentModal,
    setOpenRescheduleCancelAppointmentModal,
  ] = useState(false);

  const [
    urlTransferFileAppointmentsModal,
    setUrlTransferFileAppointmentsModal,
  ] = useState("");
  const [
    openTransferFileAppointmentsModal,
    setOpenTransferFileAppointmentsModal,
  ] = useState(false);
  const [rows, setRows] = useState([]);
  const [lastAppoint, setLastAppoint] = useState([]);
  const [nextAppoint, setNextAppoint] = useState([]);
  const [orderedAppoint, setOrderedAppoint] = useState([]);
  const [title, setTitle] = useState("Próximas citas agendadas");
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [communicationState, postMessage] =
    useBrowserContextCommunication("CitappModalIframe");
  const [clinicLogo, setClinicLogo] = useState("Próximas citas agendadas");
  const [backdropLoading, setBackdropLoading] = useState(false);
  const [openMyPresurgeryModal, setOpenMyPresurgeryModal] = useState(false);
  const [paramsRowPresurgery, setParamsRowPresurgery] = useState(false);
  const [
    openSendIncapacityToEmployerModal,
    setOpenSendIncapacityToEmployerModal,
  ] = useState(false);
  const [paramsRowIncapacity, setParamsRowIncapacity] = useState(false);
  const [alertDialog, setAlerDialog] = useState(false);
  const [alert, handleAlert] = useState({
    show: false,
    type: null,
    title: null,
    message: null,
    strong: null,
    action: null,
  });
  const [incapacitiesRows, setIncapacitiesRows] = useState([]);
  const [incapacitiesLoading, setIncapacitiesLoading] = useState(false);

  const clinicsMaping = clinicsM.Maping();

  let history = useHistory();

  //UseEffects to execute before render DOM
  useEffect(async () => {
    handleClinicLogo(props.clinic.cli_id);
    getAllIncapacities();
    const pat = props.patients ? props.patients[0] : null;
    if (!pat) {
      //validate if patient not exists, open modal to update info
      setOpenUpdateInfoModal(true);
    } else {
      // get last contrat
      //const urlLastContrat = process.env.REACT_APP_URL_HOST_AMS + `/api/getUltimoContratoPaciente/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`;
      const urlLastContrat =
        process.env.REACT_APP_URL_HOST_AMS +
        `/api/getContratoPacienteSelect/${props.clinic.cli_id}/${props.user.card_id}/${props.user.card_id_type}`;

      const lastContratData = await getData(urlLastContrat);
      const contrat = lastContratData.data
        ? lastContratData.data[0].contrato
        : "";
      setLastContrat(contrat);
      props.setLastContrat(contrat);
      getAllAppointments();
    }
    setPageTitle(props.clinic.name);
  }, []);

  useEffect(() => {
    //effect to hear communication and update appointments
    if (communicationState.lastMessage) {
      const { createNewAppointment } = communicationState.lastMessage;
      if (createNewAppointment) {
        getAllAppointments();
      }
    }
  }, [communicationState]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenCloseCitappModal = (url) => {
    setUrlCitapp(url);
    setOpenCitappModal(!openCitappModal);
    //history.go(0);
  };

  const handleOpenCloseCancelAppoinmentsModal = (url) => {
    setUrlCancelAppoinmentsModal(url);
    setOpenCancelAppoinmentsModal(!openCancelAppoinmentsModal);
  };

  const handleOpenCloseRescheduleCancelAppointmentModal = (
    urlToCancel,
    urlToCitapp
  ) => {
    setUrlRescheduleCancelAppointmentModal(urlToCancel);
    setUrlRescheduleAppointmentModal(urlToCitapp);
    setOpenRescheduleCancelAppointmentModal(
      !openRescheduleCancelAppointmentModal
    );
  };

  const handleOpenCloseTransferFileAppointmentsModal = (url) => {
    setUrlTransferFileAppointmentsModal(url);
    setOpenTransferFileAppointmentsModal(!openTransferFileAppointmentsModal);
  };

  const handleOpenCloseUpdateInfoModal = () => {
    setOpenUpdateInfoModal(!openUpdateInfoModal);
  };

  const handleOpenCloseAppointmentsModal = () => {
    setOpenAppointmentsModal(!openAppointmentsModal);
  };

  const handleAppointmentsControlClick = () => {
    setPageTitle("Citas de control");
    setAppointmentsToShow("ordered");
  };

  const handleIncapacityClick = () => {
    setPageTitle("Mis Incapacidades");
    setAppointmentsToShow("incapacities");
  };

  const handlePresurgeryClick = () => {
    setPageTitle("Procedimientos Prequirúrgicos");
    setAppointmentsToShow("presurgery");
  };

  const handleAppointmentsGeneralClick = () => {
    setPageTitle("Mis Citas");
    setAppointmentsToShow("general");
  };

  const handleHomeClick = () => {
    console.log("props.clinic", props.clinic);
    setAppointmentsToShow("general");
    setPageTitle("Página Principal");
  };

  const handleExit = () => {
    authenticationService.logout();
    history.push("/");
  };

  const handleOpenAppointMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAppointMenu = () => {
    setAnchorEl(null);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const getData = async (url) => {
    const requestOptions = {
      method: "GET",
    };
    const resp = await fetch(url, requestOptions).then((resp) => resp.json());
    return resp;
  };

  const handleSelectAppointMenu = async (AppointTypeSelected) => {
    //function to open citapp on teleorientación or agendar cita
    // Parse url
    const baseUrl = "https://portubien.com.co/citapp_user_desa_0";
    const url =
      baseUrl +
      (AppointTypeSelected === "teleorientacion"
        ? "/login/view_index1"
        : "/login/view_index2");
    //maping clinic id
    const clinicId = clinicsMaping[props.clinic.cli_id];
    // Build urlCitapp
    const urlCitapp =
      url +
      `/${clinicId}/${props.user.card_id_type}/${props.user.card_id}/${lastContrat}/0_tram/desarrollo` +
      process.env.REACT_APP_FLAG_PRODUCTION_DEVELOP;
    // Open Modal
    handleOpenCloseCitappModal(urlCitapp);
    //Close menu
    handleCloseAppointMenu();
  };

  const handleClinicLogo = (idClinic) => {
    //function to change ClinicLogo on Menu according to te clinic id
    switch (idClinic) {
      case 6:
        setClinicLogo(nuestraLogo);
        break;
      case 7:
        setClinicLogo(rosalesLogo);
        break;
      case 9:
        setClinicLogo(nuestraLogo);
        break;
      case 10:
        setClinicLogo(quindioLogo);
        break;
      case 11:
        setClinicLogo(nuestraLogo);
        break;
      case 12:
        setClinicLogo(sagradocorazonLogo);
        break;
      case 14:
        setClinicLogo(antioquiaLogo);
        break;
      case 15:
        setClinicLogo(estanciaLogo);
        break;
      case 16:
        setClinicLogo(mercedLogo);
        break;
      case 19:
        setClinicLogo(sagradocorazonLogo);
        break;
      case 28:
        setClinicLogo(sagradocorazonLogo);
        break;
      case 29:
        setClinicLogo(rosalesLogo);
        break;
      case 27:
        setClinicLogo(sagradocorazonLogo);
        break;
      case 3:
        setClinicLogo(estanciaLogo);
        break;
    }
  };

  const handleSwitchChange = (selected) => {
    //funtion to setting values if I selected Historic or next appointments
    if (selected === "next") {
      setTitle("Próximas citas agendadas");
      // set new rows data
      setRows(nextAppoint);
    } else {
      setTitle("Histórico de citas registradas");
      // set new rows data
      setRows(lastAppoint);
    }
  };
  const handleAlertDialogSuccess = async () => {
    //const incapacityDisabled = await appointmentService.disableIncapacityNotifications(props.user, "false");
    setAlerDialog(!alertDialog);
    if (alertDialog === true) {
      handleIncapacityClick();
    }
  };

  const handleDisableNotifyIncapacities = async () => {
    const incapacityDisabled =
      await appointmentService.disableIncapacityNotifications(
        props.user,
        "true"
      );
    console.log("retorno ", incapacityDisabled);
    setAlerDialog(!alertDialog);
  };

  const handleOpenClosePresurgeryModal = async (paramsRow) => {
    //Open presurgeries modal
    console.log("params en dashboard ", paramsRow);
    if (!openMyPresurgeryModal && paramsRow) {
      setParamsRowPresurgery(paramsRow);
      setBackdropLoading(true);
      //await getLastContractInfo(ceroTramUser);
      setOpenMyPresurgeryModal(!openMyPresurgeryModal);
      setBackdropLoading(false);
    } else {
      setOpenMyPresurgeryModal(!openMyPresurgeryModal);
    }
  };

  const handleOpenCloseSendIncapacityToEmployerModal = async (paramsRow) => {
    if (!openSendIncapacityToEmployerModal && paramsRow) {
      setParamsRowIncapacity(paramsRow);
      //setBackdropLoading(true);
      //await getLastContractInfo(ceroTramUser);
      setOpenSendIncapacityToEmployerModal(!openSendIncapacityToEmployerModal);
      //setBackdropLoading(false);
    } else {
      setOpenSendIncapacityToEmployerModal(!openSendIncapacityToEmployerModal);
    }
  };

  const currentUser = authenticationService.currentUserValue;
  if (!currentUser) {
    // not logged in so redirect to login page with the return url
    return <Redirect to={{ pathname: "/" }} />;
  }

  const getAllAppointments = async () => {
    //Get all ordered, next, and historic appointments according to the patient id card and clinic
    setAppointmentLoading(true);
    console.log("valor del clinic id en appointments", props.clinic.cli_id);
    const allAppointments = await appointmentService.getAllAppointments(
      props.clinic.cli_id,
      props.user
    );
    const lastAppointments = allAppointments.data.data.last;
    const nextAppointments = allAppointments.data.data.next;
    const orderedAppointments = allAppointments.data.data.control;
    console.log("all appointments", allAppointments);
    if (lastAppointments.length) {
      setTotalLastAppoint(lastAppointments.length);
      const rowsLastAppointments = lastAppointments.map((obj, index) => ({
        ...obj,
        id: index,
      }));
      setLastAppoint(rowsLastAppointments);
    }
    if (nextAppointments.length) {
      setTotalNextAppoint(nextAppointments.length);
      const rowsNextAppointments = nextAppointments.map((obj, index) => ({
        ...obj,
        id: index,
      }));
      setNextAppoint(rowsNextAppointments);
      setRows(rowsNextAppointments);
    }
    if (orderedAppointments.length) {
      setTotalControlAppoint(orderedAppointments.length);
      const rowsOrderedAppointments = orderedAppointments.map((obj, index) => ({
        ...obj,
        id: index,
      }));
      setOrderedAppoint(rowsOrderedAppointments);
    }
    setAppointmentLoading(false);
    console.log("ultimas lastAppointments ", lastAppointments);
  };

  const getAllIncapacities = async () => {
    //Get data
    setIncapacitiesLoading(true);
    const isDisabledNotifications =
      await appointmentService.getIncapacityNotifications(props.user);
    console.log(
      "valor de notificaciones activas ",
      isDisabledNotifications.data[0]
    );

    if (isDisabledNotifications.data[0]) {
      handleAlertDialogSuccess();
    }

    const clinicId = clinicsMaping[props.clinic.cli_id];
    const resp = await appointmentService.getAllIncapacitiesCeroTramites(
      props.user,
      props.clinic.cli_id
    );

    const rowsToRender = resp.data.map((row, index) => {
      const appointmentInfo = row.incapacity;
      return {
        ...row,
        id: index,
      };
    });
    setIncapacitiesRows(rowsToRender);
    setIncapacitiesLoading(false);
  };

  //Select table to present on Dashboard
  const getAppointmentTable = (appointmentsToShow) => {
    switch (appointmentsToShow) {
      case "general":
        return (
          <AppointmentsTable
            handleOpenCloseCancelAppoinmentsModal={
              handleOpenCloseCancelAppoinmentsModal
            }
            handleOpenCloseRescheduleCancelAppointmentModal={
              handleOpenCloseRescheduleCancelAppointmentModal
            }
            handleOpenCloseTransferFileAppointmentsModal={
              handleOpenCloseTransferFileAppointmentsModal
            }
            clinic={props.clinic}
            clinicsMaping={props.clinicsMaping}
            user={props.user}
            lastContrat={props.lastContrat}
            rows={rows}
            title={title}
            handleSwitchChange={handleSwitchChange}
            appointmentLoading={appointmentLoading}
          />
        );
      case "ordered":
        return (
          <OrderedAppointmentsTable
            handleOpenCloseCitappModal={handleOpenCloseCitappModal}
            user={props.user}
            clinic={props.clinic}
            rows={orderedAppoint}
          />
        );
      case "incapacities":
        return (
          <MyIncapacitiesTable
            handleOpenCloseCitappModal={handleOpenCloseCitappModal}
            handleOpenCloseSendIncapacityToEmployerModal={
              handleOpenCloseSendIncapacityToEmployerModal
            }
            user={props.user}
            clinic={props.clinic}
            incapacitiesLoading={incapacitiesLoading}
            rows={incapacitiesRows}
          />
        );
      case "presurgery":
        return (
          <MyPresurgeryProcedureTable
            //rows={presurgeryRows}
            handleOpenClosePresurgeryModal={handleOpenClosePresurgeryModal}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBarDashboard
        handleDrawerOpen={handleDrawerOpen}
        handleExit={handleExit}
        open={open}
        pageTitle={pageTitle}
        clinic={props.clinic}
      />

      <SideMenuDashboard
        open={open}
        pageTitle={pageTitle}
        secondaryListItems={secondaryListItems}
        handlePresurgeryClick={handlePresurgeryClick}
        handleIncapacityClick={handleIncapacityClick}
        handleAppointmentsControlClick={handleAppointmentsControlClick}
        handleAppointmentsGeneralClick={handleAppointmentsGeneralClick}
        handleSelectAppointMenu={handleSelectAppointMenu}
        handleCloseAppointMenu={handleCloseAppointMenu}
        handleOpenAppointMenu={handleOpenAppointMenu}
        handleOpenCloseUpdateInfoModal={handleOpenCloseUpdateInfoModal}
        handleHomeClick={handleHomeClick}
        handleDrawerClose={handleDrawerClose}
        clinic={props.clinic}
        anchorEl={anchorEl}
        clinicLogo={clinicLogo}
      />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item lg={4}>
              {/* <Chart /> */}
              <CardInfo
                title="Historico Citas"
                icon={<EventNoteIcon color="primary" fontSize="large" />}
                value={totalLastAppoint}
              />
              {/* <CardInfo title="Próximas Citas" icon={<EventNoteIcon color="primary" fontSize="large" />} value={5} /> */}
            </Grid>
            {/* Recent Deposits */}
            <Grid item lg={4}>
              <CardInfo
                title="Proximas citas"
                icon={<AccessTimeIcon color="primary" fontSize="large" />}
                value={totalNextAppoint}
              />
            </Grid>
            <Grid item lg={4}>
              <CardInfo
                title="Citas de Control"
                icon={<NoteAddIcon color="primary" fontSize="large" />}
                value={totalControlAppoint}
              />
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {/* Citas  */}
                {getAppointmentTable(appointmentsToShow)}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>

      <CitappModal
        open={openCitappModal}
        handleOpenCloseCitappModal={handleOpenCloseCitappModal}
        urlCitapp={urlCitapp}
      />
      <UpdateInfoModal
        open={openUpdateInfoModal}
        handleOpenCloseUpdateInfoModal={handleOpenCloseUpdateInfoModal}
        disableBackdropClick={props.patients ? false : true}
        havePatient={props.patients ? true : false}
      />
      <AppointmentsModal
        open={openAppointmentsModal}
        handleOpenCloseAppointmentsModal={handleOpenCloseAppointmentsModal}
      />
      <CancelAppoinmentsModal
        open={openCancelAppoinmentsModal}
        handleOpenCloseCancelAppoinmentsModal={
          handleOpenCloseCancelAppoinmentsModal
        }
        urlCancelAppoinmentsModal={urlCancelAppoinmentsModal}
      />
      <RescheduleCancelAppointment
        open={openRescheduleCancelAppointmentModal}
        handleOpenCloseRescheduleCancelAppointmentModal={
          handleOpenCloseRescheduleCancelAppointmentModal
        }
        urlRescheduleCancelAppointmentModal={
          urlRescheduleCancelAppointmentModal
        }
        urlRescheduleAppointmentModal={urlRescheduleAppointmentModal}
        handleOpenCloseCitappModal={handleOpenCloseCitappModal}
      />
      <TransferFileAppointmentsModal
        open={openTransferFileAppointmentsModal}
        handleOpenCloseTransferFileAppointmentsModal={
          handleOpenCloseTransferFileAppointmentsModal
        }
        urlTransferFileAppointmentsModal={urlTransferFileAppointmentsModal}
      />
      <SendIncapacityToEmployerModal
        open={openSendIncapacityToEmployerModal}
        handleOpenCloseSendIncapacityToEmployerModal={
          handleOpenCloseSendIncapacityToEmployerModal
        }
        paramsRowIncapacity={paramsRowIncapacity}
      />
      <MyPresurgeryModal
        open={openMyPresurgeryModal}
        handleOpenClosePresurgeryModal={handleOpenClosePresurgeryModal}
        paramsRowPresurgery={paramsRowPresurgery}
      />
      <BackdropLoading open={backdropLoading} />
      <AlertDialog
        open={alertDialog}
        title="BIENVENIDO"
        description="Recuerde, cuenta con incapacidades pendientes para la radicación"
        handleAlertDialogSuccess={handleAlertDialogSuccess}
        handleDisableNotifyIncapacities={handleDisableNotifyIncapacities}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 20px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const mapStateToProps = (state) => ({
  user: state.user,
  patients: state.patients,
  clinic: state.clinic,
  clinicsMaping: state.clinicsMaping,
  testCreateAppointment: state.testCreateAppointment,
});

const mapDispatchToProps = {
  setLastContrat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
