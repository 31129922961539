import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import {
  Typography,
  makeStyles,
} from '@material-ui/core';
import ShowAlert from '../../components/ShowAlert';
import { BsWhatsapp } from "react-icons/bs";
// redux
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
// Services
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  field: {
    width: "100%",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 160,
  },
}));

function MyExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const StaffReminders = (props) => {
  const classes = useStyles();
  const [title, setTitle] = useState('Recordatorios');
  const [rowsLoading, setRowsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [openDiary, setOpenDiary] = useState(false);
  
  const columnsIncapacities = [
    { field: 'enu_tipo_doc', headerName: 'Tipo Doc.', width: 120},
    { field: 'enu_identificacion', headerName: 'Documento', width: 130},
    { field: 'enu_nom_paciente', headerName: 'Paciente', width: 180, renderCell: renderCellExpand,},
    { field: 'enu_cit_num', headerName: 'Cita', width: 95},
    { field: 'enu_especialidad', headerName: 'Especialidad', width: 200, renderCell: renderCellExpand,},
    { field: 'enu_nom_medico', headerName: 'Médico', width: 190, renderCell: renderCellExpand,},
    { field: 'enu_tel1', headerName: 'Teléfono', width: 140},
    { field: 'enu_tel2', headerName: 'Teléfono 2', width: 140},
    { field: 'enu_fecha_cita', headerName: 'Fecha Cita', width: 130},
    { field: 'enu_cita_hora', headerName: 'Hora Cita', width: 120},
    { field: 'Estado', headerName: 'Estado', width: 140, renderCell: (params) => {
      return params.row.Estado === 'Confirmada' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BsWhatsapp style={{ color: 'green' }} />
          <span style={{ marginLeft: '5px' }}>Confirmada</span>
        </div>
      ) : params.row.Estado === 'Pendiente' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BsWhatsapp style={{ color: 'orange' }} />
          <span style={{ marginLeft: '5px' }}>Pendiente</span>
        </div>
      ) : params.row.Estado === 'Cancelada' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BsWhatsapp style={{ color: 'red' }} />
          <span style={{ marginLeft: '5px' }}>Cancelada</span>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BsWhatsapp style={{ color: 'gray' }} />
          <span style={{ marginLeft: '5px' }}>Enviado</span>
        </div>
      );
    }},
    { field: 'HORA_ENVIO', headerName: 'SMS Envio', width: 150},
    { field: 'HORA_RECIBIDO', headerName: 'SMS Recibido', width: 150},
    { field: 'ultima_hora_interaccion', headerName: 'Ultima Interacción', width: 160},
  ]

  useEffect(async () => {
    await getInformacionRecordatorios();

  }, [])

  const getInformacionRecordatorios = async ( ) => {
    //Get data
    setRowsLoading(true);
    var list = [];
    var count = 0;

    const resp = await staffAppointmentService.getInformacionRecordatorios(props.clinic.cli_id);
    count =  Object.keys(resp).length;
    if (count > 0) {    
      for (let j = 0; j < count; j++) {
        if (resp[j]['confirmacion_exitosa'] == 1) {
          resp[j]['Estado'] = 'Confirmada';
        } else if (resp[j]['cancelar_cita'] != null) {
          resp[j]['Estado'] = 'Cancelada';
        } else if (resp[j]['HORA_ENVIO'] && resp[j]['HORA_RECIBIDO']){
          resp[j]['Estado'] = 'Pendiente';
        } else {
          resp[j]['Estado'] = 'Enviado';
        }
        list.push(resp[j]);
      }
    }

    const rowsToRender = list.map((row, index) => {
      return {
        ...row,
        index: index,
      }
    })
    setRows(rowsToRender);
    setRowsLoading(false);
  }

  return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {alert.show && (
          <ShowAlert
            {...alert}
          />
        )}
        { !openDiary && <DataGrid
          autoHeight
          rows={rows}
          loading={rowsLoading}
          columns={columnsIncapacities}
          pageSize={5}
          components={{
            Toolbar: MyExportButton,
          }}
          disableSelectionOnClick
        />}
        <openDiary></openDiary>
      </React.Fragment>    
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(StaffReminders);