import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Typography from '@material-ui/core/Typography';
import ShowAlert from '../../components/ShowAlert';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// redux
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
// Services
import { appointmentService } from '../../services/appointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';
//Icons
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';

const clinicsMaping = clinicsM.Maping();

const MyPresurgeryProcedureTable = (props) => {

  const [title, setTitle] = useState('Procedimientos Prequirúrgicos');
  const [presurgeryLoading, setPresurgeryLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(false);

  const columnsPresurgery = [
    { field: 'codigo_procedimiento', headerName: 'Código', width: 110, renderCell: renderCellExpand },
    { field: 'fecha_ordenamiento', headerName: 'Fecha', width: 110, renderCell: renderCellExpand },
    { field: 'doc_paciente', headerName: 'Documento', width: 110, renderCell: renderCellExpand },
    { field: 'nombre_procedimiento', headerName: 'Nombre de procedimiento', width: 200, renderCell: renderCellExpand },
    { field: 'historia_clinica', headerName: 'Historia Clínica', width: 230, renderCell: renderCellExpand },
    { field: 'folio', headerName: 'folio', width: 110, renderCell: renderCellExpand },
    { field: 'estado', headerName: 'Estado', width: 130, renderCell: renderCellExpand },
    /* {
        field: 'OrdenMed',
        renderHeader: () => (
          loadingDocument? <CircularProgress size={28} /> :"Ver"
        ),
        
        width: 100,
        renderCell: (params) => (
            <IconButton
              onClick={() => viewDocumentIncapacity(params)}
              disabled={loadingDocument}
            >
               <DescriptionIcon color={loadingDocument ? "inherit": "primary"}/>
              
            </IconButton>
        ),
      }, */
  ]

  const getAllPresurgeryProcedures = async (user, clinic) => {
    //Get data
    console.log("User send ", user);
    console.log("Clinic send ", clinic)
    setPresurgeryLoading(true);
    const resp = await appointmentService.getAllPresurgeryProcedures(user, clinic);
    // parse data
    console.log("valor de resp ", resp)
    const rowsToRender = resp.data.map((row, index) => {
      //const appointmentInfo = row.incapacity;
      return {
        id: index,
        fecha_ordenamiento: row.fecha_ordenamiento,
        codigo_procedimiento: row.codigo_procedimiento,
        historia_clinica: row.historia_clinica,
        doc_paciente: row.doc_paciente,
        tipo_doc: row.tipo_doc,
        nombre_procedimiento: row.nombre_procedimiento,
        folio: row.folio,
        estado: row.estado
      }
    })
    console.log("PRINT DATA INCAPACITIES FROM PYTHON", rowsToRender)
    setRows(rowsToRender);
    setPresurgeryLoading(false);
  }

  const viewDocumentIncapacity = async (params) => {
    //Get data
    setLoadingDocument(true);

    console.log(params);
    const clinicId = clinicsMaping[props.clinic.cli_id]
    try {
      const resp = await appointmentService.viewDocumentIncapacity(clinicId,
        params.row.nro_Docum,
        params.row.Folio,
        null);
      var file = new Blob([resp.data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

    } catch {
      alert("Error al crear archivo")
    }
    setLoadingDocument(false);
  }

  const handleRowSelected = async (params) => {
    handleSeePresurgeryButton(params);
  }

  const handleSeePresurgeryButton = (params) => {
    //const ceroTramUser = params.row?.cerotram_user;
    //    props.setUserSelected(ceroTramUser);
    //props.setCitaNumSelected(params.row.cita_num);
    props.handleOpenClosePresurgeryModal(params.row);
  }

  useEffect(async () => {
    await getAllPresurgeryProcedures(props.user, props.clinic);
  }, [])

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {alert.show && (
        <ShowAlert
          {...alert}
        />
      )}
      <DataGrid
        autoHeight
        rows={rows}
        /* onRowClick={(params) => {
          handleRowSelected(params);
        }} */
        loading={presurgeryLoading}
        columns={columnsPresurgery}
        pageSize={4}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(MyPresurgeryProcedureTable);