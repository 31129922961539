import React from 'react';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//Custom components
import ClinicCard from '../components/ClinicCard'
// Clinics Images
import estanciaPopImg from '../assets/static/clinics/estanciapopayan.jpg';
import nuestraIbagImg from '../assets/static/clinics/nuestraibague.jpg';
import nuestraCartImg from '../assets/static/clinics/nuestracartagena.jpg';
import rosalesPereImg from '../assets/static/clinics/rosalespereira.jpg';
import corazonMedImg from '../assets/static/clinics/corazonmedellin.jpg';
import antioquiaItagImg from '../assets/static/clinics/antioquiaitagui.jpg';
import antioquiaBellImg from '../assets/static/clinics/antioquiabello.jpg';
import versallesManiImg from '../assets/static/clinics/versallesmanizales.jpg';
import mercedBarrImg from '../assets/static/clinics/mercedbarranquilla.jpg';
import centralqArmeImg from '../assets/static/clinics/ccqarmenia.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  clinics: {
    padding: theme.spacing(2),
  },
}));

export default function AutoGrid() {
  const classes = useStyles();

  function Row(props) {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>item</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container  spacing={2}>
            {clinics.map((clinic, index) => (
                 <Grid item xs>
                    <ClinicCard key={index} {...clinic} />
                </Grid>
            ))}
      </Grid>
    </div>
  );
}


const clinics = [
    {name: "Clínica la Estancia", city: "Popayan Cauca", image: estanciaPopImg},
    {name: "Clínica Nuestra", city: "Cali Valle", image: nuestraIbagImg},
    {name: "Clínica Nuestra", city: "Ibagué Tolima", image: nuestraIbagImg},
    {name: "Clínica Nuestra", city: "Cartagena Bolivar", image: nuestraCartImg},
    {name: "Clínica Los Rosales", city: "Pereira Risaralda", image: rosalesPereImg},
    {name: "Clínica Sagrado corazon", city: "Medellin Antioquia", image: corazonMedImg},
    {name: "Clínica Antioquia", city: "Itagui Antioquia", image: antioquiaItagImg},
    {name: "Clínica Antioquia", city: "Bello Antioquia", image: antioquiaBellImg},
    {name: "Clínica Versalles", city: "Manizales Caldas", image: versallesManiImg},
    {name: "Clínica La Merced", city: "Barranquilla Atlantico", image: mercedBarrImg},
    {name: "Clínica Central del Quindio", city: "Armenia Quindio", image: centralqArmeImg},
    {name: "Los Rosales Prueba", city: "Pereira Risaralda", image: rosalesPereImg},
];