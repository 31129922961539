import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridFilterToolbarButton, GridToolbarExport} from '@material-ui/data-grid';
import { Typography, makeStyles, Button, DialogContent, DialogContentText, DialogTitle, Dialog, DialogActions, Grid, TextField } from '@material-ui/core';
import ShowAlert from '../../components/ShowAlert';
import CloseIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/Check';
import * as XLSX from 'xlsx';

// redux
import { connect } from 'react-redux';
import renderCellExpand from '../../components/renderCellExpand';
// Services
import { staffAppointmentService } from '../../services/staff/staffAppointmentService';
//Mapeo de clinicas
import { clinicsM } from '../../services/clinicsM';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    width: "fit-content",
    marginTop: "10px",
    marginBottom: "10px",
  },
  field: {
    width: "100%",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 160,
  },

}));

const clinicsMaping =  clinicsM.Maping();

function MyExportButton() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const StaffWaitingList = (props) => {

  const classes = useStyles();
  const [title, setTitle] = useState('Lista de Espera');
  const [defectColor, setDefectColor] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date - (1000 * 60 * 60 * 24)));
  const [rowsLoading, setRowsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [openDiary, setOpenDiary] = useState(false);
  const [diary, setDiary] = useState({});
  const [funcionalidad, setFuncionalidad] = useState('');
  const [open, setOpen] = React.useState(false);  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsInitial, setRowsInitial] = useState([]);

  const columnsIncapacities = [
    { field: 'tipo_documento', headerName: 'Tipo Doc.', width: 115},
    { field: 'numero_documento', headerName: 'Número Doc.', width: 155},
    { field: 'nombre', headerName: 'Nombre', width: 220},
    // { field: 'clinica_id', headerName: 'Id Clinica ', width: 120, renderCell: renderCellExpand },
    { field: 'nom_esp', headerName: 'Especialidad', width: 200},
    { field: 'contrato_id', headerName: 'Cod. EPS', width: 130},
    { field: 'nombre_eps', headerName: 'EPS', width: 270},
    { field: 'numero', headerName: 'Celular', width: 120},
    { field: 'fecha_consultada', headerName: 'Fecha Consultada', width: 170},     
    { field: 'fecha_registro', headerName: 'Fecha Registro', width: 150},  
    // { field: 'cup_pac', headerName: 'Cup Pac', width: 110, renderCell: renderCellExpand },
    { field: 'descripcion_cup_pac', headerName: 'CupPac', width: 180},
    { field: 'nivel', headerName: 'Nivel', width: 90},
    { field: 'agendado', headerName: 'Agendado', width: 120, type: 'boolean', initialEditValue: false, editable: true, renderCell: (params) => {      
      return params.value ? (
        <CheckIcon style={{ color: 'green' }} onClick={toggleAgendado(params.id, params.field)}
        />
      ) : (
        <CloseIcon style={{ color: 'gray' }} onClick={toggleAgendado(params.id, params.field)}
        />
      );
    }, },
    { field: 'no_contesta', headerName: 'No Contesta', width: 140, type: 'boolean', initialEditValue: false, editable: true, renderCell: (params) => {
      return params.value ? (
        <CheckIcon style={{ color: 'green' }} onClick={toggleNoContesta(params.id, params.field)}
        />
      ) : (
        <CloseIcon style={{ color: 'gray' }} onClick={toggleNoContesta(params.id, params.field)}
        />
      );
    }, },
    { field: 'atendido', headerName: 'Atendido', width: 120, type: 'boolean', initialEditValue: false, editable: true, renderCell: (params) => {
      return params.value ? (
        <CheckIcon style={{ color: 'green' }} onClick={toggleAtendido(params.id, params.field)}
        />
      ) : (
        <CloseIcon style={{ color: 'gray' }} onClick={toggleAtendido(params.id, params.field)}
        />
      );
    }, },
    { field: 'no_requiere', headerName: 'No Requiere', width: 140, type: 'boolean', initialEditValue: false, editable: true, renderCell: (params) => {
      return params.value ? (
        <CheckIcon style={{ color: 'green' }} onClick={toggleNoRequiere(params.id, params.field)}
        />
      ) : (
        <CloseIcon style={{ color: 'gray' }} onClick={toggleNoRequiere(params.id, params.field)}
        />
      );
    }, },
    { field: 'actualizar', headerName: 'Actualizar', width: 120, renderCell: renderCellExpand, renderCell: (params) => {
      return  (
        <DoneIcon style={{ color: 'green' }} onClick={deleteUser(params.id, funcionalidad)}
        />)
    }, },
  ]

  useEffect(async () => {
    await getAllWaitingList();
  }, [])

  const toggleAtendido = React.useCallback(
    (id, field) => () => {
      setFuncionalidad(field);
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, atendido: !row.atendido,  no_contesta: false, agendado: false, no_requiere: false } : row,
        ),
      );
    },
    [],
  );

  const toggleNoContesta = React.useCallback(
    (id, field) => () => {
      setFuncionalidad(field);
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, no_contesta: !row.no_contesta, agendado: false, atendido: false, no_requiere: false } : row,
        ),
      );
    },
    [],
  );

  const toggleAgendado = React.useCallback(
    (id, field) => () => {
      setFuncionalidad(field);
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, agendado: !row.agendado, atendido: false, no_contesta: false, no_requiere: false} : row,
        ),
      );
    },
    [],
  );

  const toggleNoRequiere = React.useCallback(
    (id, field) => () => {
      setFuncionalidad(field);
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, no_requiere: !row.no_requiere, atendido: false, no_contesta: false, agendado: false } : row,
        ),
      );
    },
    [],
  );

  const deleteUser = React.useCallback(
    (id, funcionalidad) => () => {      
      setTimeout(async () => {
        if (funcionalidad === "agendado") {
          const resp = await staffAppointmentService.updateWaitingList(4, id);
          if (resp === 1){
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
          }
        } else {
          const resp = await staffAppointmentService.updateWaitingList(3, id);
          if (resp === 1){
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
          }
        }
      });
    },
    [],
  );

  const getAllWaitingList = async ( ) => {
    //Get data
    setRowsLoading(true);
    var list = [];
    var count = 0;

    const resp = await staffAppointmentService.getWaitingList(props.clinic.cli_id);
    count =  Object.keys(resp).length;
    if (count > 0) {    
      for (let j = 0; j < count; j++) {    
        list.push(resp[j]);
      }     
    }

    const rowsToRender = list.map((row, index) => {
      return {
        ...row,
        index: index,
      }
    })
    setRows(rowsToRender);
    setRowsInitial(rowsToRender);
    setRowsLoading(false);
  }

  const getReporteDemandaInsatisfecha = async () => {
    const resp = await staffAppointmentService.getReporteDemandaInsatisfecha(props.clinic.cli_id, startDate, endDate);  //CAMBIAR EN PROD
    console.log("typeof getReporteDemandaInsatisfecha", typeof(resp));
    console.log("getReporteDemandaInsatisfecha", resp);
    exportToExcel(resp);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const exportToExcel = (data) => {
    // Crea una hoja de trabajo de Excel vacía
    const wb = XLSX.utils.book_new();
    // Crea una hoja de Excel a partir de los datos
    const ws = XLSX.utils.json_to_sheet(data);
    // Agrega la hoja de Excel a la hoja de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'Datos');
    // Convierte la hoja de trabajo en un archivo de Excel binario
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    // Crea un objeto blob a partir del archivo binario
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    // Descarga el archivo
    saveAs(blob, 'demanda_insatisfecha.xlsx');
  };
  
  // Convierte una cadena en un array de bytes
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  
  // Descarga el archivo con el nombre especificado
  const saveAs = (blob, filename) => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // URL.createObjectURL() funciona en Chrome y Firefox
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // URL.revokeObjectURL() funciona en Chrome y Firefox
      URL.revokeObjectURL(url);
    }
  };

  const handleConfirm = () => {
    if (!startDate || !endDate) {
      // Si alguna fecha no está definida, muestra un mensaje de error
      alert("Por favor seleccione ambas fechas");
    } else {
      console.log(`Resultados entre ${startDate} y ${endDate}`);
      getReporteDemandaInsatisfecha();
    }
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
  
    if (searchTerm !== '') {
      setRows((rowsInitial) =>
        rowsInitial.filter((row) =>
          row.numero_documento.toLowerCase().includes(searchTerm)
        )
      );
    } else {
      setRows(rowsInitial);
    }
  }
  

  return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {alert.show && (<ShowAlert{...alert}/>)}
        <div style={{ textAlign: "center", marginBottom: "20px", display: "flex", justifyContent: "center" }}>
          <div style={{ alignItems: "center" }}>
            <input
              type="text"
              placeholder="Buscar por número de documento..."
              className="search"
              onChange={handleSearchChange}
              style={{ fontSize: "17px", padding: "15px", width: "350px" }}
            />
          </div>
        </div>
        { !openDiary && <DataGrid
          autoHeight
          rows={rows}
          loading={rowsLoading}
          columns={columnsIncapacities}
          pageSize={10}
          components={{
            Toolbar: MyExportButton,
          }}
          disableSelectionOnClick
        />}
        <Button variant="contained" color="primary" onClick={handleClickOpen} className={classes.button}>
          Generar Reporte Demanda Insatisfecha
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Reporte</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Seleccione entre que fechas quiere generar el archivo
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fecha inicial"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fecha final"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
        <openDiary></openDiary>
      </React.Fragment>    
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  lastContrat: state.lastContrat,
  clinic: state.clinic
})

export default connect(mapStateToProps, null)(StaffWaitingList);