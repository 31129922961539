import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import StaffUpdateAndConfirmInfoAppointSteper from './StaffUpdateAndConfirmInfoAppointSteper'
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '5%',
    },
  }));



const StaffConfirmAppointment = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <StaffUpdateAndConfirmInfoAppointSteper/>
        </div>
    )
}

export default StaffConfirmAppointment;