import React from 'react';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';


const Copyright = () => (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Hecho con ❤️ por '}
      <Link color="inherit" href="http://www.ospedale.com.co//">
        Grupo Ospedale
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
);

export default Copyright;