import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
// redux
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
/* import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateTimePicker from '@material-ui/lab/DateTimePicker'; */

import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import ShowAlert from '../../components/ShowAlert';



import { appointmentService } from '../../services/appointmentService';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  heroContent: {
    backgroundImage: `url(https://portubien.com.co/citapp_user/resources/recursos/img/teleconsultas.jpg)`,
    //backgroundImage: `url(${Background})`, 
    backgroundPosition: 'center 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: "20px",
    //borderRadius: '0px!important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  field: {
    width: "100%",
  },
  paper: {
    width: "90%",
    height: "100%",
    marginTop: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    marginBottom: "20px",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 250,
  },

}));

function PriorityAppointmentModal(props) {

  const classes = useStyles();
  let dateInput = useRef(null);
  const [documentInput, setDocumentInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  let { dataFromCitapp } = useParams();
  const [description, setDescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkSpecialist, setCheckSpecialist] = useState();
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [disabledRegister, setDisabledRegister] = useState(true);
  const [alert, handleAlert] = useState({ show: false, type: null, title: null, message: null, strong: null, action: null });
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [date3, setDate3] = useState(null);
  const [dateToChange, setDateToChange] = useState("");
  const [need_the_same_specialist, setNeed_the_same_specialist] = useState("");
  const options = { weekday: "long", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: "false" };

  const submitForm = async () => {
    
   /*  const fecha1 =new Date(date1).toISOString();
    const fecha2 =new Date(date2).toISOString();
    const fecha3 =new Date(date3).toISOString(); */
    console.log("valor date1 ",new Date(date1));
    setLoading(true);
    const formData = new FormData();
    formData.append("description", description);
    formData.append("file", selectedFile);
    formData.append("decodeDataFromCitapp", atob(dataFromCitapp));
    formData.append("clinic", JSON.stringify(props.clinic));
    formData.append("cero_tramites_user", JSON.stringify(props.user));
    formData.append("date1", date1);
    formData.append("date2", date2);
    formData.append("date3", date3);
    formData.append("need_the_same_specialist", checkSpecialist);

    if (Object.fromEntries(formData).description && Object.fromEntries(formData).file.name && (date1
    || date2 || date3)) {
      console.log("formdata ",formData)
      try {
        await appointmentService.createPriorityPetitionAppointment(formData);
        handleAlert({
          ...alert,
          show: true,
          type: "success",
          message: `Solicitud enviada`,
          strong: "Le comunicaremos pronto una respuesta"
        })
        setDisabledButtons(true);
        setTimeout(() => handleAlert({ ...alert, show: false }), 30000);
      } catch {
        handleAlert({
          ...alert,
          show: true,
          type: "error",
          message: `No fue posible realizar la solicitud`,
          strong: "Intente nuevamente"
        })
        setTimeout(() => handleAlert({ ...alert, show: false }), 5000)
      }
    } else {
      var mensaje;
      if(!Object.fromEntries(formData).description){
        mensaje="Debe especificar el motivo"
      }
      if(!Object.fromEntries(formData).file.name){
        setDocumentInput(true);
        mensaje="Debe adjuntar la orden médica"
      }
      if(!(date1|| date2 || date3)){
        dateInput.current.focus()
        mensaje="Debe seleccionar una fecha"
      }

      //alert("Los campos son obligatorios");
      handleAlert({
        ...alert,
        show: true,
        type: "error",
        message: mensaje,
        strong: "Por favor Completa el Formulario"
      })
      setTimeout(() => handleAlert({ ...alert, show: false }), 5000)
    }
    setLoading(false);
  };

  const handleSelectedFile = (e) => {
    setSelectedFile(e.target.files[0]);
    setDocumentInput(false);
  }
  const handleDateToChange = (event) => {
    if(date1 && date2 && date3){
      setDisabledRegister(true);
    }else{
      setDateToChange(event.target.value);
      setDisabledRegister(false);
    }
    
    
  };

  const handleSetDate = () => {

    //const date = new Date(dateToChange);
    //console.log("valor normal ",dateToChange);
    //console.log("normal ", date.toLocaleString());
    //setDate1(date.toLocaleDateString('es-ES', options));
    //console.log(val.getUTCFullYear());

    if (date1 === null) {
      setDate1(dateToChange);
    } else if (date2 === null) {
      setDate2(dateToChange);
    } else if (date3 === null) {
      setDate3(dateToChange);
    } else {
      setDisabledRegister(true);
    }
  };


  useEffect(() => {
    //console.log(dataFromCitapp);
  })

  return (
    <form className={classes.heroContent}>
      <Container component="main" maxWidth="sm" spacing={6}  >
        <div style={{ width: "94%" }}> {alert.show && (
          <ShowAlert
            {...alert}
          />
        )}</div>
        <Paper className={classes.paper}>
          <Grid style={{ marginLeft: "5%" }}>
            <Typography variant="h6" gutterBottom >
              SOLICITUD DE CITA CON ORDEN MÉDICA
            </Typography>

            <Paper variant="outlined" style={{ marginBottom: "20px", paddingBottom: "20px" }} >
              <Grid style={{ marginLeft: "15px", marginTop: "5px" }}>
                <Paper elevation={0} style={{ marginBottom: "10px", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => setCheckSpecialist(e.target.checked)} name="need_the_same_specialist" color="primary" />}
                    label="¿REQUIERE AL MISMO ESPECIALISTA PARA LA CONSULTA?"
                    labelPlacement="end"
                    disabled={disabledButtons}
                  /></Paper>
                <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing="1"
                >
                  <Grid
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing="2">
                    <TextField
                      required
                      id="datetime-local"
                      label="seleccione 3 fechas disponibles"
                      type="datetime-local"
                      name="datetimelocal"
                      inputRef={dateInput}
                      disabled={disabledButtons}
                      onChange={handleDateToChange}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      startIcon={<SaveIcon />}
                      style={{ marginTop: "27px" }}
                      disabled={disabledButtons || disabledRegister}
                      onClick={handleSetDate}>Registrar</Button>
                  </Grid>
                  <Grid container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing="2">
                    <Grid item>
                      <Typography component="h5">
                        <strong>Fecha 1:</strong> {date1 && new Date(date1).toLocaleString('es-ES', options)}
                        <IconButton onClick={() => setDate1(null)} disabled={!date1 || disabledButtons} size="small">
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="h5">
                        <strong>Fecha 2:</strong> {date2 && new Date(date2).toLocaleString('es-ES', options)}
                        <IconButton onClick={() => setDate2(null)} disabled={!date2 || disabledButtons} size="small">
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="h5">
                        <strong>Fecha 3:</strong> {date3 && new Date(date3).toLocaleString('es-ES', options)}
                        <IconButton onClick={() => setDate3(null)} disabled={!date3 || disabledButtons} size="small">
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Typography>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Paper>

            <TextField
              className={classes.field}
              required
              id="outlined-multiline-static"
              label="Motivo de solicitud"
              multiline              
              disabled={disabledButtons}
              rows={4}
              variant="outlined"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <input
              hidden
              disabled={disabledButtons}
              id="raised-button-file"
              type="file"
              multiple
              //onFileSelectSuccess={(e) => setSelectedFile(e.target.files[0])}
              onFileSelectError={({ error }) => alert(error)}
              onChange={(e) => handleSelectedFile(e)}
            />

            {selectedFile &&
              <Grid >
                <Typography variant="subtitle2" component="subtitle2">
                  Archivo seleccionado: <strong >{selectedFile.name}</strong>
                  <IconButton onClick={() => setSelectedFile("")} disabled={disabledButtons} size="small">
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Typography>
              </Grid>
            }

            <Grid container
              direction="row"
              justify="flex-end"
              alignItems="flex-end" >
              <label htmlFor="raised-button-file">
                <Button
                  className={classes.button}
                  variant="outlined"
                  color={!documentInput?"primary":"error"}
                  component="span"                  
                  disabled={disabledButtons}
                  startIcon={<CloudUploadIcon />}
                  style={{ marginRight: "7px" }}
                >
                  Subir orden médica
                        </Button>
              </label>
              {loading ?
                <Grid>
                  <CircularProgress size={34} />
                </Grid> :
                <Button
                  onClick={submitForm}
                  className={classes.button}
                  variant="contained"
                  disableElevation
                  disabled={disabledButtons}
                  size="medium"
                  color="primary"
                >
                  Enviar</Button>
              }
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </form >
  );
}


const mapStateToProps = (state) => ({
  clinic: state.clinic,
  user: state.user,
  patients: state.patients
})

/* const mapDispatchToProps = {
  setClinic,
} */

export default connect(mapStateToProps, null)(PriorityAppointmentModal);
